import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../../../reducers/types";
import { CfarAirDetails } from "halifax";
import {
  selectedCfarIdSelector,
  cfarOfferSelector,
  viewBookingAddOnPropertiesSelector,
  cfarAddOnChoicePropertiesSelector,
  currentCfarChangePolicySelector,
  fetchAncillaryOfferCallStateSelector,
  cfarAttachPropertiesForViewBookingAddOnSelector,
  hasCfarOfferSelector,
  selectedTripSelector,
  tripDetailsSelector,
  showNoFtcOnlyInRefundableFaresSelector,
  cfarDiscountPropertiesSelector,
  selectedFareDetailsSelector,
  isSpiritOrFrontierAirlinesSelectedSelector,
} from "../../../../reducer/selectors";
import {
  setSelectedCfarId,
  updateRefundableFaresProperties,
} from "../../../../actions/actions";
import { populateFlightBookQueryParams } from "../../../../../book/actions/actions";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../../../rewards/reducer/selectors";
import {
  cfarSocialVariantSelector,
  isRapidRebookRenameEnabledSelector,
  cfarDiscountPromoCopy,
  isAirRefundableFareCopyEnabledSelector,
  cfarRefundCopyChallenger1Selector,
  cfarRefundCopyChallenger2Selector,
  isCfarRefundCopyExperimentEnabledSelector,
} from "../../../../../ancillary/reducer";
import {
  isAirOfferRedesignEnabledSelector,
  isCfarCoMerchEnabledSelector,
} from "../../../../../ancillary/reducer";

import { CFAR_ADD_ON_CHOICE } from "redmond";
import { trackEvent } from "../../../../../../api/v0/analytics/trackEvent";
import { PATH_CFAR } from "../../../../../../utils/urlPaths";
import { DO_NOT_APPLY_OPTION_KEY } from "../../../../reducer";

const mapStateToProps = (state: IStoreState) => {
  const selectedTrip = selectedTripSelector(state);
  const tripId = selectedTrip.tripId || "";
  const tripDetails = tripDetailsSelector(state, tripId);

  const cfarDiscountProperties = cfarDiscountPropertiesSelector(state);

  return {
    doNotApplyCfarId: {
      policyId: DO_NOT_APPLY_OPTION_KEY,
      productId: DO_NOT_APPLY_OPTION_KEY,
    },
    handleCfarAddOnChoiceEvent: () => {
      trackEvent({
        eventName: CFAR_ADD_ON_CHOICE,
        properties: {
          ...cfarAddOnChoicePropertiesSelector(state),
          ...{
            cfar_discount_original_premium:
              cfarDiscountProperties.original_premium,
            cfar_discount_percentage:
              cfarDiscountProperties.discount_percentage,
          },
          // note: since populateFlightBookQueryParams is called to push the user to the checkout page, it's alway true
          success: true,
        },
      });
    },
    handleViewTerms: () => {
      window.open(PATH_CFAR, "_blank")?.focus();
    },

    tripDetails,
    selectedCfarId: selectedCfarIdSelector(state),
    cfarOffer: cfarOfferSelector(state),
    selectedAccountReferenceId:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    viewBookingAddOnProperties: viewBookingAddOnPropertiesSelector(state),
    cfarAttachPropertiesForViewBookingAddOn:
      cfarAttachPropertiesForViewBookingAddOnSelector(state),
    cfarAddOnChoiceProperties: cfarAddOnChoicePropertiesSelector(state),
    changePolicy: currentCfarChangePolicySelector(state),
    fetchAncillaryOfferCallState: fetchAncillaryOfferCallStateSelector(state),
    hasCfarOffer: hasCfarOfferSelector(state),
    showNoFtcOnlyInRefundableFares:
      showNoFtcOnlyInRefundableFaresSelector(state),
    cfarSocialVariant: cfarSocialVariantSelector(state),
    isRapidRebookRenameEnabled: isRapidRebookRenameEnabledSelector(state),
    cfarDiscountPromoCopy: cfarDiscountPromoCopy(state),
    isAirOfferRedesignEnabled: isAirOfferRedesignEnabledSelector(state),
    isCfarCoMerchEnabled: isCfarCoMerchEnabledSelector(state),
    selectedFare: selectedFareDetailsSelector(state),
    isAirRefundableCopyEnabled: isAirRefundableFareCopyEnabledSelector(state),
    isSpiritOrFrontierAirlinesSelected:
      isSpiritOrFrontierAirlinesSelectedSelector(state),
    isCfarRefundCopyChallenger2Variant:
      cfarRefundCopyChallenger2Selector(state),
    isCfarRefundCopyChallenger1Variant:
      cfarRefundCopyChallenger1Selector(state),
    isCfarRefundCopyExperimentEnabled:
      isCfarRefundCopyExperimentEnabledSelector(state),
  };
};

const mapDispatchToProps = {
  populateFlightBookQueryParams,
  setSelectedCfarId,
  updateRefundableFaresProperties,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CfarDetailsConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedCfarDetails = connector(withRouter(CfarAirDetails));
