import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { AncillaryPostBookingConfirmAndBook } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { purchasePostBookingOffer } from "../../actions/actions";

import {
  deletePaymentMethod,
  fetchProductToEarn,
  listPaymentMethods,
  resetPaymentCardSelectedAccounts,
  setCreditToApply,
  setOfferToApply,
  setSelectedPaymentMethodId,
  setSelectedRewardsPaymentTotal,
  setTravelWalletCredit,
  setTravelWalletOffers,
  verifyPaymentMethod,
} from "../../../book/actions/actions";
import {
  getRewardsAccounts,
  getUserIsPrimaryCardHolder,
} from "../../../rewards/reducer";
import {
  getBestOfferOverall,
  getCredit,
  getCreditToApply,
  getDeletePaymentMethodCallState,
  getFetchApplicableTravelWalletItemsCallState,
  getHasError,
  getIsCreditCardPaymentRequired,
  getIsStackedTravelWalletPaymentOnly,
  getIsTravelWalletOfferPaymentOnly,
  getListPaymentMethodsCallState,
  getMaxApplicableTravelWalletCreditAmount,
  getOffers,
  getOfferToApply,
  getPaymentMethods,
  getRewardsPaymentAccount,
  getRewardsPaymentAccountReferenceId,
  getRewardsPaymentInFiatCurrency,
  getRewardsPaymentInRewardsCurrency,
  getSelectedPaymentMethodId,
  getTravelWalletCreditToApplyAmount,
  getVerifyPaymentMethodCallState,
  rewardsAccountMinimumRequirementStateSelector,
} from "../../../book/reducer";
import { fetchRewardsAccounts } from "../../../rewards/actions/actions";
import {
  isDisruptionOptInEnabledSelector,
  isDisruptionPushNotifEnabled,
  isPostBookingOffersRewardsEnabledSelector,
  isRapidRebookRenameEnabledSelector,
} from "../../../ancillary/reducer";
import { actions } from "../../../book/actions";
import { getAllowRewardsWithPolicy } from "../../../shop/reducer";
import {
  getCreditBreakdown,
  getTravelCreditsHistoryTransaction,
} from "../../../travel-wallet/reducer";

const mapStateToPropsTravelWallet = (state: IStoreState) => ({
  offers: getOffers(state),
  offerToApply: getOfferToApply(state),
  fetchApplicableTravelWalletItemsCallState:
    getFetchApplicableTravelWalletItemsCallState(state),
  selectedPaymentMethodId: getSelectedPaymentMethodId(state) ?? "",
  selectedRewardsPaymentAccountId: getRewardsPaymentAccountReferenceId(state),
  credits: getCredit(state),
  creditBreakdown: getCreditBreakdown(state),
  creditsToApply: getCreditToApply(state),
  isStackedTravelWalletPaymentOnly: getIsStackedTravelWalletPaymentOnly(state),
  isTravelWalletOfferPaymentOnly: getIsTravelWalletOfferPaymentOnly(state),
  creditsHistoryTransaction: getTravelCreditsHistoryTransaction(state),
  bestOverallOffer: getBestOfferOverall(state),
  rewardsAccounts: getRewardsAccounts(state),
  maxApplicableTravelWalletCredit:
    getMaxApplicableTravelWalletCreditAmount(state),
});

const mapDispatchToPropsTravelWallet = {
  setOfferToApply,
  resetPaymentCardSelectedAccounts,
  setSelectedRewardsPaymentTotal,
  setCreditToApply,
};

const mapStateToPropsPayment = (state: IStoreState) => ({
  paymentMethods: getPaymentMethods(state),
  rewardsAccounts: getRewardsAccounts(state),
  selectedPaymentMethodId: getSelectedPaymentMethodId(state) ?? "",
  listPaymentMethodCallState: getListPaymentMethodsCallState(state),
  verifyPaymentMethodCallState: getVerifyPaymentMethodCallState(state),
  deletePaymentMethodCallState: getDeletePaymentMethodCallState(state),
  selectedRewardsPaymentAccount: getRewardsPaymentAccount(state),
  selectedRewardsPaymentAccountId: getRewardsPaymentAccountReferenceId(state),
  userIsPrimary: getUserIsPrimaryCardHolder(state),
  canRedeemRewards: getAllowRewardsWithPolicy(state),

  isTravelWalletOfferPaymentOnly: getIsTravelWalletOfferPaymentOnly(state),
  isStackedTravelWalletPaymentOnly: getIsStackedTravelWalletPaymentOnly(state),

  hasError: getHasError(state),
});

const mapDispatchToPropsPayment = {
  verifyPaymentMethod,
  listPaymentMethods,
  deletePaymentMethod,
  setSelectedPaymentMethodId,
  fetchRewardsAccounts,
  fetchProductToEarn,
};

const mapStateToPropsReward = (state: IStoreState) => ({
  rewardsAccounts: getRewardsAccounts(state),
  selectedRewardsPaymentAccountId: getRewardsPaymentAccountReferenceId(state),
  rewardsPaymentInFiatCurrency: getRewardsPaymentInFiatCurrency(state),
  rewardsPaymentInRewardsCurrency: getRewardsPaymentInRewardsCurrency(state),
  rewardsAccountMinimumRequirementState:
    rewardsAccountMinimumRequirementStateSelector(state),
  isCreditCardPaymentRequired: getIsCreditCardPaymentRequired(state),

  offerToApply: getOfferToApply(state),
  creditToApply: getCreditToApply(state),
});

const mapDispatchToPropsReward = {
  setSelectedRewardsAccountId: actions.setSelectedRewardsAccountReferenceId,
  setRewardsPaymentAmount: actions.setSelectedRewardsPaymentTotal,
  setPaymentMethod: actions.setPaymentMethod,

  fetchProductToEarn: actions.fetchProductToEarn,
  setRewardsConversionFailed: actions.setRewardsConversionFailed,
  setEarnValue: actions.setEarnValue,
  setQuotedRewardsTotal: actions.setQuotedRewardsTotal,
};

const mapStateToProps = (state: IStoreState) => ({
  isRapidRebookRenameEnabled: isRapidRebookRenameEnabledSelector(state),
  disruptionOptInEnabled: isDisruptionOptInEnabledSelector(state),
  disruptionDelayRewardsEnabled:
    isPostBookingOffersRewardsEnabledSelector(state),
  isDisruptionPushNotifEnabled: isDisruptionPushNotifEnabled(state),
  getTravelWalletCreditToApplyAmount: getTravelWalletCreditToApplyAmount(state),
  ...mapStateToPropsReward(state),
  ...mapStateToPropsPayment(state),
  ...mapStateToPropsTravelWallet(state),
});

const mapDispatchToProps = {
  purchasePostBookingOffer,
  setTravelWalletCredit,
  setTravelWalletOffers,
  ...mapDispatchToPropsReward,
  ...mapDispatchToPropsPayment,
  ...mapDispatchToPropsTravelWallet,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PostBookingConfirmAndBookConnectorProps = ConnectedProps<
  typeof connector
>;

export const PostBookingConfirmAndBook = withRouter(
  connector(AncillaryPostBookingConfirmAndBook)
);
