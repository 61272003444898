import React, { FC, useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import { Icon, IconName, ActionLink } from "halifax";
import { Dealness, Prediction, RecommendationV2Enum } from "redmond";
import clsx from "clsx";
import { useExperimentsById } from "@capone/experiments";
import {
  AVAILABLE,
  PRICE_DROP_CREDIT,
  useExperiments,
  getExperimentVariant,
} from "../../../../context/experiments";

const PricePredictionBar: FC<{
  prediction: Prediction;
  onOpenPrediction?: () => void;
  onOpenPriceDrop?: () => void;
  expandedGreen?: boolean;
}> = ({ prediction, onOpenPrediction, onOpenPriceDrop, expandedGreen }) => {
  const expState = useExperiments();
  const priceDropCreditGroup = getExperimentVariant(
    expState.experiments,
    PRICE_DROP_CREDIT
  );
  const isPriceDropCreditEnabled = priceDropCreditGroup === AVAILABLE;

  const corpHidePricePrediction =
    useExperimentsById("corp-hide-price-drop-protection")?.variant ===
    "available";

  const { dealness, recommendationV2 } = prediction;
  const dealSuggestion = useMemo((): string => {
    switch (dealness) {
      case "great":
        return "This is a great price";
      case "good":
        return "This is a good price";
      case "fair":
        return "This is a fair price";
      case "wait":
        return "Wait";
      default:
        return "";
    }
  }, [dealness]);

  return (
    <Box
      className={clsx("mobile-price-prediction-bar", "price-prediction-card", {
        "expanded-green": expandedGreen,
      })}
    >
      <Typography variant="h6" className="mobile-price-prediction-title">
        {prediction?.predictionCopy?.recommendationTitle[0]}
        {recommendationV2 !== RecommendationV2Enum.BuyNeutral && (
          <div onClick={onOpenPrediction}>
            <Icon name={IconName.InfoCircle} className="info-icon" />
          </div>
        )}
      </Typography>

      {(recommendationV2 === RecommendationV2Enum.BuyNeutral ||
        dealness !== Dealness.Wait) &&
        !corpHidePricePrediction && (
          <>
            <Typography
              variant="body2"
              className={clsx("mobile-price-prediction-detail", {
                "buy-neutral":
                  recommendationV2 === RecommendationV2Enum.BuyNeutral,
              })}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    prediction?.predictionCopy?.recommendationBody[0] ?? "",
                }}
              />
              {/* TODO: Make hardcoded days and cap dynamic*/}
              {recommendationV2 !== RecommendationV2Enum.BuyNeutral && (
                <>
                  {isPriceDropCreditEnabled
                    ? " If the price drops within 10 days, you'll get back up to $50 of the difference as a travel credit with "
                    : " If the price drops within 10 days, you'll get back up to $50 of the difference with "}
                  <ActionLink
                    onClick={onOpenPriceDrop}
                    content={
                      <>
                        <b>free price drop protection</b>
                        <Icon
                          name={IconName.InfoCircle}
                          className="info-icon"
                        />
                      </>
                    }
                  />
                </>
              )}
            </Typography>

            {recommendationV2 === RecommendationV2Enum.Buy && (
              <Typography
                variant="caption"
                className="mobile-price-prediction-protection"
              >
                <Icon
                  name={IconName.PriceDropProtectionShield}
                  className="info-icon"
                />
                <span>Free price drop protection included.</span>
                <div onClick={onOpenPriceDrop}>
                  <Icon name={IconName.InfoCircle} className="info-icon" />
                </div>
              </Typography>
            )}
          </>
        )}
      <div
        className={clsx(
          "mobile-price-prediction-bar-line",
          dealness.toString()
        )}
      >
        <div
          className={clsx(
            "mobile-price-prediction-bar-marker",
            dealness.toString()
          )}
        />
      </div>

      <Typography
        variant="subtitle2"
        className={clsx("more-info", dealness.toString())}
      >
        {dealSuggestion}
      </Typography>
      <ActionLink
        className="mobile-price-prediction-learn-more"
        content={
          <>
            <b>Learn how price prediction works</b>
            <Icon name={IconName.InfoCircle} className="info-icon" />
          </>
        }
        onClick={onOpenPriceDrop}
      />
    </Box>
  );
};

export default PricePredictionBar;
