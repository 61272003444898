import {
  ActionLink,
  B2BSpinner,
  ButtonWrap,
  CloseButtonIcon,
  FlightCategoryRadio,
  Header,
  Icon,
  IconName,
  LoadingPopup,
} from "halifax";
import React, { useEffect } from "react";

import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { RouteComponentProps } from "react-router";
import {
  FareclassOptionFilter,
  ITripTerminus,
  RecentFlightSearch,
  TripCategory,
} from "redmond";
import {
  getExperimentVariantCustomVariants,
  NOT_SELF_FUNDED,
  PACKAGES_EXPERIMENT,
  PACKAGES_EXPERIMENT_VARIANTS,
  SELF_FUNDED,
  useExperiments,
} from "../../../../context/experiments";
import { generatePackagesCTAUrl } from "../../../../utils/urlHelpers";
import { PATH_HOME } from "../../../../utils/urlPaths";
import { MulticityFlightShopStep } from "../../../shop/reducer";
import { MobileFlightSearchStep } from "../../reducer";
import { LocationSearch, MobileCalendarPicker } from "./components";
import { MobileFlightSearchControlV3ConnectorProps } from "./container";
import "./styles.scss";
import * as textConstants from "./textConstants";
export interface IMobileFlightSearchControlV3Props
  extends MobileFlightSearchControlV3ConnectorProps,
    RouteComponentProps {
  isMultiCityEnabled?: boolean;
  recentSearches?: RecentFlightSearch[];
  onRecentSearchClick?: (search: RecentFlightSearch) => void;
  onSelectLocation?: (value: any) => void;
  onSelectDates?: (departure: Date | null, returnDate: Date | null) => void;
  isAirCXV3Experiment?: boolean;
  isCustomerProfileExperiment?: boolean;
  fareclassOptionFilter: FareclassOptionFilter;
}

export const MobileFlightSearchControlV3 = (
  props: IMobileFlightSearchControlV3Props
) => {
  const {
    origin,
    destination,
    originCode,
    destinationCode,
    tripCategory,
    fetchDepartureCalendar,
    setCalendar,
    departureDateBucketsLoading,
    currentStep,
    departureDate,
    returnDate,
    setCurrentStep,
    setTripCategory,
    history,
    hasOriginAutocompleteError,
    hasDestinationAutocompleteError,
    setOriginAutocompleteError,
    setDestinationAutocompleteError,
    isMultiCityEnabled,
    populateFlightShopQueryParams,
    recentSearches,
    onRecentSearchClick,
    onSelectLocation,
    onSelectDates,
    isAirCXV3Experiment,
    fareclassOptionFilter,
    stopsOption,
    adultsCount,
    childrenCount,
    infantsOnLapCount,
    infantsInSeatCount,
  } = props;

  const focusedMonthIndex = departureDate
    ? dayjs(departureDate).diff(dayjs(), "month")
    : 0;

  const handleGoBack = () => {
    setCurrentStep(
      currentStep > MobileFlightSearchStep.LocationSearch
        ? currentStep - 1
        : currentStep
    );
  };

  const renderPackagesCTA = () => {
    const handlePackagesCTAClick = () => {
      const url = generatePackagesCTAUrl({
        origin: origin,
        destination: destination,
        fromDate: departureDate,
        untilDate: returnDate,
        stopsOption,
        fareClass: fareclassOptionFilter,
        adultsCount,
        childrenCount,
        infantsInSeatCount,
        infantsOnLapCount,
        matchesMobile: true,
      });

      history.push(url);
    };

    return (
      <ButtonWrap
        className="flights-info-cta"
        onClick={() => {
          handlePackagesCTAClick();
        }}
      >
        <div className="header-container">
          <div className="packages-icon-wrapper">
            <Icon name={IconName.HotelFunnelIcon} />
            <Typography className="icon-plus-separator">+</Typography>
            <Icon name={IconName.FlightFunnelIcon} />
          </div>
          <Typography>{textConstants.INFO_NEW_PACKAGES_CTA_HEADER}</Typography>
          <Box className="new-tag">New</Box>
        </div>
        <div className="body-container">
          <Typography>
            {textConstants.INFO_NEW_PACKAGES_CTA_BODY_TEXT}
          </Typography>
          <FontAwesomeIcon
            className="mobile-right-chevron"
            icon={faChevronRight}
          />
        </div>
      </ButtonWrap>
    );
  };

  useEffect(() => {
    if (originCode && destinationCode) {
      fetchDepartureCalendar(
        origin as ITripTerminus,
        destination as ITripTerminus
      );
    } else {
      setCalendar();
    }
  }, [originCode, destinationCode, tripCategory]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const resetAutocompleteErrors = () => {
    hasOriginAutocompleteError && setOriginAutocompleteError(false);
    hasDestinationAutocompleteError && setDestinationAutocompleteError(false);
  };

  const expState = useExperiments();
  const packagesExperimentVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    PACKAGES_EXPERIMENT,
    PACKAGES_EXPERIMENT_VARIANTS
  );

  const isPackagesExperiment = [SELF_FUNDED, NOT_SELF_FUNDED].includes(
    packagesExperimentVariant
  );

  return (
    <Box
      className={clsx("mobile-flight-search-v3", {
        "date-range-picker":
          currentStep === MobileFlightSearchStep.CalendarPicker,
      })}
    >
      <Header
        className="homepage"
        center={
          <HeaderCenterSection
            originCode={originCode}
            destinationCode={destinationCode}
            currentStep={currentStep}
          />
        }
        left={
          <ActionLink
            className={clsx("mobile-flight-search-header-go-back", {
              hidden: currentStep === MobileFlightSearchStep.LocationSearch,
            })}
            onClick={handleGoBack}
            content={<FontAwesomeIcon icon={faChevronLeft} />}
          />
        }
        right={
          <ActionLink
            className="mobile-flight-search-header-close"
            onClick={() => {
              setCurrentStep(MobileFlightSearchStep.LocationSearch);
              history.push(PATH_HOME);
              resetAutocompleteErrors();
            }}
            content={<CloseButtonIcon />}
            label="Close"
          />
        }
        isMobile={true}
        fullWidth={true}
      />
      {currentStep === MobileFlightSearchStep.LocationSearch && (
        <Box className="location-search-container">
          <FlightCategoryRadio
            setTripCategory={setTripCategory}
            selectedCategory={tripCategory}
            isMultiCityFlightsAvailable={isMultiCityEnabled}
          />

          <LocationSearch
            onComplete={() => {
              if (tripCategory === TripCategory.MULTI_CITY) {
                populateFlightShopQueryParams({
                  history,
                  useHistoryPush: true,
                  forceQueryUpdate: false,
                  newQueryParams: {
                    multicityFlightShopProgress:
                      MulticityFlightShopStep.ChooseDeparture0,
                  },
                });
              } else {
                setCurrentStep(MobileFlightSearchStep.CalendarPicker);
              }
            }}
            currentTripCategory={tripCategory}
            history={history}
            recentSearches={recentSearches}
            onRecentSearchClick={onRecentSearchClick}
            onSelectLocation={onSelectLocation}
            isAirCXV3Experiment={isAirCXV3Experiment}
          />
          {isPackagesExperiment && renderPackagesCTA()}
        </Box>
      )}
      {currentStep === MobileFlightSearchStep.CalendarPicker && (
        <MobileCalendarPicker
          focusedMonthIndex={focusedMonthIndex}
          returnDate={returnDate}
          departure={departureDate}
          onSelectDates={onSelectDates}
        />
      )}
      {
        <LoadingPopup
          indicatorSize={"small"}
          indicator={B2BSpinner}
          open={!!departureDateBucketsLoading}
          message={textConstants.UPDATE_CALENDAR_TEXT}
        />
      }
    </Box>
  );
};

interface IHeaderCenterSectionProps {
  originCode?: string;
  destinationCode?: string;
  currentStep: MobileFlightSearchStep;
}

const HeaderCenterSection = (props: IHeaderCenterSectionProps) => {
  const { originCode, destinationCode, currentStep } = props;
  const headerText =
    currentStep === MobileFlightSearchStep.CalendarPicker
      ? textConstants.CHOOSE_DATE
      : textConstants.FLIGHTS_HEADER;
  return (
    <Box className="header-center-section">
      {originCode &&
      destinationCode &&
      currentStep === MobileFlightSearchStep.CalendarPicker ? (
        <Box className="trip-origin-destination-summary">
          <Box className="origin-destination-container">
            <Typography variant="body1">{headerText}</Typography>
          </Box>
        </Box>
      ) : (
        <Box className="mobile-flight-search-location-label">
          <span>{textConstants.FLIGHTS_HEADER}</span>
        </Box>
      )}
    </Box>
  );
};
