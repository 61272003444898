import { ITestimony } from "halifax";

export const TESTIMONY_CAROUSEL_TITLE =
  "Don't just take our word for it. Here's what travelers are saying.";

export const TESTIMONY_PRODUCT_NAME_RAPID_REBOOKING_OLD = "Rapid Rebooking";
export const TESTIMONY_PRODUCT_NAME_RAPID_REBOOKING_NEW =
  "Flight disruption assistance";

export const TESTIMONY_PRODUCT_NAME_CFAR = "Cancel for any reason";

export const TESTIMONIES_RAPID_REBOOKING_OLD: ITestimony[] = [
  {
    testimonyText:
      "My wife was stranded after her connection was canceled. I was able to get her rebooked...I had peace of mind I was covered...",
    author: "Mindy B",
    productName: TESTIMONY_PRODUCT_NAME_RAPID_REBOOKING_OLD,
  },
  {
    testimonyText: "The rapid rebooking feature makes travel less stressful.",
    author: "Nicole T",
    productName: TESTIMONY_PRODUCT_NAME_RAPID_REBOOKING_OLD,
  },
  {
    testimonyText:
      "The rapid rebooking option is a highly desired option with today's airline travel climate.",
    author: "Jamie M",
    productName: TESTIMONY_PRODUCT_NAME_RAPID_REBOOKING_OLD,
  },
  {
    testimonyText:
      "Capital One was proactive when they understood my flight was delayed. Provided me solutions to ensure my trip with my family was uninterrupted.",
    author: "Leston S",
    productName: TESTIMONY_PRODUCT_NAME_RAPID_REBOOKING_OLD,
  },
  {
    testimonyText:
      "Our flight was delayed...and we received all our miles back for that flight.",
    author: "Kim H",
    productName: TESTIMONY_PRODUCT_NAME_RAPID_REBOOKING_OLD,
  },
  {
    testimonyText:
      "When our flight was canceled due to weather it was effortless to get a new flight out. Glad I chose rapid rebooking!!",
    author: "Vanessa B",
    productName: TESTIMONY_PRODUCT_NAME_RAPID_REBOOKING_OLD,
  },
  {
    testimonyText:
      "Our flight was canceled and because we had [rapid rebooking], we got a new flight at no additional cost to us.",
    author: "William M",
    productName: TESTIMONY_PRODUCT_NAME_RAPID_REBOOKING_OLD,
  },
];

export const TESTIMONIES_RAPID_REBOOKING_NEW: ITestimony[] = [
  {
    testimonyText:
      "My wife was stranded after her connection was canceled. I was able to get her rebooked...I had peace of mind I was covered...",
    author: "Mindy B",
    productName: TESTIMONY_PRODUCT_NAME_RAPID_REBOOKING_NEW,
  },
  {
    testimonyText:
      "The [flight disruption assistance] feature makes travel less stressful.",
    author: "Nicole T",
    productName: TESTIMONY_PRODUCT_NAME_RAPID_REBOOKING_NEW,
  },
  {
    testimonyText:
      "The [flight disruption assistance] option is a highly desired option with today's airline travel climate.",
    author: "Jamie M",
    productName: TESTIMONY_PRODUCT_NAME_RAPID_REBOOKING_NEW,
  },
  {
    testimonyText:
      "Capital One was proactive when they understood my flight was delayed. Provided me solutions to ensure my trip with my family was uninterrupted.",
    author: "Leston S",
    productName: TESTIMONY_PRODUCT_NAME_RAPID_REBOOKING_NEW,
  },
  {
    testimonyText:
      "Our flight was delayed...and we received all our miles back for that flight.",
    author: "Kim H",
    productName: TESTIMONY_PRODUCT_NAME_RAPID_REBOOKING_NEW,
  },
  {
    testimonyText:
      "When our flight was canceled due to weather it was effortless to get a new flight out. Glad I chose [flight disruption assistance]!!",
    author: "Vanessa B",
    productName: TESTIMONY_PRODUCT_NAME_RAPID_REBOOKING_NEW,
  },
  {
    testimonyText:
      "Our flight was canceled and because we had [flight disruption assistance], we got a new flight at no additional cost to us.",
    author: "William M",
    productName: TESTIMONY_PRODUCT_NAME_RAPID_REBOOKING_NEW,
  },
];

export const TESTIMONIES_CFAR: ITestimony[] = [
  {
    testimonyText: "It provides peace of mind, which is always appreciated.",
    author: "Jessica B",
    productName: TESTIMONY_PRODUCT_NAME_CFAR,
  },
  {
    testimonyText:
      "Wouldn't change a thing. It's the peace of mind in the just in case moments.",
    author: "William V",
    productName: TESTIMONY_PRODUCT_NAME_CFAR,
  },
  {
    testimonyText:
      "It's a great way to make a great trip even better, no worries!",
    author: "Scott C",
    productName: TESTIMONY_PRODUCT_NAME_CFAR,
  },
  {
    testimonyText: "Excellent way to cancel a trip without any hassles!",
    author: "Angela M",
    productName: TESTIMONY_PRODUCT_NAME_CFAR,
  },
];
