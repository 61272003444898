import { connect, ConnectedProps } from "react-redux";

import { FlightShopHeader } from "./component";
import {
  getTripCategory,
  getMulticityRoutes,
  getDestination,
  getOrigin,
  getAwaitingRefetch,
  getDepartureDate,
  getReturnDate,
  getStopsOption,
  getAirlineFilter,
  getMaxPriceFilter,
  getHasSetOutboundTimeRange,
  getHasSetReturnTimeRange,
  getFareclassOptionFilter,
  getDurationFilter,
  getHasSetAirlineFilter,
  getHasSetFareClassFilter,
  getHasSetStopsOption,
  getActiveFiltersCount,
  getPolicyFilter,
} from "../../../search/reducer/selectors";
import {
  flightShopProgressSelector,
  flightShopMulticityProgressSelector,
  selectedTripSelector,
  selectedMulticityTripsSelector,
  sortOptionSelector,
  tripDetailsSelector,
  tripSummariesLoadingSelector,
  isInDisruptionProtectionRebookSelector,
  isPartiallyRebookingSelector,
  isPartiallyRebookingNotificationPropsSelector,
  hasActiveRefundableFareInFlightShopSelector,
  flightsByFlightShopTypeSelector,
  allFlightShopFilterSelector,
  flightShopProductRedeemChoice,
  hasSetMaxPriceFilterSelectorV2,
} from "../../reducer/selectors";
import {
  originalSliceFlightSummaryPropsSelector,
  isSelectingReturnFlightSelector,
  rebookDepartureDateTimeSelector,
  isAirOfferRedesignEnabledSelector,
} from "../../../ancillary/reducer/selectors";
import { actions } from "../../../shop/actions";

import { IStoreState } from "../../../../reducers/types";
import { TripCategory } from "redmond";
import { isCfarMulticityEnabledSelector } from "../../reducer/selectors/experiments";

const mapStateToProps = (state: IStoreState) => {
  // TODO: handle hasChosenDepartureFlight logic properly once selected fare option is stored in redux
  const tripId = selectedTripSelector(state)?.tripId ?? "";
  const tripCategory = getTripCategory(state);
  const isMulticity = tripCategory === TripCategory.MULTI_CITY;
  const selectedMulticityTrips = selectedMulticityTripsSelector(state);
  const {
    tripId: selectedFirstMcTripId,
    departureSliceId,
    departureFareId,
  } = selectedMulticityTrips[0];
  const hasSelectedFirstMulticityTrip =
    isMulticity &&
    !!selectedFirstMcTripId &&
    !!departureSliceId &&
    !!departureFareId;
  const hasChosenDepartureFlight =
    hasSelectedFirstMulticityTrip ||
    (!isMulticity && tripDetailsSelector(state, tripId) !== undefined);

  return {
    currentStep: flightShopProgressSelector(state),
    currentMulticityStep: flightShopMulticityProgressSelector(state),
    tripCategory,
    destination: getDestination(state),
    origin: getOrigin(state),
    multicityRoutes: getMulticityRoutes(state),
    hasChosenDepartureFlight,
    awaitingRefetch: getAwaitingRefetch(state),
    tripSummariesLoading: tripSummariesLoadingSelector(state),
    productRedeemChoice: flightShopProductRedeemChoice(state),
    isInDisruptionProtectionRebook:
      isInDisruptionProtectionRebookSelector(state),
    isPartiallyRebooking: isPartiallyRebookingSelector(state),
    originalSliceFlightSummaryProps:
      originalSliceFlightSummaryPropsSelector(state),
    isSelectingReturnFlight: isSelectingReturnFlightSelector(state),
    isPartiallyRebookingNotificationProps:
      isPartiallyRebookingNotificationPropsSelector(state),
    rebookDepartureDateTime: rebookDepartureDateTimeSelector(state),
    hasActiveRefundableFare: hasActiveRefundableFareInFlightShopSelector(state),
    airportMap: flightsByFlightShopTypeSelector(state)?.airports,
    departureDate: getDepartureDate(state),
    returnDate: getReturnDate(state),
    tripDetails: tripDetailsSelector(state, tripId),
    stopsOption: getStopsOption(state),
    airlineFilter: getAirlineFilter(state),
    hasSetOutboundTimeRange: getHasSetOutboundTimeRange(state),
    hasSetReturnTimeRange: getHasSetReturnTimeRange(state),
    maxPriceFilter: getMaxPriceFilter(state),
    airlines: allFlightShopFilterSelector(state).airlineOptions,
    sortOption: sortOptionSelector(state),
    fareclassFilter: getFareclassOptionFilter(state),
    isCfarMulticityEnabled: isCfarMulticityEnabledSelector(state),
    isAirOfferRedesignEnabled: isAirOfferRedesignEnabledSelector(state),
    durationFilter: getDurationFilter(state),
    hasSetAirlineFilter: getHasSetAirlineFilter(state),
    hasSetFareclassFilter: getHasSetFareClassFilter(state),
    hasSetMaxPrice: hasSetMaxPriceFilterSelectorV2(state),
    hasSetStopsOption: getHasSetStopsOption(state),
    activeFiltersCount: getActiveFiltersCount(state),
    policyFilter: getPolicyFilter(state),
  };
};

const mapDispatchToProps = {
  setSortOption: actions.setSortOption,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightShopHeaderConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFlightShopHeader = connector(FlightShopHeader);
