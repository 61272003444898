import axios, { AxiosResponse } from "axios";
import { delay, putResolve, select } from "redux-saga/effects";
import {
  COMPLETE_BUY_AIR,
  PriceFreezeFlightPaymentFinalizeResult,
  DisruptionOptInProperties,
  DISRUPTION_SOURCE,
  UncategorizedPurchaseError,
  PriceDropProtectionEnum,
  ITrackingProperties,
} from "redmond";
import {
  TravelItineraryEnum,
  MultiTravelItinerary,
  SingleTravelItinerary,
  PurchaseResult,
} from "@b2bportal/air-booking-api";
import {
  FulfillFailure,
  FulfillResponse,
  FulfillResponseEnum,
  FulfillSuccess,
  FulfillSuccessV0,
  Product,
  purchaseApi,
} from "@b2bportal/purchase-api";
import { IStoreState } from "../../../../reducers/types";
import {
  getCompleteBuyAirPriceFreezeProperties,
  getCompleteBuyAirProperties,
  getCompleteBuyTravelOfferProperties,
  getPriceDropProperties,
  getPurchaseError,
  getAgentErrorTitleFromPurchaseError,
  hasActiveRefundableFareInFlightBookSelector,
  getSession,
} from "../../reducer";
import {
  refundableFaresPropertiesSelector,
  cfarDiscountPropertiesSelector,
  predictionSelector,
} from "../../../shop/reducer";
import {
  hasSMSBeenSelected,
  hasAppNotifBeenSelected,
  hasDisruptionProtectionBeenAttachedSelector,
} from "../../../book/reducer/selectors";
import Logger from "../../../../helpers/Logger";
import {
  setPollFinalizedCallStateFailure,
  setPollFinalizedCallStateSuccess,
  setFinalized,
  IPollFinalized,
} from "../../actions/actions";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { isDisruptionOptInEnabledSelector } from "../../../ancillary/reducer";

export function* pollFinalizedSaga({ isPriceFreezePurchase }: IPollFinalized) {
  try {
    const state: IStoreState = yield select();
    const sessionToken = getSession(state);
    const completeBuyAirProperties = getCompleteBuyAirProperties(state);
    const completeBuyAirPriceFreezeProperties =
      getCompleteBuyAirPriceFreezeProperties(state);
    const completeBuyTravelOfferProperties =
      getCompleteBuyTravelOfferProperties(state);
    const priceDropProperties = getPriceDropProperties(state);
    const hasDisruptionBeenAttached =
      hasDisruptionProtectionBeenAttachedSelector(state);
    const isDisruptionOptInEnabled = isDisruptionOptInEnabledSelector(state);
    const disruptionNotificationOptInProperties: DisruptionOptInProperties =
      hasDisruptionBeenAttached && isDisruptionOptInEnabled
        ? {
            messaging_source: DISRUPTION_SOURCE,
            messaging_channel_preference_sms: hasSMSBeenSelected(state),
            messaging_channel_preference_app_notif:
              hasAppNotifBeenSelected(state),
          }
        : {};

    const prediction = predictionSelector(state);
    const predictionProperties: ITrackingProperties = {
      properties: {},
      encryptedProperties: [
        prediction?.priceDropProtection?.trackingPropertiesV2
          ?.encryptedProperties ?? "",
      ],
    };
    if (!sessionToken) {
      throw new Error("Session token is not present.");
    }

    const delayTimes = [1000];

    let pollTerminated = false;
    let index = 0;

    const request = () =>
      purchaseApi(axios as any).apiV0PurchaseFulfillPollPost(sessionToken);

    while (!pollTerminated) {
      yield delay(delayTimes[index]);

      const finalizedCheckedResponse: AxiosResponse<FulfillResponse> =
        yield request().catch((e) => {
          // [CMKT-1150] do not error out on network errors, instead retry
          console.error("Poll finalized flight failed", e);
          return Promise.resolve({
            data: { FulfillResponse: FulfillResponseEnum.Pending },
            status: 200,
            statusText: "Gateway timeout",
            headers: [],
            config: {},
          });
        });

      const fulfillRespnse = finalizedCheckedResponse.data;

      switch (fulfillRespnse.FulfillResponse) {
        case FulfillResponseEnum.Failure:
          pollTerminated = true;
          const failedResponse = fulfillRespnse as FulfillFailure;
          const errors = failedResponse.errors.map(getPurchaseError);

          if (failedResponse.errors.length > 0) {
            yield putResolve(setPollFinalizedCallStateFailure(errors));
            trackEvent({
              eventName: COMPLETE_BUY_AIR,
              properties: {
                ...completeBuyAirProperties,
                ...completeBuyAirPriceFreezeProperties,
                ...completeBuyTravelOfferProperties.properties,
                price_bucket: priceDropProperties.price_bucket,
                failure_reason: errors
                  .map((error) => {
                    if (!!(error as UncategorizedPurchaseError)?.code) {
                      return (error as UncategorizedPurchaseError)?.code;
                    } else {
                      return getAgentErrorTitleFromPurchaseError(error);
                    }
                  })
                  .join(" - "),
                success: false,
              },
              encryptedProperties: [
                ...completeBuyTravelOfferProperties.encryptedProperties,
              ],
            });
          } else {
            throw new Error(
              "Poll Finalized response returned an error and the given error code is not handleable."
            );
          }

          return;
        case FulfillResponseEnum.Pending:
          break;
        case FulfillResponseEnum.Success:
          pollTerminated = true;
          const finalizedResponse = fulfillRespnse as
            | FulfillSuccess
            | FulfillSuccessV0;
          yield putResolve(setPollFinalizedCallStateSuccess());

          switch ((finalizedResponse as FulfillSuccessV0).fulfillment.type) {
            case Product.Flight:
              if (isPriceFreezePurchase) {
                throw new Error("invalid fulfill response");
              }

              const flightPurchaseFinalized = (
                finalizedResponse as FulfillSuccessV0
              ).fulfillment.value as PurchaseResult;
              yield putResolve(
                setFinalized(Product.Flight, flightPurchaseFinalized)
              );

              const trip = flightPurchaseFinalized.itinerary.travelItinerary;
              let brand = null;

              let provider = "";
              let itineraryProvider = "";
              if (
                trip &&
                trip?.TravelItinerary ===
                  TravelItineraryEnum.SingleTravelItinerary
              ) {
                brand = (trip as SingleTravelItinerary).slices[0].segments[0]
                  .fareBrand;
              } else if (
                trip &&
                trip?.TravelItinerary ===
                  TravelItineraryEnum.MultiTravelItinerary
              ) {
                const multiTrip = trip as MultiTravelItinerary;
                brand =
                  multiTrip.travelItineraries[0].slices[0].segments[0]
                    .fareBrand;
                itineraryProvider = multiTrip.locators?.agent.provider ?? "";
              }

              if (brand) {
                if (brand.sabreFareBrand) {
                  provider = "Sabre";
                } else if (brand.travelportFareBrand) {
                  provider = "Travelport";
                } else if (brand.amadeusFareBrand) {
                  provider = "Amadeus";
                } else if (brand.gdxFareBrand) {
                  provider = "GDX";
                } else if ((brand as any).travelFusionFareBrand) {
                  provider = "TravelFusion";
                }
              }

              const { cfar_fare_choice, cfar_choice } =
                refundableFaresPropertiesSelector(state);
              const hasActiveRefundableFare =
                hasActiveRefundableFareInFlightBookSelector(state);
              const cfarDiscountProperties =
                cfarDiscountPropertiesSelector(state);
              trackEvent({
                eventName: COMPLETE_BUY_AIR,
                properties: {
                  ...completeBuyAirProperties,
                  ...completeBuyAirPriceFreezeProperties,
                  ...completeBuyTravelOfferProperties.properties,
                  ...disruptionNotificationOptInProperties,
                  price_bucket: priceDropProperties.price_bucket,
                  success: true,
                  provider: itineraryProvider ?? provider ?? "",
                  agent_locator:
                    itineraryProvider === "multiProvider"
                      ? flightPurchaseFinalized.itinerary.travelItinerary
                          .locators?.agent.value
                      : flightPurchaseFinalized.itinerary.travelItinerary
                          .locators?.agent.unscopedValue,
                  cfar_fare_choice:
                    cfar_fare_choice ??
                    (hasActiveRefundableFare ? 1 : undefined),
                  cfar_discount_original_premium:
                    cfarDiscountProperties.original_premium,
                  cfar_discount_percentage:
                    cfarDiscountProperties.discount_percentage,
                  cfar_choice,
                  price_drop_added:
                    flightPurchaseFinalized.itinerary.priceDropEligibility
                      .PriceDropProtection ===
                    PriceDropProtectionEnum.IsEligible,
                  evaluated_recommendation: prediction?.recommendationV2,
                },

                encryptedProperties: [
                  ...completeBuyTravelOfferProperties.encryptedProperties,
                  ...predictionProperties.encryptedProperties,
                ],
              });
              break;
            case Product.AirPriceFreeze:
              if (!isPriceFreezePurchase) {
                throw new Error("invalid fulfill response");
              }

              const priceFreezePurchaseResult = (
                finalizedResponse as FulfillSuccessV0
              ).fulfillment.value as PriceFreezeFlightPaymentFinalizeResult;
              yield putResolve(
                setFinalized(Product.AirPriceFreeze, priceFreezePurchaseResult)
              );
              // TODO: handle tracking for PF purchase
              break;
          }
      }

      // if we want to give up on polling we should instead direct the user to my trips
      if (index >= delayTimes.length) {
        pollTerminated = true;
        window.location.pathname = "/trips";
      }

      if (index !== delayTimes.length - 1) {
        index++;
      }
    }
  } catch (e) {
    Logger.debug(e);
    yield putResolve(setPollFinalizedCallStateFailure([]));
    const state: IStoreState = yield select();
    const completeBuyAirProperties = getCompleteBuyAirProperties(state);
    const completeBuyAirPriceFreezeProperties =
      getCompleteBuyAirPriceFreezeProperties(state);
    const completeBuyTravelOfferProperties =
      getCompleteBuyTravelOfferProperties(state);
    const priceDropProperties = getPriceDropProperties(state);

    // TODO: differentiate tracking for PF purchase
    trackEvent({
      eventName: COMPLETE_BUY_AIR, // default to normal complete_buy event (not multicity)
      properties: {
        ...completeBuyAirProperties,
        ...completeBuyAirPriceFreezeProperties,
        ...completeBuyTravelOfferProperties.properties,
        price_bucket: priceDropProperties.price_bucket,
        failure_reason: (e as Error).message,
      },
      encryptedProperties: [
        ...completeBuyTravelOfferProperties.encryptedProperties,
      ],
    });
  }
}
