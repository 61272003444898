export const DISRUPTION_PROTECTION_TEXT_OLD = "Rapid rebooking";
export const DISRUPTION_PROTECTION_TEXT_NEW = "Flight disruption assistance";
// export const MISSED_CONNECTION_GUARANTEE = "Missed connection guarantee";
/** @see https://hopper-jira.atlassian.net/browse/COTA-1093 */
export const MISSED_CONNECTION_REBOOKING = "Missed connection rebooking";
export const CHOOSE_DEPARTURE_TEXT = "Departure";
export const CHOOSE_OUTBOUND_TEXT = "Outbound";
export const CHOOSE_RETURN_TEXT = "Return";
export const REVIEW_ITINERARY_TEXT = "Review itinerary";
export const CUSTOMIZE_TEXT = "Customize";
export const BOOK_TEXT = "Book";
export const BACK_TO_FLIGHT_RESULTS = "Back to Flight Results";
