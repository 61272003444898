import {
  AncillaryFetchOfferFailure,
  AncillaryFetchOfferResponse,
  AncillaryFetchOfferResponseEnum,
  AncillaryFetchOfferSuccess,
  AncillaryKindEnum,
  AncillaryChfarOffer,
  AncillaryOfferEnum,
  AncillaryFetchOfferRequest,
  ChfarAirOrchestratorEvent,
} from "redmond";

import { GetAirChfarOfferRequestV1 } from "@b2bportal/air-chfar-api";

import { call, put, select } from "redux-saga/effects";

import { getAncillaryOffer } from "../../../api/v2/ancillary/getAncillaryOffer";
import {
  fetchAncillaryOfferRequestParametersSelector,
  cfarOfferFromRefundableFaresSelector,
} from "../reducer/selectors";
import { fetchAncillaryOfferRequestParametersByPriceFreezeSelector } from "../../freeze/reducer/selectors";
import { isChfarEnabledSelector } from "../../ancillary/reducer/selectors";
import { actions } from "../actions";
import Logger from "../../../helpers/Logger";
import { getAirChfarOffer } from "../../../api/v2/ancillary/getAirChfarOffer";
import { trackEvent } from "../../../api/v0/analytics/trackEvent";

export function* fetchAncillaryOfferSaga({
  isPriceFreezeOverview,
  request,
}: actions.IFetchAncillaryOffer) {
  try {
    const fetchAncillaryOfferSelector = isPriceFreezeOverview
      ? fetchAncillaryOfferRequestParametersByPriceFreezeSelector
      : fetchAncillaryOfferRequestParametersSelector;

    const fetchAncillaryOfferRequest: AncillaryFetchOfferRequest | null =
      request ?? (yield select(fetchAncillaryOfferSelector));

    if (!fetchAncillaryOfferRequest) {
      yield put(actions.setFetchAncillaryOfferCallStateFailed());
      return;
    }

    const hasCfarOfferFromRefundableFares: boolean = yield select(
      cfarOfferFromRefundableFaresSelector
    );

    const response: AncillaryFetchOfferResponse = yield call(
      getAncillaryOffer,
      {
        ...fetchAncillaryOfferRequest,
        // note: when there is already a CFAR offer stored through selecting a refundable fare, only DP offers need to be requested
        requestedProducts: hasCfarOfferFromRefundableFares
          ? [AncillaryKindEnum.Delay, AncillaryKindEnum.MissedConnection]
          : undefined,
      }
    );

    const responseFailure = response as AncillaryFetchOfferFailure;

    if (responseFailure.Failure) {
      yield put(actions.setFetchAncillaryOfferCallStateFailed());
      return;
    }

    const responseSuccess = response as AncillaryFetchOfferSuccess;
    switch (responseSuccess.FetchOfferResponseV2) {
      case AncillaryFetchOfferResponseEnum.MultiTicketV2:
      case AncillaryFetchOfferResponseEnum.SingleTicket:
      case AncillaryFetchOfferResponseEnum.MultiCity:
        break;
      default:
        yield put(actions.setFetchAncillaryOfferCallStateFailed());
        return;
    }

    const isChfarEnabled: boolean = yield select(isChfarEnabledSelector);
    if (isChfarEnabled) {
      const ancillaryChfarOffer: AncillaryChfarOffer | undefined = yield call(
        fetchAncillaryChfarOffer,
        {
          tripId: fetchAncillaryOfferRequest.tripId,
          fareId: fetchAncillaryOfferRequest.fareId,
        }
      );

      if (ancillaryChfarOffer) {
        responseSuccess.ancillaryOffers.push(ancillaryChfarOffer);
      }
    }

    yield put(actions.setFetchAncillaryOfferResponse(responseSuccess));
  } catch (e) {
    yield put(actions.setFetchAncillaryOfferCallStateFailed());
    Logger.debug(e);
  }
}

async function fetchAncillaryChfarOffer(
  params: GetAirChfarOfferRequestV1
): Promise<AncillaryChfarOffer | undefined> {
  try {
    const response = await getAirChfarOffer(params);
    if (response?.offer == undefined) {
      const trackingProperties = response.trackingProperties;
      actions.setSkipChfarTrackingProperties(trackingProperties);
      trackEvent({
        eventName: ChfarAirOrchestratorEvent.SKIP_CHFAR,
        properties: trackingProperties.properties,
        encryptedProperties: trackingProperties.encryptedProperties
          ? [trackingProperties.encryptedProperties]
          : undefined,
      });
      return coundNotFetchAncillaryOffer();
    }

    return {
      ...response.offer,
      trackingProperties: response.trackingProperties,
      AncillaryOffer: AncillaryOfferEnum.ChfarOffer,
    };
  } catch {
    return coundNotFetchAncillaryOffer();
  }

  function coundNotFetchAncillaryOffer() {
    console.warn("Could not fetch CHFAR offer");
    return undefined;
  }
}
