import React, { useEffect, useState, useMemo } from "react";
import { Box, Button, Dialog, Typography } from "@material-ui/core";
import clsx from "clsx";

import "./styles.scss";
import { DesktopPricePredictionConnectorProps } from "./container";
import {
  PriceForecast,
  PricePredictionRecommendation,
  PricePredictionCard,
  Tag,
  PredictionForecast,
  PriceWatchCard,
  Icon,
  IconName,
  PriceDropProtection,
  PriceDropProtectionImage,
  B2BSpinner,
  getTotalPriceText,
  ButtonWrap,
  NotificationBanner,
  BannerSeverity,
} from "halifax";
import {
  Dealness,
  Prediction,
  PriceDropProtectionEnum,
  VIEWED_FORECAST,
  PRICE_DROP_VIEWED,
  VIEWED_PRICE_DROP_DETAILS,
  FiatPrice,
  RecommendationV2Enum,
} from "redmond";
import {
  PRICE_FORECAST_TITLES,
  PRICE_PREDICTION_CURRENT_PRICE_TITLE,
  VIEW_PRICE_PREDICTION,
  getPricePredictionCurrentPriceSubtitle,
  RESET_FILTERS,
  CHANGE_DATES,
  NO_DATA_ERROR_SUBTITLE,
  NO_DATA_ERROR_TITLE,
  PREDICTION_UNAVAILABLE,
  ADJUST_OR_RESET_SUBTITLE,
  WAIT,
  BOOK_NOW,
  UNSUPPORTED_FILTERS,
  WATCH_THIS_TRIP,
  WatchState,
  getPriceWatchCopy,
  WATCHING,
  FORECASTED_HIGHEST,
  FORECASTED_LOWEST,
  getPriceRecommendationTitle,
  getPriceRecommendationSubtitle,
  UNSUPPORTED_FILTER_COPY,
  getLowestPriceInFiat,
  getLowestPriceInRewards,
  PDP_TITLE,
  READ_TERMS_CONDITION_TEXT,
  PDP_HEADER,
  getSubtitle,
  getMaxRefund,
  PRICE_PREDICTION_HEADER,
  PRICE_PREDICTION_SUBTITLE,
  getPriceFreezeTitles,
  OR_SEPARATOR,
  NOT_READY_TO_BOOK,
  WAITING_TO_BOOK,
} from "../../../../constants";
import { RouteComponentProps } from "react-router";
import { trackEvent } from "../../../../../../api/v0/analytics/trackEvent";
import {
  PATH_PRICE_DROP_PROTECTION,
  PATH_PRICE_PREDICTION_PRICE_WATCH,
} from "../../../../../../utils/urlPaths";
import { PriceFreezeEntry } from "../../../priceFreezeComponents/PriceFreezeEntry";
import {
  AVAILABLE,
  PRICE_FREEZE,
  getExperimentVariant,
  useExperiments,
  PRICE_PREDICTION_GRADIENT_V2,
  AirCXV3VariantType,
  AIR_CX_V3_1_VARIANT_1,
  PRICE_FREEZE_PREDICTION_COPY,
} from "../../../../../../context/experiments";
import * as constants from "./constants";
import {
  getForecastedPriceDisplayText,
  isForecastedPriceSensible,
  getPriceFreezeRewardsString,
} from "../../../../utils/helpers";
import {
  IPriceFreezeEntryButtonOwnProps,
  IPriceFreezeFlightDetailsModalOwnPropsForButton,
} from "../../../priceFreezeComponents/PriceFreezeEntryButton";
import {
  IPriceFreezeEntryInfoOwnProps,
  IPriceFreezeExplanationModalOwnPropsForInfo,
  IPriceFreezeFlightDetailsModalOwnPropsForInfo,
} from "../../../priceFreezeComponents/PriceFreezeEntryInfo";
import {
  getPriceDropRefundTypeSelector,
  isPriceDropCreditEnabledSelector,
} from "../../../../../ancillary/reducer";
import { PriceWatchOptInCard } from "../../../PriceWatchOptInCard";
import { useSelector } from "react-redux";
import { FlightShopStep } from "../../../../reducer";
import { IPriceFreezeDurationRadioGroupOwnProps } from "../../../priceFreezeComponents/PriceFreezeDurationRadioGroup";

export interface IDesktopPricePredictionProps
  extends DesktopPricePredictionConnectorProps,
    RouteComponentProps {
  airCXV3Variant?: AirCXV3VariantType;
}

export const DesktopPricePrediction = (props: IDesktopPricePredictionProps) => {
  const {
    prediction,
    tripCategory,
    hasUnsupportedPredictionFilters,
    resetFilters,
    isWatching,
    deleteWatch,
    predictionLoading,
    selectedRewardsAccountId,
    viewedForecastProperties,
    priceDropViewedProperties,
    setOpenFlightShopCalendarDesktop,
    hidden,
    priceFreezeOffer,
    priceFreezeFiat,
    priceFreezeRewards,
    priceFreezeCap,
    tripDetails,
    fareId,
    priceFreezeDuration,
    viewedPriceFreezeProperties,
    airports,
    cheapestFrozenPrice,
    isPriceFreezeOfferIncludedInShopSummary,
    isTripDetailsLoading,
    highlightedPriceFreezeBox,
    highlightedPriceFreezeButton,
    showPriceFreezeIcon,
    highlightedRewards,
    useLockPriceLanguage,
    pricePredictionGradientVariant,
    hasActiveFilters,
    airCXV3Variant,
    canRedeemRewards,
    isCheapestPriceFreezeOffer,
  } = props;
  const [watchModalOpen, setWatchModalOpen] = useState(false);
  const [priceDropPredictionModalOpen, setPriceDropPredictionModalOpen] =
    useState(false);
  const [pricePredictionModalOpen, setPricePredictionModalOpen] =
    useState(false);
  const [showForecast, setShowForecast] = useState(false);
  const [justWatched, setJustWatched] = useState(false);
  const [hasViewedForecast, setHasViewedForecast] = useState(false);
  const getWatchState = () => {
    if (isWatching) {
      return justWatched ? WatchState.JustWatched : WatchState.Watched;
    } else {
      return WatchState.NotWatching;
    }
  };

  const expState = useExperiments();

  const priceFreezeGroup = getExperimentVariant(
    expState.experiments,
    PRICE_FREEZE
  );
  const isPriceFreeze = useMemo(() => {
    return priceFreezeGroup === AVAILABLE;
  }, [priceFreezeGroup]);

  const isPriceDropCreditEnabled = useSelector(
    isPriceDropCreditEnabledSelector
  );
  const priceDropRefundType = useSelector(getPriceDropRefundTypeSelector);

  const getForecastedPrice = (prediction: Prediction) => {
    const displayText = getForecastedPriceDisplayText(prediction);
    if (displayText) {
      return (
        <Typography className="content" variant="body1">
          {displayText}
          {/* TODO: display RewardText once the backend is ready */}
        </Typography>
      );
    }

    return "";
  };

  const isPDPEligible =
    prediction?.priceDropProtection?.PriceDropProtection ===
    PriceDropProtectionEnum.IsEligible;

  const onResetFilters = () => {
    resetFilters();
  };
  const onChangeDates = () => {
    setOpenFlightShopCalendarDesktop(true);
  };

  useEffect(() => {
    if (prediction && isPDPEligible) {
      trackEvent({
        eventName: PRICE_DROP_VIEWED,
        properties: {
          ...priceDropViewedProperties,
          page: "flight_list",
        },
      });
    }
  }, [prediction, isPDPEligible, priceDropViewedProperties]);

  useEffect(() => {
    if (prediction && viewedForecastProperties && !hasViewedForecast) {
      if (isPriceFreezeOfferIncludedInShopSummary) {
        // note: isTripDetailsLoading is of type boolean | null
        if (isTripDetailsLoading === false) {
          trackEvent({
            eventName: VIEWED_FORECAST,
            properties: {
              ...viewedForecastProperties,
              ...(isPriceFreeze ? viewedPriceFreezeProperties : undefined),
            },
          });
          setHasViewedForecast(true);
        }
      } else {
        trackEvent({
          eventName: VIEWED_FORECAST,
          properties: {
            ...viewedForecastProperties,
          },
        });
        setHasViewedForecast(true);
      }
    }
  }, [
    prediction,
    viewedForecastProperties,
    viewedPriceFreezeProperties,
    hasViewedForecast,
    isPriceFreezeOfferIncludedInShopSummary,
    isTripDetailsLoading,
  ]);

  const priceFreezeFlightDetailsModalPropsForButton: IPriceFreezeFlightDetailsModalOwnPropsForButton =
    {
      priceFreezeOffer: priceFreezeOffer,
      frozenPrice: cheapestFrozenPrice,
      duration: priceFreezeDuration,
      tripDetails: tripDetails,
      fareId: fareId,
      airports: airports,
      useLockPriceLanguage: useLockPriceLanguage,
    };

  const priceFreezeFlightDetailsModalPropsForInfo: IPriceFreezeFlightDetailsModalOwnPropsForInfo =
    priceFreezeFlightDetailsModalPropsForButton;

  const PriceFreezeExplanationModalPropsForInfo: IPriceFreezeExplanationModalOwnPropsForInfo =
    {
      showPriceFreezeFlightDetails: true,
      priceFreezeTitles: getPriceFreezeTitles({
        priceFreezeFiat,
        priceFreezeRewards,
        selectedRewardsAccountId,
        priceFreezeCap,
        duration: priceFreezeDuration,
        useLockPriceLanguage,
      }),
      useLockPriceLanguage: useLockPriceLanguage,
    };

  const priceFreezeEntryInfoProps: IPriceFreezeEntryInfoOwnProps = {
    useLowestPriceText: true,
    fiatPrice: getTotalPriceText({
      price: priceFreezeFiat as FiatPrice,
    }),
    rewards: getPriceFreezeRewardsString(
      priceFreezeRewards,
      selectedRewardsAccountId
    ),
    duration: priceFreezeDuration,
    showFreezeIcon: showPriceFreezeIcon,
    highlightedRewards: highlightedRewards,
    useLockPriceLanguage: useLockPriceLanguage,
    priceFreezeFlightDetailsModalProps:
      priceFreezeFlightDetailsModalPropsForInfo,
    priceFreezeExplanationModalProps: PriceFreezeExplanationModalPropsForInfo,
    isFromFlightShopReviewItinerary: false,
    prediction: prediction,
  };

  const priceFreezeEntryButtonProps: IPriceFreezeEntryButtonOwnProps = {
    showPriceFreezeDetails: true,
    tripDetails: tripDetails,
    fareId: fareId,
    highlightedButton: highlightedPriceFreezeButton,
    useLockPriceLanguage: useLockPriceLanguage,
    priceFreezeFlightDetailsModalProps:
      priceFreezeFlightDetailsModalPropsForButton,
    flightShopStep: FlightShopStep.PricePrediction,
    setCheapestEligibleOfferData: true,
  };

  const priceFreezeDurationRadioProps: IPriceFreezeDurationRadioGroupOwnProps =
    {
      isFromFlightsReviewItineraryScreen: false,
    };

  const priceFreezePredictionCopy = getExperimentVariant(
    expState.experiments,
    PRICE_FREEZE_PREDICTION_COPY
  );
  const isPriceFreezePredictionCopyXpEnabled = useMemo(
    () => priceFreezePredictionCopy === AVAILABLE,
    [priceFreezePredictionCopy]
  );

  const shouldRunWaitPredictionCopyXp =
    isPriceFreezePredictionCopyXpEnabled &&
    !!prediction &&
    prediction.dealness === Dealness.Wait &&
    isCheapestPriceFreezeOffer;

  return (
    <Box className={clsx("desktop-price-prediction-root", { hidden: hidden })}>
      {predictionLoading ? (
        <Box className="prediction-loading">
          <B2BSpinner />
        </Box>
      ) : (
        <>
          {prediction && prediction.predictionCopy ? (
            <>
              <Dialog
                role="tooltip"
                id="desktop-price-prediction-popover"
                open={pricePredictionModalOpen}
                className="price-prediction-popup"
                /** TransitionProps fixes `role` issue bug in MUIv4 - https://github.com/mui/material-ui/issues/18935  */
                TransitionProps={{ role: "none" } as never}
                PaperProps={{
                  /* eslint-disable */
                  // @ts-ignore: Fix the type definition of PaperProps to include tabIndex.
                  tabIndex: 0,
                  /* eslint-enable */
                }}
                onClose={() => setPricePredictionModalOpen(false)}
              >
                <Box className="price-prediction-wrapper">
                  <PriceDropProtection
                    className="price-prediction-card"
                    image={PriceDropProtectionImage}
                    title={PDP_TITLE}
                    onClick={() =>
                      window.open(
                        `${PATH_PRICE_PREDICTION_PRICE_WATCH} `,
                        "_blank"
                      )
                    }
                    onClose={() => setPricePredictionModalOpen(false)}
                    subtitle={PRICE_PREDICTION_SUBTITLE}
                    header={PRICE_PREDICTION_HEADER}
                    ctaText={READ_TERMS_CONDITION_TEXT}
                    icon={
                      <Icon
                        className="icon"
                        name={IconName.PricePredictionIcon}
                      />
                    }
                  />
                </Box>
              </Dialog>
              {hasUnsupportedPredictionFilters && (
                <Typography
                  variant="caption"
                  className="unsupported-filters-banner"
                >
                  {UNSUPPORTED_FILTERS}
                </Typography>
              )}
              <Box className="prediction-watch-container">
                {!hasUnsupportedPredictionFilters ? (
                  <Box className="price-prediction-container">
                    {airCXV3Variant === AIR_CX_V3_1_VARIANT_1 &&
                      hasActiveFilters && (
                        <NotificationBanner
                          label={
                            constants.PRICE_PREDICTION_APPLIED_FILTERS_NOTICE_TEXT
                          }
                          severity={BannerSeverity.WARNING}
                          icon={<Icon name={IconName.MagnifyingGlass} />}
                          className="price-prediction-applied-filters-notice-banner"
                        />
                      )}
                    <Box className="prediction-info">
                      <PricePredictionRecommendation
                        className="price-recommendation"
                        hideButton={true}
                        title={getPriceRecommendationTitle(
                          getWatchState(),
                          prediction.predictionCopy
                        )}
                        subtitle={getPriceRecommendationSubtitle(
                          getWatchState(),
                          prediction.predictionCopy,
                          prediction.dealness,
                          false,
                          prediction.recommendationV2
                        )}
                        pdpButtonProps={{
                          displayPDP:
                            prediction.recommendationV2 !==
                            RecommendationV2Enum.BuyNeutral
                              ? isPDPEligible
                              : false,
                          onPDPModalClick: () => {
                            if (isPDPEligible) {
                              trackEvent({
                                eventName: VIEWED_PRICE_DROP_DETAILS,
                                properties: {
                                  page: "flight_list",
                                  refund_type: priceDropRefundType,
                                },
                              });
                              setPriceDropPredictionModalOpen(true);
                            }
                          },
                          priceDiff: getMaxRefund(prediction) || undefined,
                          pdpButtonAriaLabelledby:
                            "desktop-price-prediction-price-drop-protection-popup",
                          pdpButtonAriaLabel:
                            constants.VIEW_PRICE_DROP_ARIA_LABEL,
                        }}
                        onPricePredictionModalClick={
                          prediction.recommendationV2 !==
                          RecommendationV2Enum.BuyNeutral
                            ? () => {
                                trackEvent({
                                  eventName: VIEWED_PRICE_DROP_DETAILS,
                                  properties: {
                                    page: "flight_list",
                                    refund_type: priceDropRefundType,
                                  },
                                });
                                setPricePredictionModalOpen(true);
                              }
                            : undefined
                        }
                        infoIconAriaLabelledby="desktop-price-prediction-popover"
                        infoIconAriaLabel={
                          constants.VIEW_PRICE_PREDICTION_ARIA_LABEL
                        }
                        isPriceDropCreditEnabled={isPriceDropCreditEnabled}
                      />
                      <Dialog
                        role="tooltip"
                        id="desktop-price-prediction-price-drop-protection-popup"
                        open={priceDropPredictionModalOpen}
                        className="price-drop-protection-popup"
                        /** TransitionProps fixes `role` issue bug in MUIv4 - https://github.com/mui/material-ui/issues/18935  */
                        TransitionProps={{ role: "none" } as never}
                        PaperProps={{
                          /* eslint-disable */
                          // @ts-ignore: Fix the type definition of PaperProps to include tabIndex.
                          tabIndex: 0,
                          /* eslint-enable */
                        }}
                        onClose={() => setPriceDropPredictionModalOpen(false)}
                      >
                        <Box className="price-drop-protection-wrapper">
                          <PriceDropProtection
                            className="price-drop-protection-card"
                            image={PriceDropProtectionImage}
                            title={PDP_TITLE}
                            onClick={() =>
                              window.open(
                                `${PATH_PRICE_DROP_PROTECTION} `,
                                "_blank"
                              )
                            }
                            onClose={() =>
                              setPriceDropPredictionModalOpen(false)
                            }
                            subtitle={getSubtitle(
                              prediction,
                              isPriceDropCreditEnabled
                            )}
                            header={PDP_HEADER}
                            ctaText={READ_TERMS_CONDITION_TEXT}
                          />
                        </Box>
                      </Dialog>
                      <PricePredictionCard
                        className="current-price"
                        title={PRICE_PREDICTION_CURRENT_PRICE_TITLE}
                        subtitle={getPricePredictionCurrentPriceSubtitle(
                          tripCategory
                        )}
                        content={
                          prediction.lowestPrice ? (
                            <Typography className="content">
                              <b>
                                {getLowestPriceInFiat(prediction.lowestPrice)}
                              </b>
                              {canRedeemRewards &&
                                getLowestPriceInRewards(
                                  prediction.lowestPrice,
                                  selectedRewardsAccountId,
                                  OR_SEPARATOR
                                )}
                            </Typography>
                          ) : (
                            ""
                          )
                        }
                        icon={
                          prediction.recommendationV2 !==
                          RecommendationV2Enum.BuyNeutral ? (
                            <Tag
                              className={prediction.dealness}
                              label={
                                prediction.dealness === Dealness.Wait
                                  ? WAIT
                                  : BOOK_NOW
                              }
                            />
                          ) : undefined
                        }
                        hasRewards={!!selectedRewardsAccountId}
                      />
                    </Box>
                    <PriceForecast
                      className="price-forecast"
                      items={PRICE_FORECAST_TITLES}
                      dealness={prediction.dealness}
                      expandedGreen={
                        pricePredictionGradientVariant ===
                        PRICE_PREDICTION_GRADIENT_V2
                      }
                      redOnLeftForWait={
                        pricePredictionGradientVariant ===
                        PRICE_PREDICTION_GRADIENT_V2
                      }
                      hideNonDealnessLabels={
                        pricePredictionGradientVariant ===
                        PRICE_PREDICTION_GRADIENT_V2
                      }
                    />
                    {!hasUnsupportedPredictionFilters &&
                      prediction.recommendationV2 !==
                        RecommendationV2Enum.BuyNeutral && (
                        <ButtonWrap
                          className="view-price-forecast-button"
                          tabIndex={0}
                          aria-label={
                            constants.VIEW_PRICE_PREDICTION_ARIA_LABEL
                          }
                          aria-expanded={showForecast}
                          onClick={() => setShowForecast(!showForecast)}
                        >
                          <Typography
                            className="view-price-forecast-copy"
                            variant="button"
                          >
                            {VIEW_PRICE_PREDICTION}

                            <Icon
                              name={IconName.Dropdown}
                              className={
                                showForecast
                                  ? "transformed-dropdown-icon"
                                  : "dropdown-icon"
                              }
                            />
                          </Typography>
                        </ButtonWrap>
                      )}
                  </Box>
                ) : (
                  <Box className="price-prediction-error-container">
                    <Typography variant="h6" className="prediction-error-title">
                      {PREDICTION_UNAVAILABLE}
                      <ButtonWrap
                        className="prediction-modal-icon"
                        aria-labelledby="desktop-price-prediction-popover"
                        onClick={() => setPricePredictionModalOpen(true)}
                      >
                        <Icon
                          name={IconName.InfoCircle}
                          ariaLabel={constants.VIEW_PRICE_PREDICTION_ARIA_LABEL}
                        />
                      </ButtonWrap>
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className="prediction-error-subtitle"
                    >
                      {ADJUST_OR_RESET_SUBTITLE}
                    </Typography>
                    <Button
                      onClick={onResetFilters}
                      className={"change-dates-button"}
                    >
                      {RESET_FILTERS}
                    </Button>
                  </Box>
                )}
                <Box
                  className={clsx("price-watch-container", {
                    "highlighted-price-freeze-box": highlightedPriceFreezeBox,
                  })}
                >
                  {shouldRunWaitPredictionCopyXp ? (
                    <Typography variant="h4" className="not-ready-book-header">
                      {WAITING_TO_BOOK}
                    </Typography>
                  ) : (
                    <Typography variant="h4" className="not-ready-book-header">
                      {NOT_READY_TO_BOOK}
                    </Typography>
                  )}
                  {isPriceFreeze && !!priceFreezeOffer && (
                    <PriceFreezeEntry
                      priceFreezeEntryButtonProps={priceFreezeEntryButtonProps}
                      priceFreezeEntryInfoProps={priceFreezeEntryInfoProps}
                      priceFreezeDurationRadioGroupProps={
                        priceFreezeDurationRadioProps
                      }
                    />
                  )}
                  <PriceWatchCard
                    title={getPriceWatchCopy(
                      getWatchState(),
                      hasUnsupportedPredictionFilters,
                      prediction.predictionCopy
                    )}
                    buttonCopy={isWatching ? WATCHING : WATCH_THIS_TRIP}
                    unsupportedFilterCopy={
                      hasUnsupportedPredictionFilters
                        ? UNSUPPORTED_FILTER_COPY
                        : ""
                    }
                    onClick={() =>
                      isWatching ? deleteWatch() : setWatchModalOpen(true)
                    }
                    isWatching={isWatching}
                  />
                </Box>
              </Box>
              <Dialog
                open={watchModalOpen}
                className="flight-watch-opt-in-popup"
                /** TransitionProps fixes `role` issue bug in MUIv4 - https://github.com/mui/material-ui/issues/18935  */
                TransitionProps={{ role: "none" } as never}
                PaperProps={{
                  /* eslint-disable */
                  // @ts-ignore: Fix the type definition of PaperProps to include tabIndex.
                  tabIndex: 0,
                  /* eslint-enable */
                }}
                onClose={() => setWatchModalOpen(false)}
              >
                <Box className="price-watch-opt-in-wrapper">
                  <PriceWatchOptInCard
                    isMobile={false}
                    setJustWatched={setJustWatched}
                    setWatchModalOpen={setWatchModalOpen}
                  />
                </Box>
              </Dialog>
              {showForecast && !hasUnsupportedPredictionFilters && (
                <Box className="prediction-forecast-container">
                  {getForecastedPrice(prediction) &&
                    isForecastedPriceSensible(prediction) && (
                      <PricePredictionCard
                        className="forecasted-price"
                        title={
                          prediction.dealness === Dealness.Wait
                            ? FORECASTED_LOWEST
                            : FORECASTED_HIGHEST
                        }
                        icon={
                          <Icon
                            className={
                              prediction.dealness === Dealness.Wait
                                ? "forecasted-lowest-icon"
                                : "forecasted-highest-icon"
                            }
                            name={
                              prediction.dealness === Dealness.Wait
                                ? IconName.DecreaseReviewed
                                : IconName.IncreasedReviewed
                            }
                          />
                        }
                        content={getForecastedPrice(prediction)}
                        subtitle={getPricePredictionCurrentPriceSubtitle(
                          tripCategory
                        )}
                        isForecastedPrice
                      />
                    )}
                  <PredictionForecast
                    intervals={prediction.predictionCopy.intervals}
                  ></PredictionForecast>
                </Box>
              )}
            </>
          ) : (
            <Box className="prediction-no-data">
              <Typography variant="h6" className="prediction-no-data-title">
                {NO_DATA_ERROR_TITLE}
                <ButtonWrap
                  className="prediction-modal-icon"
                  aria-labelledby="desktop-price-prediction-popover"
                  onClick={() => setPricePredictionModalOpen(true)}
                >
                  <Icon
                    name={IconName.InfoCircle}
                    ariaLabel={constants.VIEW_PRICE_PREDICTION_ARIA_LABEL}
                  />
                </ButtonWrap>
              </Typography>
              <Typography
                variant="subtitle2"
                className="prediction-no-data-subtitle"
              >
                {NO_DATA_ERROR_SUBTITLE}
              </Typography>
              <Button onClick={onChangeDates} className={"change-dates-button"}>
                {CHANGE_DATES}
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
