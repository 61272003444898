import React, { useEffect, useMemo, useState } from "react";
import { Badge, Box, Typography } from "@material-ui/core";
import {
  ActionLink,
  useDeviceTypes,
  Icon,
  IconName,
  removeTimezone,
  Header,
  BackButton,
  DatePickerButton,
  MobileSearchFieldButton,
} from "halifax";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RouteComponentProps } from "react-router";
import { FlightShopType, TripCategory } from "redmond";
import dayjs from "dayjs";
import clsx from "clsx";

import "./styles.scss";
import { FlightShopProgressHeaderConnectorProps } from "./container";
import { PATH_HOME, PATH_HOME_SEARCH } from "../../../../utils/urlPaths";
import { FlightShopStep, MulticityFlightShopStep } from "../../reducer";
import {
  ONE_WAY_TEXT,
  ROUND_TRIP_TEXT,
  FARE_DETAILS,
  TICKET_OPTION,
  DISRUPTION_PROTECTION_TITLE_NEW,
  DISRUPTION_PROTECTION_TITLE_OLD,
  DISRUPTION_PROTECTION_SUBTITLE,
  CHANGE_DATES,
  CONTINUE,
  REVIEW_DETAILS,
} from "./textConstants";
import { pushToDisruptionOverview } from "../../../ancillary/utils";
import {
  AIR_CX_V3_1,
  AIR_CX_V3_1_VARIANTS,
  AIR_CX_V4,
  AVAILABLE,
  CONTROL,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
  MOBILE_PREDICTION,
  useExperiments,
} from "../../../../context/experiments";
import { MobileEditLocationModal } from "../MobileEditLocationModal";
import { MobileCalendarPickerModal } from "../../../search/components/MobileFlightSearchControlV2/components";
import { FlightShopFilter } from "../FlightShopFilter";
import {
  filtersWithoutMaxPrice,
  sortOptionsWithoutPrice,
} from "../../../../utils/sortAndFilter";
import { TravelWalletDrawer } from "../../../travel-wallet/components";

interface IFlightShopProgressHeaderProps
  extends FlightShopProgressHeaderConnectorProps,
    RouteComponentProps {
  onFiltersClick: () => void;
  // note: this prop is used to overwrite the default go-back logic on the Customize step
  onGoBackCustomize?: () => void;
  className?: string;
  isEditHidden?: boolean;
  isFlightListOptimizationExperiment?: boolean;
  isMultiCityEnabled?: boolean;
  isEditMulticitySearchModalOpen?: boolean;
  setIsEditMulticitySearchModalOpen?: (arg: boolean) => void;
  allFiltersModalOpen: boolean;
  setAllFiltersModalOpen: (open: boolean) => void;
}

export const FlightShopProgressHeader = (
  props: IFlightShopProgressHeaderProps
) => {
  const {
    tripCategory,
    currentProgress,
    currentMulticityProgress,
    multicityRoutes,
    origin,
    destination,
    departureDate,
    returnDate,
    flightShopType,
    openFlightShopCalendarMobile,
    isInDisruptionProtectionRebook,
    rebookDepartureDateTime,
    setOpenFlightShopCalendarMobile,
    setFlightShopProgress,
    setMobileSearchProgress,
    onGoBackCustomize,
    onFiltersClick,
    className,
    isEditHidden,
    flightList,
    isFlightListOptimizationExperiment,
    isMultiCityEnabled,
    isRapidRebookRenameEnabled,
    isEditMulticitySearchModalOpen,
    setIsEditMulticitySearchModalOpen,
    allFiltersModalOpen,
    setAllFiltersModalOpen,
    walletItemCount,
    ...routerProps
  } = props;
  const { history } = routerProps;
  const isInPredictionStep = currentProgress === FlightShopStep.PricePrediction;
  const isInFareDetailsStep = currentProgress === FlightShopStep.FareDetails;
  const isInCustomizeStep = currentProgress === FlightShopStep.Customize;
  const isInReviewItinerary =
    currentProgress === FlightShopStep.ReviewItinerary &&
    currentMulticityProgress !== MulticityFlightShopStep.ReviewItinerary;
  const { matchesMobile } = useDeviceTypes();
  const [openEditLocationModal, setOpenEditLocationModal] = useState(false);
  const [calendarTripType, setCalendarTripType] = useState(tripCategory);
  const [travelWalletDrawerOpen, setTravelWalletDrawerOpen] =
    React.useState<boolean>(false);
  const { experiments } = useExperiments();
  const isInPredictionExperiment = useMemo(
    () => getExperimentVariant(experiments, MOBILE_PREDICTION) === AVAILABLE,
    [experiments]
  );

  const airCXV3Variant = useMemo(
    () =>
      getExperimentVariantCustomVariants(
        experiments,
        AIR_CX_V3_1,
        AIR_CX_V3_1_VARIANTS
      ),
    [experiments]
  );

  const isOneWay = useMemo(() => {
    return tripCategory === TripCategory.ONE_WAY;
  }, [tripCategory]);

  const isMulticity = useMemo(() => {
    return tripCategory === TripCategory.MULTI_CITY;
  }, [tripCategory]);

  const globalMobileNavExperimentVariant = getExperimentVariant(
    experiments,
    GLOBAL_MOBILE_NAV_EXPERIMENT
  );
  const isGlobalMobileNavExperiment = React.useMemo(
    () => globalMobileNavExperimentVariant === AVAILABLE,
    [globalMobileNavExperimentVariant]
  );

  const airCXV4ExperimentVariant = getExperimentVariant(experiments, AIR_CX_V4);
  const isAirCXV4Experiment = React.useMemo(
    () => airCXV4ExperimentVariant === AVAILABLE,
    [airCXV4ExperimentVariant]
  );

  useEffect(() => {
    setCalendarTripType(tripCategory);
  }, [tripCategory]);

  const closeLocationModal = () => {
    if (isEditMulticitySearchModalOpen && setIsEditMulticitySearchModalOpen) {
      setIsEditMulticitySearchModalOpen(false);
    }
    setOpenEditLocationModal(false);
  };

  const handleGoBack = () => {
    switch (currentProgress) {
      case FlightShopStep.ChooseDeparture: {
        if (isInDisruptionProtectionRebook) {
          pushToDisruptionOverview({ history });
        } else if (matchesMobile && isInPredictionExperiment) {
          history.push(PATH_HOME_SEARCH);
        } else {
          setFlightShopProgress(FlightShopStep.PricePrediction);
        }
        return;
      }
      case FlightShopStep.ChooseReturn: {
        setFlightShopProgress(FlightShopStep.ChooseDeparture);
        return;
      }

      case FlightShopStep.ReviewItinerary: {
        isOneWay
          ? setFlightShopProgress(FlightShopStep.ChooseDeparture)
          : setFlightShopProgress(FlightShopStep.ChooseReturn);
        return;
      }
      case FlightShopStep.FareDetails: {
        setFlightShopProgress(FlightShopStep.ReviewItinerary);
        return;
      }
      case FlightShopStep.Customize: {
        if (onGoBackCustomize) {
          onGoBackCustomize();
        } else {
          setFlightShopProgress(FlightShopStep.FareDetails);
        }
        return;
      }
      default: {
        history.push(matchesMobile ? PATH_HOME_SEARCH : PATH_HOME);
        return;
      }
    }
  };

  const handleEditCalendar = () => {
    if (matchesMobile) {
      setOpenFlightShopCalendarMobile(true);
    } else {
      history.push(PATH_HOME);
    }
  };

  const HeaderText = () => {
    const LocationsSection = () => {
      return (
        <Typography className="locations-section">
          {`${isOneWay ? ONE_WAY_TEXT : ROUND_TRIP_TEXT} ${
            origin?.id.code.code
          } to ${destination?.id.code.code}`}
        </Typography>
      );
    };

    switch (flightShopType) {
      case FlightShopType.DISRUPTION_PROTECTION_EXERCISE: {
        switch (currentProgress) {
          case FlightShopStep.ReviewItinerary:
            return (
              <Typography className="disruption-protection-header">
                {isRapidRebookRenameEnabled
                  ? DISRUPTION_PROTECTION_TITLE_NEW
                  : DISRUPTION_PROTECTION_TITLE_OLD}
              </Typography>
            );
          default:
            return <LocationsSection />;
        }
      }
      default: {
        switch (currentProgress) {
          case FlightShopStep.Customize:
            return (
              <Typography className="ticket-option-header" variant="body1">
                {TICKET_OPTION}
              </Typography>
            );
          case FlightShopStep.FareDetails:
            return (
              <Typography className="fare-details-header" variant="body1">
                {FARE_DETAILS}
              </Typography>
            );
          case FlightShopStep.ReviewItinerary:
            return (
              <Typography className="review-itinerary-header">
                {REVIEW_DETAILS}
              </Typography>
            );
          default:
            return <LocationsSection />;
        }
      }
    }
  };

  const Subtitle = () => {
    switch (flightShopType) {
      case FlightShopType.DISRUPTION_PROTECTION_EXERCISE: {
        switch (currentProgress) {
          case FlightShopStep.ReviewItinerary:
            return (
              <Box className="subtitle-section">
                <Typography className="subtitle">
                  {DISRUPTION_PROTECTION_SUBTITLE}
                </Typography>
              </Box>
            );
          default:
            return (
              <Box className="dates-section">
                {rebookDepartureDateTime && (
                  <Typography className="date">
                    {`${dayjs(removeTimezone(rebookDepartureDateTime)).format(
                      "MMM DD"
                    )}`}
                  </Typography>
                )}
              </Box>
            );
        }
      }
      default: {
        switch (currentProgress) {
          case FlightShopStep.FareDetails:
          case FlightShopStep.Customize:
            return null;
          case FlightShopStep.ReviewItinerary:
            return null;

          default:
            return (
              <Box className="dates-section">
                <Typography className="date">
                  {isOneWay &&
                    departureDate &&
                    `${dayjs(departureDate).format("MMM DD")}`}
                  {!isOneWay &&
                    departureDate &&
                    returnDate &&
                    `${dayjs(departureDate).format("MMM DD")} - ${dayjs(
                      returnDate
                    ).format("MMM DD")}`}
                </Typography>
                {isInPredictionStep && (
                  <ActionLink
                    className="edit-date-button"
                    onClick={handleEditCalendar}
                    content={<Icon name={IconName.B2BEditPencil} />}
                  />
                )}
              </Box>
            );
        }
      }
    }
  };

  const renderFilterExperimentHeader = () => {
    const getMulticityHeaderTitle = () => {
      switch (currentMulticityProgress) {
        case MulticityFlightShopStep.ReviewItinerary:
          return REVIEW_DETAILS;
        case MulticityFlightShopStep.FareDetails:
        case MulticityFlightShopStep.BookTrip:
          return FARE_DETAILS;
        case MulticityFlightShopStep.Customize:
          return TICKET_OPTION;
        case MulticityFlightShopStep.ChooseDeparture0:
        case MulticityFlightShopStep.ChooseDeparture1:
        case MulticityFlightShopStep.ChooseDeparture2:
        case MulticityFlightShopStep.ChooseDeparture3:
        case MulticityFlightShopStep.ChooseDeparture4:
          // Use currentMulticityProgress as index for ChooseDeparture0 - ChooseDeparture4
          // as multicityRoutes contains one route per element.
          const currentRoute = multicityRoutes[currentMulticityProgress];
          const originCode = currentRoute.origin?.id.code.code;
          const destinationCode = currentRoute.destination?.id.code.code;
          return `${originCode} - ${destinationCode}`;
        default:
          return "";
      }
    };

    const renderMiddleSection = () => {
      if (isMulticity) {
        return (
          <Box className="multicity-flight-shop-location-codes-and-departure-date">
            <Typography className="multicity-header-title">
              {getMulticityHeaderTitle()}
            </Typography>
            {[
              MulticityFlightShopStep.ChooseDeparture0,
              MulticityFlightShopStep.ChooseDeparture1,
              MulticityFlightShopStep.ChooseDeparture2,
              MulticityFlightShopStep.ChooseDeparture3,
              MulticityFlightShopStep.ChooseDeparture4,
            ].includes(currentMulticityProgress) && (
              <Typography className="multicity-header-subtitle">
                {dayjs(
                  multicityRoutes[currentMulticityProgress].departureDate
                ).format("MMM DD")}
              </Typography>
            )}
          </Box>
        );
      } else {
        return (
          <Box className="mobile-flight-shop-search-and-date">
            <MobileSearchFieldButton
              value={origin?.label}
              onClick={() => setOpenEditLocationModal(true)}
            />
            <DatePickerButton
              startDate={departureDate}
              endDate={returnDate}
              onClick={() => setOpenFlightShopCalendarMobile(true)}
              dateFormat={"MMM D"}
              variant="minimal"
              hideEndDate={!returnDate}
              hideSeparator={!returnDate}
            />
          </Box>
        );
      }
    };

    return (
      <Header
        className={clsx("mobile-flight-shop-search-header", {
          "global-mobile-nav": isGlobalMobileNavExperiment,
        })}
        left={
          <BackButton
            className="mobile-flight-shop-go-back"
            onClick={handleGoBack}
          />
        }
        center={renderMiddleSection()}
        right={
          <>
            {isMulticity && !isInReviewItinerary && (
              <ActionLink
                className="edit-multicity-search-button"
                onClick={() => setOpenEditLocationModal(true)}
                content={<Icon name={IconName.B2BEditPencil} />}
              />
            )}
            {isGlobalMobileNavExperiment &&
              !isInReviewItinerary &&
              !isMulticity && (
                <>
                  <ActionLink
                    className="mobile-flight-shop-wallet-entry"
                    onClick={() => setTravelWalletDrawerOpen(true)}
                    content={
                      <Badge
                        className="wallet-count"
                        badgeContent={walletItemCount ? "" : undefined}
                      >
                        <Icon name={IconName.TravelWalletIcon} />
                      </Badge>
                    }
                  />
                  <TravelWalletDrawer
                    drawerOpen={travelWalletDrawerOpen}
                    setDrawerOpen={setTravelWalletDrawerOpen}
                  />
                </>
              )}
            {!isInReviewItinerary && (
              <FlightShopFilter
                openAllFiltersModal={allFiltersModalOpen}
                setOpenAllFiltersModal={setAllFiltersModalOpen}
                isFlightListOptimizationExperiment={
                  isFlightListOptimizationExperiment
                }
                airCXV3Variant={airCXV3Variant}
                isMobile
                isAirCXV4Experiment={isAirCXV4Experiment}
              />
            )}
          </>
        }
      />
    );
  };

  return (
    <>
      <Box className={clsx("flight-shop-progress-header-root", className)}>
        {(isMulticity || isFlightListOptimizationExperiment) &&
        !(
          isInFareDetailsStep ||
          isInCustomizeStep ||
          isInDisruptionProtectionRebook ||
          isInReviewItinerary
        ) ? (
          renderFilterExperimentHeader()
        ) : (
          <Box className="flight-shop-progress-header-container">
            <Box className="go-back-button-section">
              <ActionLink
                className={clsx("go-back-button")}
                onClick={handleGoBack}
                content={<FontAwesomeIcon icon={faChevronLeft} />}
              />
            </Box>
            <Box className="header-and-subtitle-section">
              <HeaderText />
              <Subtitle />
            </Box>
            {!(
              isInFareDetailsStep ||
              isInCustomizeStep ||
              isInDisruptionProtectionRebook ||
              isInReviewItinerary
            ) && (
              <Box
                className={clsx("edit-location-and-date-button-section", {
                  hidden: isEditHidden,
                })}
              >
                {isInPredictionStep ? (
                  <ActionLink
                    className={clsx("prediction-filters-button")}
                    onClick={() => onFiltersClick()}
                    content={<Typography variant="body2">Filters</Typography>}
                  />
                ) : (
                  <ActionLink
                    className="edit-location-and-date-button"
                    onClick={handleEditCalendar}
                    content={<Icon name={IconName.B2BEditPencil} />}
                  />
                )}
              </Box>
            )}
            {isInDisruptionProtectionRebook && (
              <Box className="flight-shop-filter-section">
                <FlightShopFilter
                  openAllFiltersModal={allFiltersModalOpen}
                  setOpenAllFiltersModal={setAllFiltersModalOpen}
                  filtersToDisplay={filtersWithoutMaxPrice}
                  sortOptionsToDisplay={sortOptionsWithoutPrice}
                  isFlightListOptimizationExperiment={
                    isFlightListOptimizationExperiment
                  }
                  airCXV3Variant={airCXV3Variant}
                  isMobile
                  isAirCXV4Experiment={isAirCXV4Experiment}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
      {!(isInFareDetailsStep || isInCustomizeStep) &&
        openFlightShopCalendarMobile &&
        flightShopType === FlightShopType.DEFAULT && (
          <MobileCalendarPickerModal
            openCalendarModal={openFlightShopCalendarMobile}
            setOpenCalendarModal={setOpenFlightShopCalendarMobile}
            searchOnContinue
            headerText={CHANGE_DATES}
            fullScreen={isFlightListOptimizationExperiment}
            buttonText={CONTINUE}
            calendarTripType={calendarTripType}
            setCalendarTripType={setCalendarTripType}
          />
        )}
      {!(isInFareDetailsStep || isInCustomizeStep) &&
        (openEditLocationModal || isEditMulticitySearchModalOpen) &&
        flightShopType === FlightShopType.DEFAULT && (
          <MobileEditLocationModal
            openLocationModal={
              isEditMulticitySearchModalOpen || openEditLocationModal
            }
            setOpenLocationModal={closeLocationModal}
            setOpenCalendarModal={setOpenFlightShopCalendarMobile}
            setCalendarTripType={setCalendarTripType}
            isMultiCityEnabled={isMultiCityEnabled}
            isAirCXV3Experiment={airCXV3Variant !== CONTROL}
          />
        )}
    </>
  );
};
