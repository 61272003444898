import React from "react";
import { CurrencyFormatters } from "halifax";
import { TravelWalletOffer, TravelWalletCredit } from "redmond";

export const APPLY_TRAVEL_CREDIT_TITLE = "Apply travel credits";
export const TRAVEL_CREDIT_SUBTITLE =
  "Your entire travel credit balance will be applied to this booking.";

export const APPLY_TRAVEL_OFFER_TITLE = "Apply travel offer";
export const TRAVEL_OFFER_SUBTITLE =
  "Select the travel offer you’d like to apply to this booking.";

export const getSelectedOfferDescription = (offer: TravelWalletOffer) => {
  return offer.title;
};

export const getSelectedCreditDescription = (
  credit?: TravelWalletCredit,
  creditAmountToApply?: number
) => {
  if (credit) {
    return `${CurrencyFormatters.get(credit.amount.currency).format(
      Math.abs(creditAmountToApply || 0)
    )}`;
  }
  return "";
};

export const TRAVEL_CREDIT_TOTAL_PAYMENT_TEXT =
  "Your travel credits cover the total cost of your booking.";

export const TRAVEL_OFFER_TOTAL_PAYMENT_TEXT =
  "Your travel travel offer covers the total cost of your booking.";

export const TRAVEL_CREDIT_AND_OFFER_TOTAL_PAYMENT_TEXT =
  "Your travel credits and travel offer cover the total cost of your booking.";

export const VIEW_CREDIT_HISTORY_CTA = "View travel credit activity";

export const CREDIT_BALANCE_DESCRIPTION = (name: string) =>
  `${name}'s travel credits`;

export const CREDIT_HISTORY_BALANCE_TEXT = (
  currencySymbol: string,
  amount: string
) => {
  const [dollars, cents] = amount.split(".");
  return (
    <>
      <span className="balance-amount-currency-symbol">{currencySymbol}</span>
      {/* since the currency symbol is in it's own span for styling purposes, it is removed from the dollars string */}
      {dollars.replace(currencySymbol, "")}
      <span className="balance-amount-cents">{cents}</span>
    </>
  );
};

export const CREDIT_HISTORY_MODAL_SUBHEADER = "Available to spend on travel";
export const CREDIT_HISTORY_MODAL_LIST_HEADER = "Travel credit activity:";
