import dayjs from "dayjs";
import {
  PriceDropProtectionEnum,
  Prediction,
  IsEligible,
  PRICE_DROP_PROTECTION_MODAL_SUBTITLE,
  IResult,
} from "redmond";

export const BANNER_COPY = `Confirmed, your flight is booked!`;
export const MULTI_CITY_BANNER_COPY = `Confirmed, your multi-city flight is booked!`;
export const FEE_WAIVED_SUCCESS_TITLE = "Booking Fee Waived Successfully";
// TODO: once the backend is ready, add in the email variable
export const TITLE = (email: string) =>
  `An email confirmation will be sent to ${email}`;
export const SUBTITLE = (id: string) => `Your booking confirmation is #${id}`;
export const WHATS_NEXT_HEADER = "What's next?";
export const SEATS_TITLE = "Seat Selection";
export const SEATS_DESCRIPTION =
  "You booked seats ahead for your journey. A detailed confirmation will be sent to you by email.";
export const CFAR_TITLE = "Cancel for Any Reason";
export const CFAR_DESCRIPTION_ONE = `Cancel this trip online up until 3 hours before departure from the `;
export const DISRUPTION_PROTECTION_TITLE_OLD = "Rapid rebooking";
export const DISRUPTION_PROTECTION_TITLE_NEW = "Flight disruption assistance";
/** @see https://hopper-jira.atlassian.net/browse/COTA-1093 */
export const MISSED_CONNECTION_REBOOKING_TITLE = "Missed Connection Rebooking";
export const DISRUPTION_PROTECTION_DESCRIPTION =
  "Quickly rebook a delayed or canceled flight or get a refund.";
export const MISSED_CONNECTION_REBOOKING_DESCRIPTION =
  "Quickly rebook a missed or canceled flight";
export const CFAR_DESCRIPTION_MY_TRIPS_LINK = "My Trips";
export const CFAR_DESCRIPTION_MY_TRIPS_LINK_ARIA_LABEL = "Open my trips";
export const CFAR_DESCRIPTION_TWO = ` Page.`;
export const HOTELS_TITLE = "Browse Hotels";
export const HOTELS_DESCRIPTION =
  "View our selection of hotels and accomodations.";
export const CARS_TITLE = "Rent a Car";
export const CARS_DESCRIPTION = "Search now and get the best deals on wheels.";
export const ADD_ONS_TITLE = "You also purchased:";

export const getReviewCardHeaderText = (
  isDeparture: boolean,
  location: string,
  date: string,
  airlineCode?: string
) => ({
  bold: isDeparture ? "Outbound" : "Return",
  standard: `to ${location}${airlineCode ? ` (${airlineCode})` : ""} on ${dayjs(
    date
  ).format("ddd, MMM D")}`,
});

export const getReviewCardHeaderTextMultiCity = (
  sliceIndex: number,
  date: string,
  origin: string,
  destination: string,
  originCode?: string,
  destinationCode?: string
) => ({
  bold: `Flight ${sliceIndex + 1}: ${origin}${
    originCode ? ` (${originCode})` : ""
  } to ${destination}${destinationCode ? ` (${destinationCode})` : ""}`,
  standard: `on ${dayjs(date).format("ddd, MMM D")}`,
});

export const getStopsString = (stops: number) =>
  stops === 0 ? "Nonstop" : stops > 1 ? `${stops} stops` : "1 stop";

export const PDP_TITLE = "How it works";
export const PDP_CTA_TEXT = "Read terms and conditions";
export const PDP_HEADER =
  "Book now with <span class='font-regular'>free price drop protection</span>";

export const getMonitoringDaysCount = (prediction: Prediction) => {
  if (prediction) {
    const days =
      prediction &&
      prediction.priceDropProtection &&
      prediction.priceDropProtection.PriceDropProtection ===
        PriceDropProtectionEnum.IsEligible
        ? (prediction.priceDropProtection as IsEligible).monitoringDuration
            .inSeconds / 86400
        : 0;
    return days;
  } else {
    return 0;
  }
};

export const getMaxRefund = (prediction: Prediction) => {
  if (prediction) {
    const maxRefund =
      prediction.priceDropProtection &&
      prediction.priceDropProtection.PriceDropProtection ===
        PriceDropProtectionEnum.IsEligible &&
      prediction.priceDropProtection.maximumRefund.display;
    return maxRefund;
  } else {
    return "";
  }
};

export const getSubtitle = (
  prediction: Prediction,
  isPriceDropCreditEnabled: boolean
) => {
  const monitoringDays = getMonitoringDaysCount(prediction);
  const maxRefund = getMaxRefund(prediction);
  return PRICE_DROP_PROTECTION_MODAL_SUBTITLE(
    monitoringDays,
    maxRefund,
    isPriceDropCreditEnabled
  );
};

export const TREES_MODAL_TITLE = "How it works";
export const TREES_MODAL_SUBTITLE =
  "<b>Tree Planting Efforts:</b> Capital One, together with its partners, will donate to GlobalGiving’s fund for EarthLungs’ Reforestation Project enough to plant two (2) trees, $0.25, for every completed flight, hotel booking, car rental, or vacation rental booking made through Capital One Travel.  In the event that a booking is made, but later canceled and refunded to the customer, for any reason, such a payment will not be made. No portion of a customer’s Capital One Travel purchase is tax-deductible. <br>GlobalGiving is a 501(c)(3) nonprofit organization whose mission is to transform aid and philanthropy to accelerate community-led change. To learn more about GlobalGiving, visit <a href='www.globalgiving.org' target='_blank'>www.globalgiving.org</a>; to learn more about GlobalGiving’s nonprofit partner, EarthLungs Reforestation Project, visit <a href='http://www.earthlungsreforestation.org.' target='_blank'>www.earthlungsreforestation.org.";
export const TREES_MODAL_HEADER =
  "When you book, <span class='font-regular'>you plant trees.</span>";
export const TREES_MODAL_IMG_LOCATION = "Banff, Alberta";
export const TREES_MODAL_CTA_TEXT = "will be planted for this booking! ";
export const TREES_BOLDED_MODAL_CTA_TEXT = "2 trees ";

export const TRAVEL_SALES_EVENT_ACTIVE_SUBTITLE =
  "Save up to $200 on select hotels with our first-ever travel sale.";
export const TRAVEL_SALES_EVENT_ACTIVE_CTA = "Explore destinations on sale";

export const getBannerText = (
  location: IResult | null,
  fromDate: Date,
  untilDate: Date,
  isMobile?: boolean,
  bannerText?: string
) => {
  const [city, state] = location?.label ? location.label.split(",") : [];

  const locationString = `${city}${state ? `, ${state}` : ""}`;

  if (isMobile) {
    return `${bannerText} 
      from <strong>${dayjs(fromDate).format("MMM, D")} - ${dayjs(
      untilDate
    ).format("MMM, D")}`;
  }
  return `${bannerText} in <strong>${locationString}</strong> from <strong>${dayjs(
    fromDate
  ).format("MMM, D")}
  - ${dayjs(untilDate).format("MMM, D")}`;
};
