import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import {
  getTripCategory,
  getDepartureDate,
  getReturnDate,
} from "../../../search/reducer";
import {
  airportsSelector,
  isOutgoingMultiTicketSelector,
  selectedTripSelector,
  tripDetailsLoadingSelector,
  tripDetailsSelector,
  selectedFareDetailsSelector,
  perPaxRefundableFarePricesSelector,
  customizeCheckoutBreakdownTotalPricesSelector,
  predictionSelector,
  getViewedTripSummaryProperties,
  isReturnMultiTicketSelector,
  getPriceFreezeOfferFiat,
  getPriceFreezeOfferRewards,
  getPriceFreezeOfferCap,
  currentPriceFreezeOfferFlightShopSelector,
  getPriceFreezeOfferDuration,
  getViewedPriceFreezeProperties,
  getPriceDropProperties,
  cfarOfferSelector,
  currentCfarChangePolicySelector,
  fetchAncillaryOfferCallStateSelector,
  isAddOnOptionAvailableSelector,
  getOffersByTripId,
  getTravelOfferProperties,
  priceFreezeUserSelectedDurationPropertiesSelector,
  isInDisruptionProtectionRebookSelector,
  flightShopTypeSelector,
  isSelectedTripMarketedBySpecificAirlineSelector,
  hasActiveRefundableFareInFlightShopSelector,
  refundableFaresPropertiesSelector,
  getCorporateTravel,
  flightShopProductRedeemChoice,
} from "../../reducer/selectors";
import {
  isPriceFreezeDurationEnabledSelector,
  getPriceFreezeOfferDataCallStateSelector,
} from "../../../freeze/reducer";
import {
  isCfarEnabledSelector,
  isCustomizePageMarketplaceEnabledSelector,
  batchCfarOffersCallStateSelector,
} from "../../../ancillary/reducer";
import {
  getSelectedAccount,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
} from "../../../rewards/reducer";
import { FlightShopReviewItinerary } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { populateFlightBookQueryParams } from "../../../book/actions/actions";
import { batchFetchCfarOffers } from "../../../ancillary/actions/actions";
import {
  setFlightShopProgress,
  setMulticityFlightShopProgress,
  fetchAncillaryOffer,
  fetchCorpFintechEligibility,
} from "../../actions/actions";
import { NO_PRICE_FREEZE_AIRLINE_CODES } from "./constants";
import { getTravelWalletCredit } from "../../../travel-wallet/reducer";
import {
  isAirPriceFreezeNewReviewCTAEnabledSelector,
  isCfarMulticityEnabledSelector,
} from "../../reducer/selectors/experiments";
import { setPriceFreezeEntryPointStep } from "../../../freeze/actions/actions";
import { getPotentialCrossSellOffers } from "../../../cross-sell/reducer/selectors";
import { hasTravelFusionInSelectedTripDetailsSelector } from "../../../book/reducer";

const mapStateToProps = (state: IStoreState) => {
  const selectedTrip = selectedTripSelector(state);
  const tripId = selectedTrip.tripId || "";
  const tripDetails = tripDetailsSelector(state, tripId);

  const isTripMarketedByNoPriceFreezeAirline =
    NO_PRICE_FREEZE_AIRLINE_CODES.some((airlineCode) =>
      isSelectedTripMarketedBySpecificAirlineSelector(state, airlineCode)
    );

  return {
    productRedeemChoice: flightShopProductRedeemChoice(state),
    tripCategory: getTripCategory(state),
    departureDate: getDepartureDate(state),
    returnDate: getReturnDate(state),
    airports: airportsSelector(state, tripId),
    tripDetails,
    selectedTrip,
    fareDetails: selectedFareDetailsSelector(state),
    perPaxRefundableFarePrices: perPaxRefundableFarePricesSelector(state),
    perPaxPrices: customizeCheckoutBreakdownTotalPricesSelector(state),
    tripDetailsLoading: tripDetailsLoadingSelector(state),
    isMultiTicket:
      isOutgoingMultiTicketSelector(state) ||
      isReturnMultiTicketSelector(state),
    isTripMarketedByNoPriceFreezeAirline,
    rewardsKey: getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    prediction: predictionSelector(state),
    viewedTripSummaryProperties: getViewedTripSummaryProperties(state),
    priceDropViewedProperties: getPriceDropProperties(state),
    priceFreezeFiat: getPriceFreezeOfferFiat(state),
    priceFreezeRewards: getPriceFreezeOfferRewards(state),
    priceFreezeCap: getPriceFreezeOfferCap(state),
    currentPriceFreezeOffer: currentPriceFreezeOfferFlightShopSelector(state),
    priceFreezeDuration: getPriceFreezeOfferDuration(state),
    viewedPriceFreezeProperties: getViewedPriceFreezeProperties(state),
    cfarOffer: cfarOfferSelector(state),
    changePolicy: currentCfarChangePolicySelector(state),
    fetchAncillaryOfferCallState: fetchAncillaryOfferCallStateSelector(state),
    batchCfarOffersCallState: batchCfarOffersCallStateSelector(state),
    isAddOnOptionAvailable: isAddOnOptionAvailableSelector(state),
    offersByTripId: getOffersByTripId(state),
    travelOfferProperties: getTravelOfferProperties(state),
    isPriceFreezeDurationEnabled: isPriceFreezeDurationEnabledSelector(state),
    isCfarEnabled: isCfarEnabledSelector(state),
    isCustomizePageMarketplaceEnabled:
      isCustomizePageMarketplaceEnabledSelector(state),
    isInDisruptionProtectionRebook:
      isInDisruptionProtectionRebookSelector(state),
    getPriceFreezeOfferDataCallState:
      getPriceFreezeOfferDataCallStateSelector(state),
    priceFreezeUserSelectedDurationProperties:
      priceFreezeUserSelectedDurationPropertiesSelector(state),
    flightShopType: flightShopTypeSelector(state),
    hasActiveRefundableFare: hasActiveRefundableFareInFlightShopSelector(state),
    refundableFaresProperties: refundableFaresPropertiesSelector(state),
    credit: getTravelWalletCredit(state),
    isCfarMulticityEnabled: isCfarMulticityEnabledSelector(state),
    isAirPriceFreezeNewReviewCTAEnabled:
      isAirPriceFreezeNewReviewCTAEnabledSelector(state),
    corporateTravel: getCorporateTravel(state),
    potentialCrossSellOffers: getPotentialCrossSellOffers(state),
    selectedAccount: getSelectedAccount(state),
    hasTravelFusionFareBrand:
      hasTravelFusionInSelectedTripDetailsSelector(state),
  };
};

const mapDispatchToProps = {
  populateFlightBookQueryParams,
  setFlightShopProgress,
  setMulticityFlightShopProgress,
  fetchAncillaryOffer,
  batchFetchCfarOffers,
  setPriceFreezeEntryPointStep,
  fetchCorpFintechEligibility,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightShopReviewItineraryConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightShopReviewItinerary = connector(
  withRouter(FlightShopReviewItinerary)
);
