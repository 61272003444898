import {
  CallState,
  RebookSummarySuccess,
  SingleFlightItineraryResponse,
  GetExerciseEligibilitiesResponseV1,
  FetchCfarOfferResponseV2,
  AncillaryPostBookingState,
  AncillaryPostBookingKindEnum,
  AncillaryPostBookingPurchaseKindEnum,
} from "redmond";
import { actions, actionTypes } from "../actions";
import {
  DefaultVariantType,
  AncillaryMarketplaceVariantType,
  CfarDiscountVariantType,
  CFAR,
  REFUNDABLE_FARES,
  DISRUPTION,
  ANCILLARY_MARKETPLACE,
  PRICE_DROP_CREDIT,
  CfarSocialVariantType,
  CFAR_SOCIAL,
  CAP_ONE_DISRUPTION_OPT_IN,
  CapOneDisruptionOptInVariantType,
  NON_FDA_CAP_ONE_DISRUPTION_OPT_IN,
  CHFAR,
  RAPID_REBOOK_RENAME,
  CFAR_DISCOUNT,
  AIR_OFFER_REDESIGN,
  AirOfferRedesignVariantType,
  CFAR_ANCILLARY_COMERCH,
  CFARCoMerchVariantType,
  POST_BOOKING_OFFER_EXPERIMENT,
  AIR_REFUNDABLE_FARE_COPY,
  POST_BOOKING_OFFER_EXPERIMENT_REWARDS,
  PostBookingOfferVariantType,
  CFAR_ENABLE_F9_NK_AIRLINE,
  UppercaseDefaultVariantType,
  FINTECH_CSAT,
  PostBookingOfferRewardsVariantType,
  CFAR_OFFER_SHOW_REFUND_COPY_EXPERIMENT,
  CfarRefundCopyVariantType,
  ChfarVariantsType,
} from "../../../context/experiments";

export interface IFlightAncillaryExperiments {
  [CFAR]: DefaultVariantType;
  [CHFAR]: ChfarVariantsType;
  [CFAR_SOCIAL]: CfarSocialVariantType;
  [REFUNDABLE_FARES]: DefaultVariantType;
  [DISRUPTION]: DefaultVariantType;
  [POST_BOOKING_OFFER_EXPERIMENT]: PostBookingOfferVariantType;
  [POST_BOOKING_OFFER_EXPERIMENT_REWARDS]: PostBookingOfferRewardsVariantType;
  [ANCILLARY_MARKETPLACE]: AncillaryMarketplaceVariantType;
  [PRICE_DROP_CREDIT]: DefaultVariantType;
  [CAP_ONE_DISRUPTION_OPT_IN]: CapOneDisruptionOptInVariantType;
  [NON_FDA_CAP_ONE_DISRUPTION_OPT_IN]: DefaultVariantType;
  [RAPID_REBOOK_RENAME]: DefaultVariantType;
  [CFAR_DISCOUNT]: CfarDiscountVariantType;
  [AIR_OFFER_REDESIGN]: AirOfferRedesignVariantType;
  [CFAR_ANCILLARY_COMERCH]: CFARCoMerchVariantType;
  [AIR_REFUNDABLE_FARE_COPY]: DefaultVariantType;
  [CFAR_ENABLE_F9_NK_AIRLINE]: UppercaseDefaultVariantType;
  [FINTECH_CSAT]: DefaultVariantType;
  [CFAR_OFFER_SHOW_REFUND_COPY_EXPERIMENT]: CfarRefundCopyVariantType;
}

export interface IFlightAncillaryExperimentState {
  experiments: IFlightAncillaryExperiments | null;
  isAutoApprovalEnabled: boolean;
}

export enum DateToggleFilter {
  TODAY,
  TOMORROW,
}

export interface IFlightAncillaryState extends IFlightAncillaryExperimentState {
  originalItineraryId: string | null;
  rebookSummary: RebookSummarySuccess | null;
  rebookSummaryCallState: CallState;
  dateToggleFilter: DateToggleFilter;
  singleFlightItinerary: SingleFlightItineraryResponse | null;
  disruptedSliceIndex: number | null;
  disruptedSegmentIndex: number | null;
  fetchSingleFlightItineraryCallState: CallState;
  exerciseDisruptionProtectionRebookCallState: CallState;
  exerciseDisruptionProtectionRebookCallFailureReason: string | null;
  // note: given that the /rebookSummary endpoint already validates eligibility, flightDisruption is used for event tracking only
  flightDisruptions: GetExerciseEligibilitiesResponseV1;
  fetchFlightDisruptionsCallState: CallState;
  cfarOffers: {
    [tripId: string]: { [fareId: string]: FetchCfarOfferResponseV2 };
  };
  batchCfarOffersCallState: CallState;
  hasUpdatedCfarOffersForReturnFlights: boolean;
  postBookingOfferState: AncillaryPostBookingState;
}

export const initialState: IFlightAncillaryState = {
  originalItineraryId: null,
  rebookSummary: null,
  rebookSummaryCallState: CallState.NotCalled,
  dateToggleFilter: DateToggleFilter.TODAY,
  singleFlightItinerary: null,
  disruptedSliceIndex: null,
  disruptedSegmentIndex: null,
  fetchSingleFlightItineraryCallState: CallState.NotCalled,
  exerciseDisruptionProtectionRebookCallState: CallState.NotCalled,
  exerciseDisruptionProtectionRebookCallFailureReason: null,
  flightDisruptions: { eligibilityByItinerary: {} },
  fetchFlightDisruptionsCallState: CallState.NotCalled,
  cfarOffers: {},
  batchCfarOffersCallState: CallState.NotCalled,
  hasUpdatedCfarOffersForReturnFlights: false,
  experiments: null,
  postBookingOfferState: { state: AncillaryPostBookingKindEnum.NotCalled },
  isAutoApprovalEnabled: false,
};

export const getExperimentState = (
  flightAncillaryState: IFlightAncillaryState | undefined
): IFlightAncillaryExperimentState => {
  return {
    experiments: flightAncillaryState?.experiments ?? initialState.experiments,
    isAutoApprovalEnabled: flightAncillaryState?.isAutoApprovalEnabled || false,
  };
};

export function reducer(
  state: IFlightAncillaryState = initialState,
  action: actions.FlightAncillaryActions
): IFlightAncillaryState {
  switch (action.type) {
    case actionTypes.SET_ORIGINAL_FLIGHT_ITINERARY_ID:
      return {
        ...state,
        originalItineraryId: action.itineraryId,
      };

    case actionTypes.FETCH_REBOOK_SUMMARY:
      return {
        ...state,
        rebookSummary: null,
        rebookSummaryCallState: CallState.InProcess,
      };

    case actionTypes.SET_REBOOK_SUMMARY_CALL_STATE_FAILED:
      return {
        ...state,
        rebookSummaryCallState: CallState.Failed,
      };

    case actionTypes.SET_REBOOK_SUMMARY_RESPONSE:
      return {
        ...state,
        rebookSummary: action.rebookSummary,
        rebookSummaryCallState: CallState.Success,
      };

    case actionTypes.SET_TODAY_TOMORROW_DATE_FILTER:
      return {
        ...state,
        dateToggleFilter: action.dateFilter,
      };

    case actionTypes.FETCH_SINGLE_FLIGHT_ITINERARY:
      return {
        ...state,
        singleFlightItinerary: null,
        fetchSingleFlightItineraryCallState: CallState.InProcess,
      };

    case actionTypes.SET_SINGLE_FLIGHT_ITINERARY:
      return {
        ...state,
        singleFlightItinerary: action.response,
        fetchSingleFlightItineraryCallState: CallState.Success,
      };

    case actionTypes.SET_FETCH_SINGLE_FLIGHT_ITINERARY_CALL_STATE_FAILED:
      return {
        ...state,
        fetchSingleFlightItineraryCallState: CallState.Failed,
      };

    case actionTypes.SET_DISRUPTED_FLIGHT_ITINERARY_SLICE_AND_SEGMENT_INDEXES:
      return {
        ...state,
        disruptedSliceIndex: action.sliceIndex,
        disruptedSegmentIndex: action.segmentIndex,
      };

    case actionTypes.EXERCISE_DISRUPTION_PROTECTION_REBOOK:
      return {
        ...state,
        exerciseDisruptionProtectionRebookCallState: CallState.InProcess,
      };

    case actionTypes.SET_EXERCISE_DISRUPTION_PROTECTION_REBOOK_CALL_STATE_SUCCESS:
      return {
        ...state,
        exerciseDisruptionProtectionRebookCallState: CallState.Success,
      };

    case actionTypes.SET_EXERCISE_DISRUPTION_PROTECTION_REBOOK_CALL_STATE_FAILED:
      return {
        ...state,
        exerciseDisruptionProtectionRebookCallState: CallState.Failed,
        exerciseDisruptionProtectionRebookCallFailureReason:
          action.failureReason,
      };

    case actionTypes.SET_EXERCISE_DISRUPTION_PROTECTION_REBOOK_CALL_STATE_NOT_CALLED:
      return {
        ...state,
        exerciseDisruptionProtectionRebookCallState: CallState.NotCalled,
      };

    case actionTypes.FETCH_FLIGHT_DISRUPTION_BY_ITINERARY_ID:
      return {
        ...state,
        fetchFlightDisruptionsCallState: CallState.InProcess,
      };

    case actionTypes.SET_FLIGHT_DISRUPTIONS:
      return {
        ...state,
        flightDisruptions: action.flightDisruptions,
        fetchFlightDisruptionsCallState: CallState.Success,
      };

    case actionTypes.SET_FETCH_FLIGHT_DISRUPTIONS_CALL_STATE_FAILED:
      return {
        ...state,
        flightDisruptions: initialState.flightDisruptions,
        fetchFlightDisruptionsCallState: CallState.Failed,
      };

    case actionTypes.BATCH_FETCH_CFAR_OFFERS:
      return {
        ...state,
        batchCfarOffersCallState: CallState.InProcess,
        ...(action.variant === "return-flights"
          ? { hasUpdatedCfarOffersForReturnFlights: false }
          : {}),
      };

    case actionTypes.SET_CFAR_OFFERS:
      return {
        ...state,
        cfarOffers: action.cfarOffers,
        batchCfarOffersCallState: CallState.Success,
        ...(action.variant === "return-flights"
          ? { hasUpdatedCfarOffersForReturnFlights: true }
          : {}),
      };

    case actionTypes.RESET_CFAR_OFFERS:
      return {
        ...state,
        cfarOffers: initialState.cfarOffers,
        batchCfarOffersCallState: CallState.NotCalled,
        hasUpdatedCfarOffersForReturnFlights:
          initialState.hasUpdatedCfarOffersForReturnFlights,
      };

    case actionTypes.SET_BATCH_CFAR_OFFERS_CALL_STATE_FAILED:
      return {
        ...state,
        batchCfarOffersCallState: CallState.Failed,
        hasUpdatedCfarOffersForReturnFlights:
          initialState.hasUpdatedCfarOffersForReturnFlights,
      };

    case actionTypes.SET_ANCILLARY_EXPERIMENTS:
      return {
        ...state,
        experiments: action.experiments,
      };

    case actionTypes.FETCH_ANCILLARY_POST_BOOKING_OFFER: {
      return {
        ...state,
        postBookingOfferState: {
          state: AncillaryPostBookingKindEnum.FetchOffer,
        },
      };
    }

    case actionTypes.SET_FETCH_ANCILLARY_POST_BOOKING_OFFER_RESPONSE: {
      return {
        ...state,
        postBookingOfferState: {
          state: AncillaryPostBookingKindEnum.ValidOffer,
          offer: action.response,
          attemptState: {
            state: AncillaryPostBookingPurchaseKindEnum.NotCalled,
          },
        },
      };
    }

    case actionTypes.RESET_FETCH_ANCILLARY_POST_BOOKING_OFFER_STATE: {
      return {
        ...state,
        postBookingOfferState: {
          state: AncillaryPostBookingKindEnum.NotCalled,
        },
      };
    }

    case actionTypes.PURCHASE_ANCILLARY_POST_BOOKING_OFFER: {
      return {
        ...state,
        postBookingOfferState: {
          state: AncillaryPostBookingKindEnum.ValidOffer,
          offer: action.offer,
          attemptState: {
            request: action.request,
            state: AncillaryPostBookingPurchaseKindEnum.InProgress,
          },
        },
      };
    }

    case actionTypes.SET_PURCHASE_ANCILLARY_POST_BOOKING_OFFER_RESPONSE: {
      return {
        ...state,
        postBookingOfferState: {
          state: AncillaryPostBookingKindEnum.Confirmation,
          response: action.response,
        },
      };
    }

    case actionTypes.SET_PURCHASE_ANCILLARY_POST_BOOKING_OFFER_RESPONSE_CALL_STATE_FAILED: {
      return {
        ...state,
        postBookingOfferState: {
          state: AncillaryPostBookingKindEnum.ValidOffer,
          offer: action.offer,
          attemptState: {
            response: action.response,
            state: AncillaryPostBookingPurchaseKindEnum.Failed,
          },
        },
      };
    }

    case actionTypes.COMPLETE_POST_BOOKING_OFFER_SCREEN: {
      return {
        ...state,
        postBookingOfferState: {
          state: AncillaryPostBookingKindEnum.NoOffer,
        },
      };
    }

    case actionTypes.SET_AUTO_APPROVAL:
      return {
        ...state,
        isAutoApprovalEnabled: action.isAutoApprovalEnabled,
      };

    default:
      return state;
  }
}

export * from "./selectors";
