import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  useDeviceTypes,
  ExpandableCard,
  NoResults,
  B2BSpinner,
  LoadingIndicator,
  getTotalPriceText,
  IPriceFreezeOfferEntries,
  FareDetailsCardCtaTitles,
  FareDetailsCardCtaType,
  IFareCustomization,
  UserPreferencesNotAvailableBanner,
  HotelCrossSellAwarenessCard,
  MobilePopoverCard,
  BackButton,
  Header,
  ButtonWrap,
  ActionButton,
} from "halifax";
import { Box, Button, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import * as H from "history";
import clsx from "clsx";

import "./styles.scss";
import { FlightListInfo } from "./components/FlightListInfo";
import { FlightCardType } from "./components/FlightListInfo/component";
import * as constants from "./constants";
import {
  CHANGE_DATES,
  CHANGE_DATES_CTA_TEXT,
  CLEAR_FILTERS_CTA_TEXT,
  getPriceFreezeTitles,
  RESET_FILTERS,
} from "../../../constants";
import InfiniteScroll from "react-infinite-scroll-component";
import { FareclassOptionFilter } from "../../../../search/reducer";
import {
  AirportMap,
  AlgomerchTag,
  Cap,
  FareDetails,
  FiatPrice,
  mapAlgomerchTexts,
  RewardsPrice,
  TimeToLive,
  TripDetails,
  PriceFreezeEntryEnum,
  EntryButtonEnum,
  PreviousFlightEnum,
  FlightShopType,
  TravelWalletOffer,
  TravelWalletCredit,
  RewardsAccount,
  FetchCfarOfferResponseV2,
  Slice,
  FlightSortOption,
  VIEWED_MISSED_CONNECTION_GUARANTEE_MODAL,
  VIEWED_SELF_TRANSFER_MODAL,
  VirtualInterlineEntryPoint,
  VirtualInterlineModalProperties,
  AirEntryProperties,
  SliceStopCountFilter,
  POLICY_DESCRIPTOR,
  PolicyViolation,
  VIEWED_POLICY_DESCRIPTOR,
  UserFlightPreferencesPayload,
  CLEAR_ALL_FILTERS,
  PotentialCrossSellOffer,
  TripCategory,
  FREEZE_PRICE_CLICK,
  ITripTerminus,
} from "redmond";
import { FlightDetails } from "./components/FlightDetails";
import { FlightAlgomerchModal } from "../../../components/FlightAlgomerchModal";
import { ISelectedTrip, FlightShopStep } from "../../../reducer";
import { FlightFindMoreResults } from "../../../components/FlightList/components/FlightFindMoreResults";

import {
  AVAILABLE,
  FLIGHT_LIST_OPTIMIZATION_V1_EXPERIMENT,
  getExperimentVariant,
  INTERNATIONAL_NGS_EXPERIMENT,
  MOBILE_PREDICTION,
  useExperiments,
  SEATS_UX_OPTIMIZATION,
  AirCXV3VariantType,
  AIR_CX_V3_1_VARIANT_1,
  CONTROL,
  addTrackingProperties,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
  HOTEL_CROSS_SELL_V3_EXPERIMENT,
  HOTEL_CROSS_SELL_V3_VARIANTS,
  getExperimentVariantCustomVariants,
  AIR_CX_V4,
  VI_SHOP_BANNERS_CONTROL,
  VI_SHOP_BANNERS_EXPERIMENT,
  VI_SHOP_BANNERS_VARIANTS,
  PACKAGES_EXPERIMENT,
  PACKAGES_EXPERIMENT_VARIANTS,
  SELF_FUNDED,
  NOT_SELF_FUNDED,
} from "../../../../../context/experiments";
import { getPriceFreezeRewardsString } from "../../../utils/helpers";
import { PriceFreezeEntryBanner } from "../../../components/priceFreezeComponents/PriceFreezeEntryBanner";
import { goToPriceFreezeCheckout } from "../../../components/priceFreezeComponents/sharedUtils";
import {
  IFlightBookOverwriteQueryParams,
  IPopulateFlightBookQueryParams,
} from "../../../../book/actions/actions";
import {
  IPriceFreezeEntryButtonOwnProps,
  IPriceFreezeFlightDetailsModalOwnPropsForButton,
} from "../../../components/priceFreezeComponents/PriceFreezeEntryButton";
import {
  IPriceFreezeEntryInfoOwnProps,
  IPriceFreezeExplanationModalOwnPropsForInfo,
  IPriceFreezeFlightDetailsModalOwnPropsForInfo,
} from "../../../components/priceFreezeComponents/PriceFreezeEntryInfo";
import { RebookNoResults } from "../../../../ancillary/components";
import { PATH_HOME } from "../../../../../utils/urlPaths";
import ReactList from "react-list";
import { Flights } from "@b2bportal/air-shopping-api";
import { VirtualInterliningModal } from "../../../components/VirtualIinterliningModal";
import { trackEvent } from "../../../../../api/v0/analytics/trackEvent";
import { getFaresToShow } from "../../../utils/getFaresToShow";
import { NonstopFlightListSeparator } from "./components/NonstopFlightListSeparator";
import {
  ISetSelectedMarketingAirlineCodes,
  ISetSelectedOperatingAirlineCodes,
} from "../../../actions/actions";
import { FlightFreezeEntryCTAContent } from "../../../../freeze/experiments/c1-price-freeze-new-flights-list-entry/components/FlightFreezeEntryCTAContent";
import { FlightListConnectorProps } from "./container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  INFO_NEW_PACKAGES_CTA_LINK,
  INFO_NEW_PACKAGES_CTA_SAVE_MORE,
  INFO_NEW_PACKAGES_CTA_TEXT,
} from "../../../../search/constants";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { generatePackagesCTAUrl } from "../../../../../utils/urlHelpers";

const DESKTOP_OFFSET_SCROLL = 250;
const RESTRICTED_COUNTRY_ERROR_CODE = "RestrictedCountry";
const RESTRICTED_CITY_ERROR_CODE = "RestrictedCity";

export interface IHandleFareSelectForPriceFreeze {
  flight: IFlightListData;
  tripDetails: TripDetails;
  fare: FareDetails;
}

export interface IFlightListProps
  extends FlightListConnectorProps,
    RouteComponentProps {
  flights: Flights | null;
  flightsToRender: IFlightListData[] | [];
  invertedStopsFilterFlightList: IFlightListData[] | [];
  tripSummariesLoading: boolean | null;
  rewardsKey: string | undefined;
  handleFareSelect: (
    val1: any,
    val2: string,
    idx: number,
    limit?: FiatPrice | null
  ) => void;
  fareClassFilter: FareclassOptionFilter;
  handleFlightSelect: (val: any, val2: string) => void;
  expandedFareDetails: TripDetails | null;
  departureDate: Date | null;
  returnDate?: Date | null;
  origin: ITripTerminus | null;
  destination: ITripTerminus | null;
  isInChooseReturnStep: boolean;
  hasFlightsError: boolean;
  flightsErrorCode?: string | null;
  selectedTrip: ISelectedTrip;
  maxFlightPriceFilter: number;
  hasAppliedFareClassFilter: boolean;
  hasAppliedNonFareclassFilter: boolean;
  setOpenFlightShopCalendarDesktop: (val: boolean) => void;
  isRoundTrip: boolean;
  setOpenFlightShopCalendarMobile: (val: boolean) => void;
  resetAllFilters: () => void;
  rerunPrediction: () => void;
  openMobileFlightDetailsModal: boolean;
  setOpenMobileFlightDetailsModal: (val: boolean) => void;
  priceFreezeOfferCheapestTripAirports: AirportMap;
  priceFreezeOfferCheapestTripTripDetails: TripDetails;
  priceFreezeOffer: IPriceFreezeOfferEntries | null;
  history: H.History;
  priceFreezeOfferCheapestTripFareId: string;
  priceFreezeRewards?: { [key: string]: RewardsPrice };
  priceFreezeDuration?: TimeToLive;
  cheapestFrozenPrice?: { fiat: FiatPrice; rewards: RewardsPrice | undefined };
  priceFreezeFiat?: FiatPrice;
  priceFreezeCap?: Cap;
  populateFlightBookQueryParams: (args: {
    history: H.History;
    pathname?: string;
    preserveQuery?: boolean;
    newQueryParams?: IFlightBookOverwriteQueryParams;
  }) => IPopulateFlightBookQueryParams;
  priceDropProtectionCandidateId?: string;
  hasPriceFreezeOnOutbound: boolean;
  setHasPriceFreezeOnOutbound: (val: boolean) => void;
  flightShopType: FlightShopType;
  flightShopProgress: FlightShopStep;
  useLockPriceLanguage?: boolean;
  offersByTripId?: { [key: string]: TravelWalletOffer };
  credit?: TravelWalletCredit;
  isLoadingIndicatorDisplayed?: boolean;
  isReadyToRenderFlights?: boolean;
  showRefundableFaresOnly?: boolean;
  cfarOffers?: {
    [key: string]: {
      [key: string]: FetchCfarOfferResponseV2;
    };
  };
  fareCustomizations?: { [key in string]: IFareCustomization };
  handleRefundableFare?: (fareId: string, isRefundableFare: boolean) => void;
  largestValueAccount?: RewardsAccount;
  isRefundableFaresEnabled?: boolean;
  sortOption: FlightSortOption;
  hasSetMaxPriceFilter: boolean;
  isChatbotEnabled?: boolean;
  airEntryProperties?: AirEntryProperties;
  isThebesHackerFaresV2Cap1ExperimentAvailable?: boolean;
  stopsOption?: SliceStopCountFilter;
  airCXV3Variant?: AirCXV3VariantType;
  isInPolicyFilter?: boolean;
  isAirPriceFreezeEnabled?: boolean;
  userHasSetFlightPreferences?: boolean;
  userFlightPreferencesNotAvailable?: boolean;
  userFlightPreferences?: UserFlightPreferencesPayload;
  shouldApplyUserFlightPreferences?: boolean;
  setSelectedMarketingAirlineCodes: (
    marketingAirlineCodes: string[]
  ) => ISetSelectedMarketingAirlineCodes;
  setSelectedOperatingAirlineCodes: (
    operatingAirlineCodes: string[]
  ) => ISetSelectedOperatingAirlineCodes;
  isSpiritOrFrontierAirlinesSelected: boolean;
  potentialCrossSellOffers: PotentialCrossSellOffer[];
}

enum ModalTypes {
  AlgomerchModal,
  MobileFlightDetails,
  SelfCheckModal,
  MissedConnectionRebookingModal,
}

type IOpenModal = ModalTypes | false;

export interface IFlightListData {
  slice: string;
  fares: any; //Todo: add type
}

export const FlightList = (props: IFlightListProps) => {
  const {
    flightsToRender,
    tripSummariesLoading,
    flights,
    invertedStopsFilterFlightList,
    rewardsKey,
    handleFareSelect,
    fareClassFilter,
    handleFlightSelect,
    expandedFareDetails,
    departureDate,
    returnDate,
    isInChooseReturnStep,
    hasFlightsError,
    flightsErrorCode,
    selectedTrip,
    maxFlightPriceFilter,
    isRoundTrip,
    hasAppliedFareClassFilter,
    hasAppliedNonFareclassFilter,
    setOpenFlightShopCalendarDesktop,
    setOpenFlightShopCalendarMobile,
    resetAllFilters,
    rerunPrediction,
    openMobileFlightDetailsModal,
    setOpenMobileFlightDetailsModal,
    priceFreezeOfferCheapestTripAirports,
    priceFreezeOfferCheapestTripTripDetails,
    priceFreezeOfferCheapestTripFareId,
    priceFreezeOffer,
    priceFreezeRewards,
    priceFreezeDuration,
    cheapestFrozenPrice,
    priceFreezeFiat,
    priceFreezeCap,
    history,
    priceDropProtectionCandidateId,
    hasPriceFreezeOnOutbound,
    setHasPriceFreezeOnOutbound,
    flightShopType,
    flightShopProgress,
    useLockPriceLanguage,
    offersByTripId,
    credit,
    isLoadingIndicatorDisplayed,
    isReadyToRenderFlights = true,
    showRefundableFaresOnly,
    cfarOffers,
    fareCustomizations,
    handleRefundableFare,
    largestValueAccount,
    isRefundableFaresEnabled,
    sortOption,
    hasSetMaxPriceFilter,
    isChatbotEnabled,
    airEntryProperties,
    isThebesHackerFaresV2Cap1ExperimentAvailable,
    stopsOption,
    airCXV3Variant,
    isInPolicyFilter,
    isAirPriceFreezeEnabled,
    userFlightPreferences,
    userHasSetFlightPreferences,
    userFlightPreferencesNotAvailable,
    shouldApplyUserFlightPreferences,
    setSelectedMarketingAirlineCodes,
    setSelectedOperatingAirlineCodes,
    isSpiritOrFrontierAirlinesSelected,
    potentialCrossSellOffers,
    isPriceFreezeFlightListCtaEnabled,
    isPriceFreezeCurrentEntryDisabled,
    setPriceFreezeOfferData,
    generateCustomPriceFreezeOffer,
    setChosenReturnSlice,
    setChosenOutgoingSlice,
    origin,
    destination,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
  } = props;
  const { matchesMobile, matchesDesktop, matchesLargeDesktop } =
    useDeviceTypes();
  const matchesMediumDesktopOnly = matchesDesktop && !matchesLargeDesktop;
  const [flightsToShow, setFlightsToShow] = useState<IFlightListData[]>([]);
  const [clickedFareId, setClickedFareId] = useState("");
  const [policyDescriptorViewCount, setPolicyDescriptorViewCount] = useState(0);
  const expState = useExperiments();
  const isInPredictionExperiment = useMemo(
    () =>
      getExperimentVariant(expState.experiments, MOBILE_PREDICTION) ===
      AVAILABLE,
    [expState]
  );
  const isPackagesExperiment = useMemo(() => {
    const packagesExperimentVariant = getExperimentVariantCustomVariants(
      expState.experiments,
      PACKAGES_EXPERIMENT,
      PACKAGES_EXPERIMENT_VARIANTS
    );

    return [SELF_FUNDED, NOT_SELF_FUNDED].includes(packagesExperimentVariant);
  }, [expState]);

  const isInChooseDepartureStep =
    flightShopProgress === FlightShopStep.ChooseDeparture;

  const isFlightListOptimizationExperiment = useMemo(
    () =>
      getExperimentVariant(
        expState.experiments,
        FLIGHT_LIST_OPTIMIZATION_V1_EXPERIMENT
      ) === AVAILABLE,
    [expState]
  );

  const isSeatsUXOptimizationExperiment = useMemo(
    () =>
      getExperimentVariant(expState.experiments, SEATS_UX_OPTIMIZATION) ===
      AVAILABLE,
    [expState]
  );

  const isAirCXV4Experiment = React.useMemo(
    () => getExperimentVariant(expState.experiments, AIR_CX_V4) === AVAILABLE,
    [expState]
  );

  const listRef = React.useRef<ReactList | null>(null);
  const divRef = React.useRef<HTMLDivElement | null>(null);

  const isInDisruptionProtectionRebook =
    flightShopType === FlightShopType.DISRUPTION_PROTECTION_EXERCISE;

  const isAirCXV3Experiment = airCXV3Variant !== CONTROL;

  const hotelCrossSellV3Variant = useMemo(
    () =>
      getExperimentVariantCustomVariants(
        expState.experiments,
        HOTEL_CROSS_SELL_V3_EXPERIMENT,
        HOTEL_CROSS_SELL_V3_VARIANTS
      ),
    [expState.experiments]
  );

  const isHotelCrossSellV3Experiment = hotelCrossSellV3Variant !== CONTROL;

  const ngsEnabled = useMemo(
    () =>
      getExperimentVariant(
        expState.experiments,
        INTERNATIONAL_NGS_EXPERIMENT
      ) === AVAILABLE,
    [expState]
  );

  const isGlobalMobileNavExperiment =
    getExperimentVariant(expState.experiments, GLOBAL_MOBILE_NAV_EXPERIMENT) ===
    AVAILABLE;

  const viShopBannerVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    VI_SHOP_BANNERS_EXPERIMENT,
    VI_SHOP_BANNERS_VARIANTS
  );

  const setFetchMoreData = () => {
    const newPageSize = flightsToShow.length + constants.SHOW_MORE_NUM;
    return setTimeout(
      () => setFlightsToShow(flightsToRender.slice(0, newPageSize)),
      500
    );
  };

  const [expandedFlight, setExpandedFlight] = useState("");

  useEffect(() => {
    if (flightsToRender.length > 0) {
      if (!matchesMobile || (matchesMobile && isInDisruptionProtectionRebook)) {
        setFlightsToShow(
          flightsToRender.slice(0, constants.INITIAL_RESULT_SET_SIZE)
        );
      } else {
        setFlightsToShow(flightsToRender);
      }
      setExpandedFlight("");
    } else {
      setFlightsToShow([]);
    }
    if (!matchesMobile || (matchesMobile && isInDisruptionProtectionRebook)) {
      return clearTimeout(setFetchMoreData());
    }
  }, [flightsToRender]);

  useEffect(() => {
    if (tripSummariesLoading) setFlightsToShow([]);
  }, [tripSummariesLoading]);

  const [openModal, setOpenModal] = React.useState<IOpenModal>(false);
  const [selectedAlgomerchTag, setSelectedAlgomerchTag] =
    React.useState<AlgomerchTag>(AlgomerchTag.Cheapest);

  const handleClickAlgomerchTag = (tagText: string) => {
    const allTags = Object.keys(AlgomerchTag);
    const selectedTag = allTags.find((tag) =>
      tagText.includes(mapAlgomerchTexts[tag])
    );

    setSelectedAlgomerchTag(
      (selectedTag as AlgomerchTag) ?? AlgomerchTag.Cheapest
    );
  };

  const ctaTitles: FareDetailsCardCtaTitles = {
    primary: !hasPriceFreezeOnOutbound // @TODO: I think we can remove this when the c1-price-freeze-new-flights-list-entry experiment is over
      ? {
          getContent: constants.getSelectCtaCopy,
          type: FareDetailsCardCtaType.Function,
        }
      : undefined,
    secondary: isPriceFreezeFlightListCtaEnabled
      ? {
          component: FlightFreezeEntryCTAContent,
          type: FareDetailsCardCtaType.Component,
          shouldRender: (fare: FareDetails) =>
            !fare.id.includes("-refundable-fare") &&
            !!fare.middleOffer &&
            !!fare.priceFreezeOffers,
        }
      : undefined,
  };

  const handleFareSelectForPriceFreeze = React.useCallback(
    ({ flight, tripDetails, fare }: IHandleFareSelectForPriceFreeze) => {
      if (isInChooseReturnStep) {
        setChosenReturnSlice({
          returnFareId: fare.id,
          returnSliceId: flight.slice,
          returnFareRating: fare?.slices[1].fareShelf?.rating,
          tripId: tripDetails.id,
        });
      } else {
        setChosenOutgoingSlice({
          outgoingFareId: fare.id,
          outgoingSliceId: flight.slice,
          outgoingFareRating: fare?.slices[0].fareShelf?.rating,
          tripId: tripDetails.id,
          resetReturnIds: true,
        });
      }
    },
    [isInChooseReturnStep, setChosenReturnSlice, setChosenOutgoingSlice]
  );

  const onPriceFreezeCtaClick = useCallback(
    (flight: IFlightListData, _index: number) => {
      return (tripDetails: TripDetails, fare: FareDetails | undefined) => {
        if (!isPriceFreezeFlightListCtaEnabled) {
          return;
        }

        const fareId = fare?.id ?? "";
        const tripId = tripDetails.id;

        if (fare?.middleOffer && fare?.priceFreezeOffers) {
          trackEvent({
            eventName: FREEZE_PRICE_CLICK,
            properties: {
              price_freeze_entry: "flight_list_fare_class",
            },
          });
          handleFareSelectForPriceFreeze({ flight, tripDetails, fare });
          setPriceFreezeOfferData(fare.priceFreezeOffers);
          generateCustomPriceFreezeOffer(fare.middleOffer, history, false);
          goToPriceFreezeCheckout({
            tripDetails,
            fareId,
            tripId,
            priceDropProtectionCandidateId,
            history,
            entry: PriceFreezeEntryEnum.FLIGHT_LIST_FARE_CLASS,
            entryButton: EntryButtonEnum.FREEZE_PRICE,
            prevFlight: PreviousFlightEnum.CHOSEN_FLIGHT,
          });
        }
      };
    },
    [
      isPriceFreezeFlightListCtaEnabled,
      history,
      priceDropProtectionCandidateId,
      handleFareSelectForPriceFreeze,
      setPriceFreezeOfferData,
      generateCustomPriceFreezeOffer,
    ]
  );

  // @TODO: I think we can remove this when the c1-price-freeze-new-flights-list-entry experiment is over
  const getFreezePriceHandler = useCallback(
    (flight: IFlightListData, index: number) => {
      return (_tripDetails: TripDetails, fare: FareDetails | undefined) => {
        const fareId = fare?.id ?? "";
        const tripId = flight.fares.find(
          (fare: any) => fare.id == fareId
        )?.tripId;

        /*
          note: when Freeze Price is clicked on ChooseDeparture and it's a round trip,
          set hasPriceFreezeOnOutbound to true to mark that it's been clicked on departure
        */
        if (
          flightShopProgress === FlightShopStep.ChooseDeparture &&
          isRoundTrip
        ) {
          setHasPriceFreezeOnOutbound(true);
          handleFareSelect(flight, fareId, index);
        }
        // note: when Freeze Price is clicked again on ChooseReturn, take the user to the checkout page
        else if (
          flightShopProgress === FlightShopStep.ChooseReturn &&
          hasPriceFreezeOnOutbound
        ) {
          goToPriceFreezeCheckout({
            fareId,
            tripId,
            priceDropProtectionCandidateId,
            history,
            entry: PriceFreezeEntryEnum.FLIGHT_LIST,
            entryButton: EntryButtonEnum.FREEZE_PRICE,
            prevFlight: PreviousFlightEnum.CHOSEN_FLIGHT,
          });
        }
      };
    },
    [
      history,
      flightShopProgress,
      isRoundTrip,
      hasPriceFreezeOnOutbound,
      priceDropProtectionCandidateId,
      setHasPriceFreezeOnOutbound,
      goToPriceFreezeCheckout,
      handleFareSelect,
    ]
  );

  const renderSkeletonFlights = () => {
    return (
      <ExpandableCard
        key={""}
        className={clsx("flight-list-item", "flight-row", "b2b")}
        isMobile={matchesMobile}
        expandedCardKey={"expandedFlight"}
        cardKey={""}
        handleCardKeyChange={() => {}}
        scrollExpandedIntoView={true}
        content={{
          title: (
            <FlightListInfo
              tripId={""}
              onFareClick={() => {}}
              type={FlightCardType.skeleton}
            />
          ),
          body: <></>,
        }}
      />
    );
  };

  const getFlightsErrorInfo = () => {
    let flightsErrorTitle = constants.ERROR_TITLE;
    let flightsErrorSubtitle = constants.ERROR_SUBTITLE;
    let flightErrorButton = constants.RELOAD;
    let flightErrorOnClickFunction = () => window.location.reload();

    if (flightsErrorCode === RESTRICTED_COUNTRY_ERROR_CODE) {
      flightsErrorTitle = constants.RESTRICTED_COUNTRY_TITLE;
      flightsErrorSubtitle = constants.RESTRICTED_COUNTRY_SUBTITLE;
      flightErrorButton = constants.SEARCH_AGAIN;
      flightErrorOnClickFunction = () => history.push(PATH_HOME);
    } else if (flightsErrorCode === RESTRICTED_CITY_ERROR_CODE) {
      flightsErrorTitle = constants.RESTRICTED_CITY_TITLE;
      flightsErrorSubtitle = constants.RESTRICTED_CITY_SUBTITLE;
      flightErrorButton = constants.SEARCH_AGAIN;
      flightErrorOnClickFunction = () => history.push(PATH_HOME);
    }

    return {
      flightsErrorTitle,
      flightsErrorSubtitle,
      flightErrorButton,
      flightErrorOnClickFunction,
    };
  };

  const renderNoFlightsMessaging = () => {
    let noFlightsString = constants.GENERIC_FLIGHTS_NOT_FOUND_SUBTITLE;

    if (hasAppliedFareClassFilter) {
      noFlightsString = constants.FARE_CLASS_FLIGHTS_NOT_FOUND_SUBTITLE;
    } else if (hasAppliedNonFareclassFilter) {
      noFlightsString = constants.FITLERED_FLIGHTS_NOT_FOUND_SUBTITLE;
    }

    const {
      flightsErrorTitle,
      flightsErrorSubtitle,
      flightErrorButton,
      flightErrorOnClickFunction,
    } = getFlightsErrorInfo();

    switch (flightShopType) {
      case FlightShopType.DISRUPTION_PROTECTION_EXERCISE: {
        return <RebookNoResults />;
      }
      default: {
        return hasFlightsError ? (
          <Box className="no-results-container">
            <NoResults
              className="flight-list-no-results"
              title={flightsErrorTitle}
              subtitle={flightsErrorSubtitle}
            />
            <button
              onClick={() => flightErrorOnClickFunction()}
              className={"reload-button"}
            >
              {flightErrorButton}
            </button>
          </Box>
        ) : (
          <Box
            className={clsx("no-results-container", {
              "air-cx-v3": isAirCXV3Experiment,
            })}
          >
            <NoResults
              className="flight-list-no-results"
              title={
                isAirCXV3Experiment
                  ? constants.FLIGHTS_NOT_FOUND_TITLE_AIR_CX_V3
                  : constants.FLIGHTS_NOT_FOUND_TITLE
              }
              subtitle={
                isAirCXV3Experiment
                  ? constants.FLIGHTS_NOT_FOUND_SUBTITLE_AIR_CX_V3
                  : noFlightsString
              }
            />
            {hasAppliedNonFareclassFilter ? (
              <Box className="no-results-buttons-container">
                <Button
                  onClick={() => {
                    resetAllFilters();
                    rerunPrediction();
                    trackEvent({
                      eventName: CLEAR_ALL_FILTERS,
                      properties: addTrackingProperties(
                        expState.trackingProperties
                      ),
                    });
                  }}
                  className={clsx("reset-filters-dates-button", "b2b")}
                >
                  {isAirCXV3Experiment ? CLEAR_FILTERS_CTA_TEXT : RESET_FILTERS}
                </Button>
                <Button
                  onClick={() => {
                    if (matchesMobile) {
                      setOpenFlightShopCalendarMobile(true);
                    } else {
                      setOpenFlightShopCalendarDesktop(true);
                    }
                  }}
                  className={clsx("filters-change-dates-button", "b2b")}
                >
                  {isAirCXV3Experiment ? CHANGE_DATES_CTA_TEXT : CHANGE_DATES}
                </Button>
              </Box>
            ) : (
              <Button
                onClick={() => {
                  if (matchesMobile) {
                    setOpenFlightShopCalendarMobile(true);
                  } else {
                    setOpenFlightShopCalendarDesktop(true);
                  }
                }}
                className={clsx("change-dates-button", "b2b")}
              >
                {isAirCXV3Experiment ? CHANGE_DATES_CTA_TEXT : CHANGE_DATES}
              </Button>
            )}
          </Box>
        );
      }
    }
  };

  const handleSliceSelect = (
    fareId: string,
    selectedFareClass: string,
    flight: IFlightListData
  ) => {
    if (fareId === expandedFlight) {
      setExpandedFlight("");
    } else {
      setExpandedFlight(fareId);
      !isInChooseReturnStep
        ? handleFlightSelect(
            flight.fares.map((fare: any) => fare.example),
            selectedFareClass
          )
        : handleFlightSelect(
            flight.fares.map((fare: any) => {
              return { trip: fare.tripId, fare: fare.id };
            }),
            selectedFareClass
          );
    }
  };

  const renderPackagesCTA = () => {
    const handlePackagesCTAClick = () => {
      const url = generatePackagesCTAUrl({
        origin: origin,
        destination: destination,
        fromDate: departureDate,
        untilDate: returnDate,
        stopsOption,
        fareClass: fareClassFilter,
        adultsCount,
        childrenCount,
        infantsInSeatCount,
        infantsOnLapCount,
        matchesMobile,
      });

      history.push(url);
    };

    return (
      <ButtonWrap
        className="packages-info-cta"
        onClick={() => handlePackagesCTAClick()}
      >
        <Box className="left-section">
          <img
            src={`${window.location.origin}/email-assets/generic-hotels-offer.jpg`}
          ></img>
          <img
            src={`${window.location.origin}/email-assets/generic-flights-offer.jpg`}
          ></img>
        </Box>
        <Box className="center-section">
          <Typography className="title">
            {INFO_NEW_PACKAGES_CTA_SAVE_MORE}
          </Typography>
          <Typography className="text">{INFO_NEW_PACKAGES_CTA_TEXT}</Typography>
        </Box>
        <Box className="right-section">
          {matchesDesktop && (
            <ActionButton
              className="search-btn big-btn"
              message={INFO_NEW_PACKAGES_CTA_LINK}
              onClick={() => handlePackagesCTAClick()}
            />
          )}
          {matchesMobile && (
            <FontAwesomeIcon
              className="mobile-right-chevron"
              icon={faChevronRight}
              onClick={() => handlePackagesCTAClick()}
            />
          )}
        </Box>
      </ButtonWrap>
    );
  };

  const renderFlightListInfo = (
    selectedFare: any,
    showListView: boolean,
    slice: Slice,
    flight: IFlightListData,
    index: number
  ) => {
    const fareId = selectedFare.example?.fare || selectedFare.id;
    return (
      <>
        <Box
          id={fareId}
          className={clsx(
            "flight-list-item",
            "flight-row",
            {
              "row-view-desktop":
                matchesMediumDesktopOnly ||
                (matchesLargeDesktop && showListView) ||
                (matchesDesktop && isInDisruptionProtectionRebook),
              expanded: fareId === expandedFlight,
            },
            "b2b"
          )}
          key={fareId}
        >
          <FlightListInfo
            {...{
              selectedFare,
              slice,
              flights,
              flight,
              rewardsKey,
              fareClassFilter,
              maxFlightPriceFilter,
              isRoundTrip,
              offersByTripId,
              credit,
              largestValueAccount,
              showRefundableFaresOnly,
              cfarOffers,
              isChatbotEnabled,
              isInPolicyFilter,
              tripType: isRoundTrip
                ? TripCategory.ROUND_TRIP
                : TripCategory.ONE_WAY,
            }}
            userFlightPreferences={userFlightPreferences}
            userHasSetFlightPreferences={userHasSetFlightPreferences}
            shouldApplyUserFlightPreferences={shouldApplyUserFlightPreferences}
            type={FlightCardType.content}
            isExpanded={fareId === expandedFlight}
            onClick={(selectedFareClass: string) => {
              handleSliceSelect(fareId, selectedFareClass, flight);
              setTimeout(() => {
                if (matchesMobile) {
                  listRef.current?.scrollTo(index);
                } else {
                  const OFFSET = DESKTOP_OFFSET_SCROLL;
                  const cardTop =
                    document?.getElementById(fareId)?.getBoundingClientRect()
                      .top || 0;
                  window.scrollBy({
                    top: (cardTop as number) - OFFSET,
                    behavior: "smooth",
                  });
                }
              }, 100);
            }}
            onAlgomerchClick={(label: string) => {
              handleClickAlgomerchTag(label);
              setOpenModal(ModalTypes.AlgomerchModal);
            }}
            onClickSelfCheckBanner={() => {
              if (viShopBannerVariant === VI_SHOP_BANNERS_CONTROL) {
                trackEvent({
                  eventName: VIEWED_SELF_TRANSFER_MODAL,
                  properties: {
                    entry_point: !isInChooseReturnStep
                      ? VirtualInterlineEntryPoint.Outbound
                      : VirtualInterlineEntryPoint.Return,
                  } as VirtualInterlineModalProperties,
                });
                setOpenModal(ModalTypes.SelfCheckModal);
              } else {
                trackEvent({
                  eventName: VIEWED_MISSED_CONNECTION_GUARANTEE_MODAL,
                  properties: {
                    entry_point: !isInChooseReturnStep
                      ? VirtualInterlineEntryPoint.Outbound
                      : VirtualInterlineEntryPoint.Return,
                  } as VirtualInterlineModalProperties,
                });
                setOpenModal(ModalTypes.MissedConnectionRebookingModal);
              }
            }}
            onFareClick={(fareId: string) => {
              const marketingAirlineCodes = slice.segments.map(
                // confirmed this is the airline code, not the full name
                (segment) => segment.marketingAirline
              );
              const operatingAirlineCodes = slice.segments.map(
                (segment) => segment.operatingAirline
              );
              setSelectedMarketingAirlineCodes(marketingAirlineCodes);
              setSelectedOperatingAirlineCodes(operatingAirlineCodes);

              setClickedFareId(fareId);
            }}
            showPriceAndTags={!isInDisruptionProtectionRebook}
            useRowFlightListInfoOnly={isInDisruptionProtectionRebook}
            onOpenPolicyDescriptor={onOpenPolicyDescriptor}
            isOutgoing={!isInChooseReturnStep}
          />
          {matchesMobile &&
            isAirCXV4Experiment &&
            fareId === expandedFlight && (
              <MobilePopoverCard
                headerElement={
                  <Header
                    className="mobile-flight-details-header-container"
                    left={
                      <BackButton
                        className="back-button-icon"
                        onClick={() => {
                          setExpandedFlight("");
                        }}
                      />
                    }
                    center={
                      <Typography
                        variant="body1"
                        className="mobile-flight-details-header"
                      >
                        {"Choose Fare"}
                      </Typography>
                    }
                    isMobile={true}
                    fullWidth={true}
                  />
                }
                open={true}
                fullScreen={true}
                className="mobile-flight-details-container"
                contentClassName="mobile-flight-details-container-content"
                centered={true}
              >
                {(!expandedFareDetails || isLoadingIndicatorDisplayed) && (
                  <LoadingIndicator
                    className="flight-shop-details-loading-indicator"
                    indicatorSize={"small"}
                    indicator={B2BSpinner}
                    message={constants.LOADING_FLIGHT_DETAILS_STRING}
                  />
                )}
                {expandedFareDetails && !isLoadingIndicatorDisplayed && (
                  <FlightDetails
                    fareClassFilter={fareClassFilter}
                    hasAppliedFareClassFilter={hasAppliedFareClassFilter}
                    isOutgoing={!isInChooseReturnStep}
                    selectedFareId={clickedFareId}
                    onFareClick={(_sliceId, fare, limit) => {
                      handleFareSelect(flight, fare!.id, index, limit);
                    }}
                    onAlgomerchClick={(label: string) => {
                      handleClickAlgomerchTag(label);
                      setOpenModal(ModalTypes.AlgomerchModal);
                    }}
                    noticeBannerContent={undefined}
                    ctaTitles={ctaTitles}
                    onPriceFreezeClick={
                      isPriceFreezeFlightListCtaEnabled
                        ? onPriceFreezeCtaClick(flight, index)
                        : getFreezePriceHandler(flight, index)
                    }
                    showFareDetailsTitle={!isInDisruptionProtectionRebook}
                    isRefundableFaresEnabled={isRefundableFaresEnabled}
                    // note: in the Rapid Rebook variant (Disruption Protection), it should not be showing any price to the user
                    isPriceHidden={isInDisruptionProtectionRebook}
                    isFlightListOptimizationExperiment={
                      isFlightListOptimizationExperiment
                    }
                    {...{
                      selectedOutboundFare: selectedTrip.outgoingFareId
                        ? flights?.fares[selectedTrip?.outgoingFareId]
                        : undefined,
                      tripDetails: expandedFareDetails,
                      rewardsKey,
                      departureDate,
                      returnDate,
                      airports: flights!.airports,
                      outgoingFareRating: selectedTrip.outgoingFareRating,
                      openMobileFlightDetailsModal,
                      setOpenMobileFlightDetailsModal,
                      handleRefundableFare,
                      fareCustomizations,
                      isChatbotEnabled,
                      onOpenPolicyDescriptor,
                    }}
                    isSeatsUXOptimizationExperiment={
                      isSeatsUXOptimizationExperiment
                    }
                    airEntryProperties={airEntryProperties}
                    isThebesHackerFaresV2Cap1ExperimentAvailable={
                      isThebesHackerFaresV2Cap1ExperimentAvailable
                    }
                    isSpiritOrFrontierAirlinesSelected={
                      isSpiritOrFrontierAirlinesSelected
                    }
                    isAirCXV4Experiment={isAirCXV4Experiment}
                  />
                )}
              </MobilePopoverCard>
            )}
          {fareId === expandedFlight &&
            expandedFareDetails &&
            !isLoadingIndicatorDisplayed && (
              <FlightDetails
                fareClassFilter={fareClassFilter}
                hasAppliedFareClassFilter={hasAppliedFareClassFilter}
                isOutgoing={!isInChooseReturnStep}
                selectedFareId={clickedFareId}
                onFareClick={(_sliceId, fare, limit) => {
                  handleFareSelect(flight, fare!.id, index, limit);
                }}
                onAlgomerchClick={(label: string) => {
                  handleClickAlgomerchTag(label);
                  setOpenModal(ModalTypes.AlgomerchModal);
                }}
                noticeBannerContent={undefined}
                ctaTitles={ctaTitles}
                onPriceFreezeClick={
                  isPriceFreezeFlightListCtaEnabled
                    ? onPriceFreezeCtaClick(flight, index)
                    : getFreezePriceHandler(flight, index)
                }
                showFareDetailsTitle={!isInDisruptionProtectionRebook}
                isRefundableFaresEnabled={isRefundableFaresEnabled}
                // note: in the Rapid Rebook variant (Disruption Protection), it should not be showing any price to the user
                isPriceHidden={isInDisruptionProtectionRebook}
                isFlightListOptimizationExperiment={
                  isFlightListOptimizationExperiment
                }
                {...{
                  selectedOutboundFare: selectedTrip.outgoingFareId
                    ? flights?.fares[selectedTrip?.outgoingFareId]
                    : undefined,
                  tripDetails: expandedFareDetails,
                  rewardsKey,
                  departureDate,
                  returnDate,
                  airports: flights!.airports,
                  outgoingFareRating: selectedTrip.outgoingFareRating,
                  openMobileFlightDetailsModal,
                  setOpenMobileFlightDetailsModal,
                  handleRefundableFare,
                  fareCustomizations,
                  isChatbotEnabled,
                  onOpenPolicyDescriptor,
                }}
                isSeatsUXOptimizationExperiment={
                  isSeatsUXOptimizationExperiment
                }
                airEntryProperties={airEntryProperties}
                isThebesHackerFaresV2Cap1ExperimentAvailable={
                  isThebesHackerFaresV2Cap1ExperimentAvailable
                }
                isSpiritOrFrontierAirlinesSelected={
                  isSpiritOrFrontierAirlinesSelected
                }
                isAirCXV4Experiment={isAirCXV4Experiment}
              />
            )}
          {fareId === expandedFlight &&
            (!expandedFareDetails || isLoadingIndicatorDisplayed) && (
              <LoadingIndicator
                className="flight-shop-details-loading-indicator"
                indicatorSize={"small"}
                indicator={B2BSpinner}
                message={constants.LOADING_FLIGHT_DETAILS_STRING}
              />
            )}
        </Box>
        {isPackagesExperiment &&
          ((index === 2 && isInChooseDepartureStep) ||
            (index === 8 && isInChooseReturnStep)) &&
          renderPackagesCTA()}
      </>
    );
  };

  const onOpenPolicyDescriptor = (
    entryPoint: string,
    reasons: PolicyViolation[]
  ) => {
    if (policyDescriptorViewCount <= 5) {
      trackEvent({
        eventName: VIEWED_POLICY_DESCRIPTOR,
        properties: {
          type: POLICY_DESCRIPTOR,
          entry_point: entryPoint,
          funnel: "flights",
          policy_reason: reasons.join(", "),
        },
      });
      setPolicyDescriptorViewCount((prevState) => prevState + 1);
    }
  };

  const renderFlights = () => {
    const faresToShow = getFaresToShow(
      flightsToShow,
      flights,
      hasAppliedFareClassFilter,
      fareClassFilter,
      matchesMobile,
      cfarOffers,
      isInChooseReturnStep,
      showRefundableFaresOnly,
      hasSetMaxPriceFilter,
      maxFlightPriceFilter,
      Boolean(isInPolicyFilter)
    );

    const invertedStopsFilterFaresToShow =
      airCXV3Variant === AIR_CX_V3_1_VARIANT_1
        ? getFaresToShow(
            invertedStopsFilterFlightList,
            flights,
            hasAppliedFareClassFilter,
            fareClassFilter,
            matchesMobile,
            cfarOffers,
            isInChooseReturnStep,
            showRefundableFaresOnly,
            hasSetMaxPriceFilter,
            maxFlightPriceFilter,
            Boolean(isInPolicyFilter)
          ).sort(
            (a, b) =>
              (flights?.slices[a.flight.slice].totalDurationMinutes || 0) -
              (flights?.slices[b.flight.slice].totalDurationMinutes || 0)
          )
        : [];

    if (matchesMobile) {
      // handle sort order
      if (sortOption === FlightSortOption.FareScore) {
        faresToShow.sort(
          (a, b) => (b?.fareScore || Infinity) - (a?.fareScore || Infinity)
        );
      } else if (sortOption === FlightSortOption.Price) {
        faresToShow.sort(
          (a, b) =>
            (a.fare?.amount?.fiat?.value || Infinity) -
            (b.fare?.amount?.fiat?.value || Infinity)
        );
      }
    }

    const allFaresToShow =
      airCXV3Variant === AIR_CX_V3_1_VARIANT_1 &&
      !!invertedStopsFilterFaresToShow.length
        ? (
            faresToShow as Array<
              | {
                  fare: any;
                  flight: IFlightListData;
                  fareScore: number;
                }
              | undefined
            >
          )
            .concat(undefined) // insert blank "fare" for list separator
            .concat(invertedStopsFilterFaresToShow)
        : faresToShow;

    const mobileContent = isInDisruptionProtectionRebook ? (
      <InfiniteScroll
        dataLength={faresToShow.length}
        next={setFetchMoreData}
        hasMore={faresToShow.length < flightsToRender.length}
        loader={
          <Box className="loading-flights">
            <B2BSpinner classes={["loading-flights-bunny"]} />
          </Box>
        }
      >
        {faresToShow.map(({ fare, flight }, index) => {
          const flightSliceId = flight.slice;
          const flightSlice = flights?.slices[flightSliceId];
          if (!flightSlice) {
            return null;
          }
          const isListView = !flightSlice?.domestic && !ngsEnabled;
          return renderFlightListInfo(
            fare,
            isListView,
            flightSlice,
            flight,
            index
          );
        })}
      </InfiniteScroll>
    ) : (
      <div ref={divRef} className="availability-list">
        <ReactList
          ref={listRef}
          itemRenderer={(index: number) => {
            const currentFare = allFaresToShow[index];
            if (!currentFare) {
              return (
                <NonstopFlightListSeparator
                  isMobile
                  key="nonstop-flight-separator"
                />
              );
            }
            const { fare, flight } = currentFare;
            const flightSliceId = flight.slice;
            const flightSlice = flights?.slices[flightSliceId];
            const isListView = !flightSlice?.domestic && !ngsEnabled;
            if (!flightSlice) {
              // itemRenderer does not allow for returning of undefined/null
              return (
                <Box
                  display="none"
                  key={`${fare.example?.fare || fare.id}-none`}
                />
              );
            }

            return renderFlightListInfo(
              fare,
              isListView,
              flightSlice,
              flight,
              index
            );
          }}
          length={allFaresToShow.length}
          type="variable"
        />
      </div>
    );

    return matchesMobile ? (
      mobileContent
    ) : (
      <InfiniteScroll
        dataLength={faresToShow.length}
        next={setFetchMoreData}
        hasMore={faresToShow.length < flightsToRender.length}
        loader={
          <Box className="loading-flights">
            <B2BSpinner classes={["loading-flights-bunny"]} />
          </Box>
        }
      >
        {faresToShow.map(({ fare, flight }, index) => {
          const flightSliceId = flight.slice;
          const flightSlice = flights?.slices[flightSliceId];
          if (!flightSlice) {
            return null;
          }
          const isListView = !flightSlice?.domestic && !ngsEnabled;
          return renderFlightListInfo(
            fare,
            isListView,
            flightSlice,
            flight,
            index
          );
        })}
        {airCXV3Variant === AIR_CX_V3_1_VARIANT_1 &&
          stopsOption === SliceStopCountFilter.NONE &&
          !!invertedStopsFilterFaresToShow.length && (
            <>
              <NonstopFlightListSeparator />
              {invertedStopsFilterFaresToShow.map(({ fare, flight }, index) => {
                const flightSliceId = flight.slice;
                const flightSlice = flights?.slices[flightSliceId];
                if (!flightSlice) {
                  return null;
                }
                const isListView = !flightSlice?.domestic && !ngsEnabled;
                return renderFlightListInfo(
                  fare,
                  isListView,
                  flightSlice,
                  flight,
                  index
                );
              })}
            </>
          )}
      </InfiniteScroll>
    );
  };

  const priceFreezeFlightDetailsModalPropsForButton: IPriceFreezeFlightDetailsModalOwnPropsForButton =
    {
      priceFreezeOffer: priceFreezeOffer,
      frozenPrice: cheapestFrozenPrice,
      duration: priceFreezeDuration,
      tripDetails: priceFreezeOfferCheapestTripTripDetails,
      fareId: priceFreezeOfferCheapestTripFareId,
      airports: priceFreezeOfferCheapestTripAirports,
      useLockPriceLanguage: useLockPriceLanguage,
    };

  const priceFreezeFlightDetailsModalPropsForInfo: IPriceFreezeFlightDetailsModalOwnPropsForInfo =
    priceFreezeFlightDetailsModalPropsForButton;

  const PriceFreezeExplanationModalPropsForInfo: IPriceFreezeExplanationModalOwnPropsForInfo =
    {
      showPriceFreezeFlightDetails: true,
      priceFreezeTitles: getPriceFreezeTitles({
        priceFreezeFiat,
        priceFreezeRewards,
        selectedRewardsAccountId: rewardsKey,
        priceFreezeCap,
        duration: priceFreezeDuration,
        useLockPriceLanguage,
      }),
      useLockPriceLanguage: useLockPriceLanguage,
    };

  const priceFreezeEntryInfoProps: IPriceFreezeEntryInfoOwnProps = {
    useLowestPriceText: true,
    fiatPrice: getTotalPriceText({
      price: priceFreezeFiat as FiatPrice,
    }),
    rewards: getPriceFreezeRewardsString(priceFreezeRewards, rewardsKey),
    duration: priceFreezeDuration,
    showLearnMore: true,
    disableTitleAsLink: true,
    removeInfoTooltip: true,
    highlightedRewards: false,
    useLockPriceLanguage: useLockPriceLanguage,
    priceFreezeFlightDetailsModalProps:
      priceFreezeFlightDetailsModalPropsForInfo,
    priceFreezeExplanationModalProps: PriceFreezeExplanationModalPropsForInfo,
    isFromFlightShopReviewItinerary: false,
    prediction: null,
  };

  const priceFreezeEntryButtonProps: IPriceFreezeEntryButtonOwnProps = {
    showPriceFreezeDetails: true,
    tripDetails: priceFreezeOfferCheapestTripTripDetails,
    fareId: priceFreezeOfferCheapestTripFareId,
    highlightedButton: true,
    useLockPriceLanguage: useLockPriceLanguage,
    showFreezeIcon: true,
    priceFreezeFlightDetailsModalProps:
      priceFreezeFlightDetailsModalPropsForButton,
    setCheapestEligibleOfferData: false,
  };

  return (
    <Box
      className={clsx(
        "flight-list",
        { "flight-list-skeleton": tripSummariesLoading },
        { mobile: matchesMobile },
        {
          "prediction-experiment": isInPredictionExperiment,
          "flight-list-optimization-experiment":
            isFlightListOptimizationExperiment,
          "global-mobile-nav": isGlobalMobileNavExperiment,
        }
      )}
    >
      {!matchesMobile &&
        flightShopType === FlightShopType.DEFAULT &&
        isAirPriceFreezeEnabled &&
        !isPriceFreezeCurrentEntryDisabled && (
          <PriceFreezeEntryBanner
            priceFreezeEntryButtonProps={priceFreezeEntryButtonProps}
            priceFreezeEntryInfoProps={priceFreezeEntryInfoProps}
            priceFreezeOffer={priceFreezeOffer}
            priceFreezeOfferCheapestTripTripDetails={
              priceFreezeOfferCheapestTripTripDetails
            }
            cheapestFrozenPrice={cheapestFrozenPrice}
            useLockPriceLanguage={useLockPriceLanguage}
          />
        )}
      {isHotelCrossSellV3Experiment && potentialCrossSellOffers.length > 0 ? (
        <HotelCrossSellAwarenessCard
          isMobile={matchesMobile}
          offerText={potentialCrossSellOffers[0].mainDescription}
          offerPillText={potentialCrossSellOffers[0].subDescription}
        />
      ) : undefined}
      {shouldApplyUserFlightPreferences &&
        userFlightPreferencesNotAvailable && (
          <UserPreferencesNotAvailableBanner type={"airline"} />
        )}
      {tripSummariesLoading && renderSkeletonFlights()}
      {!!flightsToShow.length &&
      !tripSummariesLoading &&
      !!flights &&
      isReadyToRenderFlights
        ? renderFlights()
        : null}
      {!tripSummariesLoading && !flightsToShow.length
        ? renderNoFlightsMessaging()
        : null}
      {!tripSummariesLoading &&
      flightsToRender.length === flightsToShow.length &&
      flightsToRender.length !== 0 &&
      (hasAppliedFareClassFilter || hasAppliedNonFareclassFilter) &&
      !isInDisruptionProtectionRebook ? (
        <FlightFindMoreResults />
      ) : null}
      <FlightAlgomerchModal
        selectedCategory={selectedAlgomerchTag}
        setSelectedCategory={setSelectedAlgomerchTag}
        openModal={openModal === ModalTypes.AlgomerchModal}
        onClose={() => setOpenModal(false)}
      />
      {
        // This is the current luggage check modal displayed on the flight list row
        openModal === ModalTypes.SelfCheckModal && (
          <VirtualInterliningModal
            onClose={() => setOpenModal(false)}
            isMobile={matchesMobile}
            variant="selfCheck"
            isOpen
          />
        )
      }
      {
        // This is the missed connection rebooking modal used in the gray-banner variant
        openModal === ModalTypes.MissedConnectionRebookingModal && (
          <VirtualInterliningModal
            onClose={() => setOpenModal(false)}
            isMobile={matchesMobile}
            variant="missedConnectionGuarantee"
            isOpen
          />
        )
      }
    </Box>
  );
};
