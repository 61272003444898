import {
  MulticityFlights,
  MulticityRoute,
  ShopMulticitySummaryRequest,
} from "@b2bportal/air-shopping-api";
import * as H from "history";
import {
  TripSummary,
  TripDetails,
  AirportRegion,
  Prediction,
  SliceStopCountFilter,
  CallState,
  FlightKey,
  WatchAlertView,
  OfferWithSuggested,
  Flights,
  FlightShopType,
  FLIGHT_SHOP_TYPE,
  PRICE_FREEZE_ID_QUERY_PARAM,
  GetSimilarFlightsResponse,
  AncillaryFetchOfferSuccess,
  TransferResponse,
  CfarId,
  ChfarId,
  MissedConnectionId,
  DelayId,
  TravelWalletOffer,
  AncillaryFetchOfferRequest,
  RefundableFaresProperties,
  ShopFilter,
  PushNotificationFilter,
  FlightSortOption,
  FareDetails,
  FlightEntryTypeEnum,
  TripCategory,
  FiatPrice,
  ShopSummaryRequest,
  Payment,
  TrackingProperties,
  PriceFreezeOfferDataWithRewards,
} from "redmond";
import { FareclassOptionFilter } from "../../search/reducer";
import {
  FlightShopStep,
  IFlightShopExperiments,
  MulticityFlightShopStep,
  ProductRedeemChoice,
} from "../reducer";
import * as actionTypes from "./constants";
import { ErrorCode } from "@b2bportal/air-price-watch-api";

// Getters
export interface IFetchTripSummaries {
  type: actionTypes.FETCH_TRIP_SUMMARIES;
  history: H.History;
  isMobile?: boolean;
}

export interface IFetchTripSummariesForPrediction {
  type: actionTypes.FETCH_TRIP_SUMMARIES_FOR_PREDICTION;
  history: H.History;
  isMobile?: boolean;
}

export interface IShopMulticityParams {
  routes: MulticityRoute[];
  adultsCount: number;
  childrenCount: number;
  infantsInSeatCount: number;
  infantsOnLapCount: number;
  stopsOption: SliceStopCountFilter;
  fareclassOptionFilter: FareclassOptionFilter;
}

export interface IShopParams {
  origin: AirportRegion;
  destination: AirportRegion;
  departureDate: string;
  returnDate?: string;
  adultsCount: number;
  childrenCount: number;
  infantsInSeatCount: number;
  infantsOnLapCount: number;
  stopsOption: SliceStopCountFilter;
  fareclassOptionFilter: FareclassOptionFilter;
  originLocationLabel?: string;
  destinationLocationLabel?: string;
  fareClass?: string[];
}

export const fetchTripSummaries = (
  history: H.History,
  isMobile?: boolean
): IFetchTripSummaries => ({
  type: actionTypes.FETCH_TRIP_SUMMARIES,
  history,
  isMobile,
});

export const fetchTripSummariesForPrediction = (
  history: H.History,
  isMobile?: boolean
): IFetchTripSummariesForPrediction => ({
  type: actionTypes.FETCH_TRIP_SUMMARIES_FOR_PREDICTION,
  history,
  isMobile,
});

export interface IFetchTripDetails {
  type: actionTypes.FETCH_TRIP_DETAILS;
  tripId: string;
  // For experiment "c1-price-freeze-new-flights-list-entry"
  priceFreezeOffersEnabled?: boolean;
  // note: filterFareDetail is used to filter fareDetails at the top level (saga)
  filterFareDetail?: (fare: FareDetails) => boolean;
}

export const fetchTripDetails = (args: {
  tripId: string;
  // For experiment "c1-price-freeze-new-flights-list-entry"
  priceFreezeOffersEnabled?: boolean;
  filterFareDetail?: (fare: FareDetails) => boolean;
}): IFetchTripDetails => ({
  type: actionTypes.FETCH_TRIP_DETAILS,
  ...args,
});

export interface IFetchAncillaryOffer {
  type: actionTypes.FETCH_ANCILLARY_OFFER;
  isPriceFreezeOverview?: boolean;
  request?: AncillaryFetchOfferRequest;
  preserveCfarId?: boolean;
  preserveChfarId?: boolean;
  preserveDisruptionProtectionId?: boolean;
}

export const fetchAncillaryOffer = (
  args?: Omit<IFetchAncillaryOffer, "type">
): IFetchAncillaryOffer => ({
  type: actionTypes.FETCH_ANCILLARY_OFFER,
  ...args,
});

export interface IFetchPriceFreezeOfferForFareDetails {
  type: actionTypes.FETCH_PRICE_FREEZE_OFFER_FOR_FARE_DETAILS;
  tripId: string;
  fareId: string;
}

export const fetchPriceFreezeOfferForFareDetails = ({
  tripId,
  fareId,
}: {
  tripId: string;
  fareId: string;
}): IFetchPriceFreezeOfferForFareDetails => ({
  type: actionTypes.FETCH_PRICE_FREEZE_OFFER_FOR_FARE_DETAILS,
  tripId,
  fareId,
});

// Setters
export interface ISetTripSummaries {
  type: actionTypes.SET_TRIP_SUMMARIES;
  tripSummaries: TripSummary[];
}

export const setTripSummaries = (
  tripSummaries: TripSummary[]
): ISetTripSummaries => ({
  type: actionTypes.SET_TRIP_SUMMARIES,
  tripSummaries,
});

export interface ISetTripSummariesError {
  type: actionTypes.SET_TRIP_SUMMARIES_ERROR;
  errorCode?: string;
}

export const setTripSummariesError = (
  errorCode?: string
): ISetTripSummariesError => ({
  type: actionTypes.SET_TRIP_SUMMARIES_ERROR,
  errorCode: errorCode,
});

export interface IRerunPrediction {
  type: actionTypes.RERUN_PREDICTION;
}

export const setRerunPrediction = (): IRerunPrediction => ({
  type: actionTypes.RERUN_PREDICTION,
});

export interface ISetPrediction {
  type: actionTypes.SET_PREDICTION;
  prediction: Prediction;
}

export const setPrediction = (prediction: Prediction): ISetPrediction => ({
  type: actionTypes.SET_PREDICTION,
  prediction,
});

export interface ISetPriceFreezeOffer {
  type: actionTypes.SET_PRICE_PRICE_FRREEZE_OFFER;
  payload: OfferWithSuggested;
}

export const setPriceFreezeOffer = (
  payload: OfferWithSuggested
): ISetPriceFreezeOffer => ({
  type: actionTypes.SET_PRICE_PRICE_FRREEZE_OFFER,
  payload,
});

export interface ISetBestOverallOffer {
  type: actionTypes.SET_BEST_OFFER_OVERALL;
  payload: TravelWalletOffer;
}

export const setBestOverallOffer = (offer: TravelWalletOffer) => ({
  type: actionTypes.SET_BEST_OFFER_OVERALL,
  payload: offer,
});

export interface ISetOffersByTripId {
  type: actionTypes.SET_OFFERS_BY_TRIP_ID;
  payload: { [key: string]: TravelWalletOffer };
}

export const setOffersByTripId = (offers: {
  [key: string]: TravelWalletOffer;
}) => ({
  type: actionTypes.SET_OFFERS_BY_TRIP_ID,
  payload: offers,
});

export interface ISetPredictionError {
  type: actionTypes.SET_PREDICTION_ERROR;
}

export const setPredictionError = (): ISetPredictionError => ({
  type: actionTypes.SET_PREDICTION_ERROR,
});

export interface ISetTripDetails {
  type: actionTypes.SET_TRIP_DETAILS;
  tripDetails: TripDetails;
}

export const setTripDetails = (tripDetails: TripDetails): ISetTripDetails => ({
  type: actionTypes.SET_TRIP_DETAILS,
  tripDetails,
});

export interface ISetPriceFreezeOfferOnFareDetails {
  type: actionTypes.SET_PRICE_FREEZE_OFFER_ON_FARE_DETAILS;
  tripId: string;
  fareId: string;
  priceFreezeOffers: PriceFreezeOfferDataWithRewards[];
  middleOffer: PriceFreezeOfferDataWithRewards;
}

export const setPriceFreezeOfferOnFareDetails = ({
  tripId,
  fareId,
  priceFreezeOffers,
  middleOffer,
}: {
  tripId: string;
  fareId: string;
  priceFreezeOffers: PriceFreezeOfferDataWithRewards[];
  middleOffer: PriceFreezeOfferDataWithRewards;
}) => ({
  type: actionTypes.SET_PRICE_FREEZE_OFFER_ON_FARE_DETAILS,
  tripId,
  fareId,
  priceFreezeOffers,
  middleOffer,
});

export interface ISetChosenOutgoingSliceParams {
  outgoingSliceId: string;
  outgoingFareId: string;
  tripId: string;
  outgoingFareRating?: number;
  resetReturnIds?: boolean;
}

export interface ISetChosenOutgoingSlice {
  type: actionTypes.SET_CHOSEN_OUTGOING_SLICE;
  outgoingSliceId: string;
  outgoingFareId: string;
  tripId: string;
  outgoingFareRating?: number;
  resetReturnIds?: boolean;
}

export const setChosenOutgoingSlice = ({
  outgoingSliceId,
  outgoingFareId,
  tripId,
  outgoingFareRating,
  resetReturnIds,
}: ISetChosenOutgoingSliceParams): ISetChosenOutgoingSlice => ({
  type: actionTypes.SET_CHOSEN_OUTGOING_SLICE,
  outgoingSliceId,
  outgoingFareId,
  tripId,
  outgoingFareRating,
  resetReturnIds,
});

export interface ISetChosenReturnSliceParams {
  tripId: string;
  returnSliceId: string;
  returnFareId: string;
  returnFareRating?: number;
}

export interface ISetChosenReturnSlice {
  type: actionTypes.SET_CHOSEN_RETURN_SLICE;
  tripId: string;
  returnFareId: string;
  returnSliceId: string;
  returnFareRating?: number;
}

export const setChosenReturnSlice = ({
  returnSliceId,
  returnFareId,
  returnFareRating,
  tripId,
}: ISetChosenReturnSliceParams): ISetChosenReturnSlice => ({
  type: actionTypes.SET_CHOSEN_RETURN_SLICE,
  returnFareId,
  returnSliceId,
  returnFareRating,
  tripId,
});

export interface ISetChosenDepartureSliceParams {
  departureSliceId: string;
  departureFareId: string;
  tripId: string;
  departureFareRating?: number;
  departureSliceIndex: number;
}

export interface ISetChosenDepartureSlice {
  type: actionTypes.SET_CHOSEN_DEPARTURE_SLICE;
  departureSliceId: string;
  departureFareId: string;
  tripId: string;
  departureFareRating?: number;
  departureSliceIndex: number;
}

export const setChosenDepartureSlice = ({
  departureSliceId,
  departureFareId,
  tripId,
  departureFareRating,
  departureSliceIndex,
}: ISetChosenDepartureSliceParams): ISetChosenDepartureSlice => ({
  type: actionTypes.SET_CHOSEN_DEPARTURE_SLICE,
  departureSliceId,
  departureFareId,
  tripId,
  departureFareRating,
  departureSliceIndex,
});

export interface ISetFlightShopProgress {
  type: actionTypes.SET_FLIGHT_SHOP_PROGRESS;
  progress: FlightShopStep;
}

export const setFlightShopProgress = (
  progress: FlightShopStep
): ISetFlightShopProgress => ({
  type: actionTypes.SET_FLIGHT_SHOP_PROGRESS,
  progress,
});

export interface ISetProductRedeemChoice {
  type: actionTypes.SET_PRODUCT_REDEEM_CHOICE;
  productRedeemChoice: ProductRedeemChoice;
}

export const setProductRedeemChoice = (
  productRedeemChoice: ProductRedeemChoice
): ISetProductRedeemChoice => ({
  type: actionTypes.SET_PRODUCT_REDEEM_CHOICE,
  productRedeemChoice,
});

export interface ISetMulticityFlightShopProgress {
  type: actionTypes.SET_MULTICITY_FLIGHT_SHOP_PROGRESS;
  progress: MulticityFlightShopStep;
}

export const setMulticityFlightShopProgress = (
  progress: MulticityFlightShopStep
): ISetMulticityFlightShopProgress => ({
  type: actionTypes.SET_MULTICITY_FLIGHT_SHOP_PROGRESS,
  progress,
});

export interface ISetSortOption {
  type: actionTypes.SET_SORT_OPTION;
  sortOption: FlightSortOption;
}

export const setSortOption = (
  sortOption: FlightSortOption
): ISetSortOption => ({
  type: actionTypes.SET_SORT_OPTION,
  sortOption,
});

export interface IFlightShopOverwriteQueryParams {
  [FLIGHT_SHOP_TYPE]?: FlightShopType;
  [PRICE_FREEZE_ID_QUERY_PARAM]?: string;
  flightShopProgress?: FlightShopStep;
  multicityFlightShopProgress?: MulticityFlightShopStep;
  tripCategory?: TripCategory;
  isFromFlightWatch?: boolean;
}

export interface IPopulateFlightShopQueryParams {
  type: actionTypes.POPULATE_FLIGHT_SHOP_QUERY_PARAMS;
  history: H.History;
  prevPath?: string;
  useHistoryPush?: boolean;
  // note: this prop is used to bypass stateDiffersFromQueryParams
  forceQueryUpdate?: boolean;
  newQueryParams?: IFlightShopOverwriteQueryParams;
}

export const populateFlightShopQueryParams = (args: {
  history: H.History;
  prevPath?: string;
  useHistoryPush?: boolean;
  forceQueryUpdate?: boolean;
  newQueryParams?: IFlightShopOverwriteQueryParams;
}): IPopulateFlightShopQueryParams => ({
  type: actionTypes.POPULATE_FLIGHT_SHOP_QUERY_PARAMS,
  ...args,
});

export interface ICreateWatch {
  type: actionTypes.CREATE_WATCH;
  email: string;
  shopFilter?: ShopFilter;
  pushNotificationFilter?: PushNotificationFilter;
  isMobile?: boolean;
}

export const createWatch = (args: {
  email: string;
  shopFilter?: ShopFilter;
  pushNotificationFilter?: PushNotificationFilter;
  isMobile?: boolean;
}): ICreateWatch => ({
  type: actionTypes.CREATE_WATCH,
  ...args,
});

export interface ISetCreateWatchCallState {
  type: actionTypes.SET_CREATE_WATCH_CALL_STATE;
  callState: CallState;
  failureCodes?: ErrorCode[];
}

export const setCreateWatchCallState = (
  callState: CallState,
  failureCodes?: ErrorCode[]
): ISetCreateWatchCallState => ({
  type: actionTypes.SET_CREATE_WATCH_CALL_STATE,
  callState,
  failureCodes,
});

export interface IDeleteWatch {
  type: actionTypes.DELETE_WATCH;
}

export const deleteWatch = (): IDeleteWatch => ({
  type: actionTypes.DELETE_WATCH,
});

export interface ISetDeleteWatchCallState {
  type: actionTypes.SET_DELETE_WATCH_CALL_STATE;
  callState: CallState;
}

export const setDeleteWatchCallState = (
  callState: CallState
): ISetDeleteWatchCallState => ({
  type: actionTypes.SET_DELETE_WATCH_CALL_STATE,
  callState,
});

export interface IUpdateWatch {
  type: actionTypes.UPDATE_WATCH;
  alertKey: FlightKey;
  email: string;
}

export const updateWatch = (
  alertKey: FlightKey,
  email: string
): IUpdateWatch => ({
  type: actionTypes.UPDATE_WATCH,
  alertKey,
  email,
});

export interface ISetUpdateWatchCallState {
  type: actionTypes.SET_UPDATE_WATCH_CALL_STATE;
  callState: CallState;
}

export const setUpdateWatchCallState = (
  callState: CallState
): ISetUpdateWatchCallState => ({
  type: actionTypes.SET_UPDATE_WATCH_CALL_STATE,
  callState,
});

export interface IListWatches {
  type: actionTypes.LIST_WATCHES;
}

export const listWatches = (): IListWatches => ({
  type: actionTypes.LIST_WATCHES,
});

export interface ISetListWatchesCallState {
  type: actionTypes.SET_LIST_WATCHES_CALL_STATE;
  callState: CallState;
}

export const setListWatchesCallState = (
  callState: CallState
): ISetListWatchesCallState => ({
  type: actionTypes.SET_LIST_WATCHES_CALL_STATE,
  callState,
});

export interface ISetWatches {
  type: actionTypes.SET_WATCHES;
  watches: WatchAlertView[];
}

export const setWatches = (watches: WatchAlertView[]): ISetWatches => ({
  type: actionTypes.SET_WATCHES,
  watches,
});

export interface ISetOpenFlightShopCalendarDesktop {
  type: actionTypes.SET_OPEN_FLIGHT_SHOP_CALENDAR_DESKTOP;
  openFlightShopCalendarDesktop: boolean;
}

export const setOpenFlightShopCalendarDesktop = (
  openFlightShopCalendarDesktop: boolean
): ISetOpenFlightShopCalendarDesktop => ({
  type: actionTypes.SET_OPEN_FLIGHT_SHOP_CALENDAR_DESKTOP,
  openFlightShopCalendarDesktop,
});

export interface ISetOpenFlightShopCalendarMobile {
  type: actionTypes.SET_OPEN_FLIGHT_SHOP_CALENDAR_MOBILE;
  openFlightShopCalendarMobile: boolean;
}

export const setOpenFlightShopCalendarMobile = (
  openFlightShopCalendarMobile: boolean
): ISetOpenFlightShopCalendarMobile => ({
  type: actionTypes.SET_OPEN_FLIGHT_SHOP_CALENDAR_MOBILE,
  openFlightShopCalendarMobile,
});

export interface ISetFlightShopType {
  type: actionTypes.SET_FLIGHT_SHOP_TYPE;
  flightShopType: FlightShopType;
}

export const setFlightShopType = (
  flightShopType: FlightShopType
): ISetFlightShopType => ({
  type: actionTypes.SET_FLIGHT_SHOP_TYPE,
  flightShopType,
});

export interface IFetchSimilarFlights {
  type: actionTypes.FETCH_SIMILAR_FLIGHTS;
  history?: H.History;
  id: string;
  refreshPriceFreeze?: boolean;
}

export const fetchSimilarFlights = (args: {
  history?: H.History;
  id: string;
  refreshPriceFreeze?: boolean;
}): IFetchSimilarFlights => {
  const { refreshPriceFreeze = true } = args;

  return {
    type: actionTypes.FETCH_SIMILAR_FLIGHTS,
    ...args,
    refreshPriceFreeze,
  };
};

export interface ISetSimilarFlightsResponse {
  type: actionTypes.SET_SIMILAR_FLIGHTS_RESPONSE;
  response: Omit<GetSimilarFlightsResponse, "trips">;
  callState: CallState;
}

export const setSimilarFlightsResponse = (args: {
  response: Omit<GetSimilarFlightsResponse, "trips">;
  callState: CallState;
}): ISetSimilarFlightsResponse => ({
  type: actionTypes.SET_SIMILAR_FLIGHTS_RESPONSE,
  ...args,
});

export interface ISetFetchSimilarFlightsCallStateFailed {
  type: actionTypes.SET_FETCH_SIMILAR_FLIGHTS_CALL_STATE_FAILED;
}

export const setFetchSimilarFlightsCallStateFailed =
  (): ISetFetchSimilarFlightsCallStateFailed => ({
    type: actionTypes.SET_FETCH_SIMILAR_FLIGHTS_CALL_STATE_FAILED,
  });

export interface IFetchTransferToSimilarFlights {
  type: actionTypes.FETCH_TRANSFER_TO_SIMILAR_FLIGHTS;
  history: H.History;
  id: string;
  tripId: string;
  fareId: string;
}

export const fetchTransferToSimilarFlights = (args: {
  history: H.History;
  id: string;
  tripId: string;
  fareId: string;
}): IFetchTransferToSimilarFlights => ({
  type: actionTypes.FETCH_TRANSFER_TO_SIMILAR_FLIGHTS,
  ...args,
});

export interface ISetTransferToSimilarFlightsResponse {
  type: actionTypes.SET_TRANSFER_TO_SIMILAR_FLIGHTS_RESPONSE;
  response: TransferResponse;
  callState: CallState;
}

export const setTransferToSimilarFlightsResponse = (args: {
  response: TransferResponse;
  callState: CallState;
}): ISetTransferToSimilarFlightsResponse => ({
  type: actionTypes.SET_TRANSFER_TO_SIMILAR_FLIGHTS_RESPONSE,
  ...args,
});

export interface ISetFetchTransferToSimilarFlightsCallStateFailed {
  type: actionTypes.SET_FETCH_TRANSFER_TO_SIMILAR_FLIGHTS_CALL_STATE_FAILED;
}

export const setFetchTransferToSimilarFlightsCallStateFailed =
  (): ISetFetchTransferToSimilarFlightsCallStateFailed => ({
    type: actionTypes.SET_FETCH_TRANSFER_TO_SIMILAR_FLIGHTS_CALL_STATE_FAILED,
  });

export interface ISetFetchTransferToSimilarFlightsCallStateNotCalled {
  type: actionTypes.SET_FETCH_TRANSFER_TO_SIMILAR_FLIGHTS_CALL_STATE_NOT_CALLED;
}

export const setFetchTransferToSimilarFlightsCallStateNotCalled =
  (): ISetFetchTransferToSimilarFlightsCallStateNotCalled => ({
    type: actionTypes.SET_FETCH_TRANSFER_TO_SIMILAR_FLIGHTS_CALL_STATE_NOT_CALLED,
  });

export interface IResetSelectedTrip {
  type: actionTypes.RESET_SELECTED_TRIP;
}

export const resetSelectedTrip = (): IResetSelectedTrip => ({
  type: actionTypes.RESET_SELECTED_TRIP,
});

export interface ISetFetchAncillaryOfferResponse {
  type: actionTypes.SET_FETCH_ANCILLARY_OFFER_RESPONSE;
  response: AncillaryFetchOfferSuccess;
}

export const setFetchAncillaryOfferResponse = (
  response: AncillaryFetchOfferSuccess
): ISetFetchAncillaryOfferResponse => ({
  type: actionTypes.SET_FETCH_ANCILLARY_OFFER_RESPONSE,
  response,
});

export interface ISetFetchAncillaryOfferCallStateFailed {
  type: actionTypes.SET_FETCH_ANCILLARY_OFFER_CALL_STATE_FAILED;
}

export const setFetchAncillaryOfferCallStateFailed =
  (): ISetFetchAncillaryOfferCallStateFailed => ({
    type: actionTypes.SET_FETCH_ANCILLARY_OFFER_CALL_STATE_FAILED,
  });

export interface IResetFetchAncillaryOfferState {
  type: actionTypes.RESET_FETCH_ANCILLARY_OFFER_STATE;
  preserveCfarId?: boolean;
  preserveChfarId?: boolean;
  preserveDisruptionProtectionId?: boolean;
}

export const resetFetchAncillaryOfferState = (
  args?: Omit<IResetFetchAncillaryOfferState, "type">
): IResetFetchAncillaryOfferState => ({
  type: actionTypes.RESET_FETCH_ANCILLARY_OFFER_STATE,
  ...args,
});

export interface ISetSelectedFlightIndex {
  type: actionTypes.SET_SELECTED_FLIGHT_INDEX;
  selectedFlightIndex: number;
}

export const setSelectedFlightIndex = (
  selectedFlightIndex: number
): ISetSelectedFlightIndex => ({
  type: actionTypes.SET_SELECTED_FLIGHT_INDEX,
  selectedFlightIndex,
});

export interface ISetSelectedCfarId {
  type: actionTypes.SET_SELECTED_CFAR_ID;
  cfarId: CfarId | null;
}

export const setSelectedCfarId = (
  cfarId: CfarId | null
): ISetSelectedCfarId => ({
  type: actionTypes.SET_SELECTED_CFAR_ID,
  cfarId,
});

export interface ISetSelectedChfarId {
  type: actionTypes.SET_SELECTED_CHFAR_ID;
  chfarId: ChfarId | null;
}
export const setSelectedChfarId = (
  chfarId: ChfarId | null
): ISetSelectedChfarId => ({
  type: actionTypes.SET_SELECTED_CHFAR_ID,
  chfarId,
});

export interface ISetSkipChfarTrackingProperties {
  type: actionTypes.SET_SKIP_CHFAR_TRACKING_PROPERTIES;
  trackingProperties: TrackingProperties;
}

export const setSkipChfarTrackingProperties = (
  trackingProperties: TrackingProperties
): ISetSkipChfarTrackingProperties => ({
  type: actionTypes.SET_SKIP_CHFAR_TRACKING_PROPERTIES,
  trackingProperties,
});

export interface ISetHasSelectedRefundableFare {
  type: actionTypes.SET_HAS_SELECTED_REFUNDABLE_FARE;
  hasSelectedRefundableFare: boolean;
}

export const setHasSelectedRefundableFare = (
  hasSelectedRefundableFare: boolean
): ISetHasSelectedRefundableFare => ({
  type: actionTypes.SET_HAS_SELECTED_REFUNDABLE_FARE,
  hasSelectedRefundableFare,
});

export interface IUpdateRefundableFaresProperties {
  type: actionTypes.UPDATE_REFUNDABLE_FARES_PROPERTIES;
  properties: RefundableFaresProperties;
}

export const updateRefundableFaresProperties = (
  properties: RefundableFaresProperties
): IUpdateRefundableFaresProperties => ({
  type: actionTypes.UPDATE_REFUNDABLE_FARES_PROPERTIES,
  properties,
});

export interface IResetRefundableFaresProperties {
  type: actionTypes.RESET_REFUNDABLE_FARES_PROPERTIES;
}

export const resetRefundableFaresProperties =
  (): IResetRefundableFaresProperties => ({
    type: actionTypes.RESET_REFUNDABLE_FARES_PROPERTIES,
  });

export interface ISetSelectedDisruptionProtectionId {
  type: actionTypes.SET_SELECTED_DISRUPTION_PROTECTION_ID;
  disruptionProtectionId: MissedConnectionId | DelayId | null;
}

export const setSelectedDisruptionProtectionId = (
  disruptionProtectionId: MissedConnectionId | DelayId | null
): ISetSelectedDisruptionProtectionId => ({
  type: actionTypes.SET_SELECTED_DISRUPTION_PROTECTION_ID,
  disruptionProtectionId,
});

export interface ISetHasPriceFreezeOnOutbound {
  type: actionTypes.SET_HAS_PRICE_FREEZE_ON_OUTBOUND;
  hasPriceFreezeOnOutbound: boolean;
}

export const setHasPriceFreezeOnOutbound = (
  hasPriceFreezeOnOutbound: boolean
): ISetHasPriceFreezeOnOutbound => ({
  type: actionTypes.SET_HAS_PRICE_FREEZE_ON_OUTBOUND,
  hasPriceFreezeOnOutbound,
});

export interface ISetFlightShopRecommendedBasedOnPreferences {
  type: actionTypes.SET_FLIGHT_SHOP_RECOMMENDED_BASED_ON_PREFERENCES;
  recommendedBasedOnPreferences: boolean;
}

export const setFlightShopRecommendedBasedOnPreferences = (
  recommendedBasedOnPreferences: boolean
): ISetFlightShopRecommendedBasedOnPreferences => ({
  type: actionTypes.SET_FLIGHT_SHOP_RECOMMENDED_BASED_ON_PREFERENCES,
  recommendedBasedOnPreferences,
});

export interface ISetSelectedMarketingAirlineCodes {
  type: actionTypes.SET_SELECTED_MARKETING_AIRLINE_CODES;
  marketingAirlineCodes: string[];
}

export const setSelectedMarketingAirlineCodes = (
  marketingAirlineCodes: string[]
): ISetSelectedMarketingAirlineCodes => ({
  type: actionTypes.SET_SELECTED_MARKETING_AIRLINE_CODES,
  marketingAirlineCodes,
});

export interface ISetSelectedOperatingAirlineCodes {
  type: actionTypes.SET_SELECTED_OPERATING_AIRLINE_CODES;
  operatingAirlineCodes: string[];
}

export const setSelectedOperatingAirlineCodes = (
  operatingAirlineCodes: string[]
): ISetSelectedOperatingAirlineCodes => ({
  type: actionTypes.SET_SELECTED_OPERATING_AIRLINE_CODES,
  operatingAirlineCodes,
});

export type FlightShopActions =
  | IFetchTripSummaries
  | IFetchTripSummariesForPrediction
  | IFetchTripDetails
  | ISetPredictionError
  | ISetPrediction
  | IRerunPrediction
  | ISetTripSummaries
  | ISetTripSummariesError
  | ISetTripDetails
  | ISetPriceFreezeOfferOnFareDetails
  | ISetChosenOutgoingSlice
  | ISetChosenReturnSlice
  | ISetChosenDepartureSlice
  | ISetFlightShopProgress
  | ISetMulticityFlightShopProgress
  | ISetSortOption
  | ICreateWatch
  | ISetCreateWatchCallState
  | IListWatches
  | ISetListWatchesCallState
  | IUpdateWatch
  | ISetUpdateWatchCallState
  | IDeleteWatch
  | ISetDeleteWatchCallState
  | ISetWatches
  | ISetOpenFlightShopCalendarDesktop
  | ISetOpenFlightShopCalendarMobile
  | ISetPriceFreezeOffer
  | IFetchSimilarFlights
  | ISetSimilarFlightsResponse
  | ISetFetchSimilarFlightsCallStateFailed
  | IFetchTransferToSimilarFlights
  | ISetTransferToSimilarFlightsResponse
  | ISetFetchTransferToSimilarFlightsCallStateFailed
  | ISetFetchTransferToSimilarFlightsCallStateNotCalled
  | IFetchAncillaryOffer
  | ISetFetchAncillaryOfferResponse
  | ISetFetchAncillaryOfferCallStateFailed
  | IResetFetchAncillaryOfferState
  | ISetSelectedCfarId
  | ISetSelectedChfarId
  | ISetSkipChfarTrackingProperties
  | ISetHasSelectedRefundableFare
  | IUpdateRefundableFaresProperties
  | IResetRefundableFaresProperties
  | ISetSelectedDisruptionProtectionId
  | ISetHasPriceFreezeOnOutbound
  | ISetFlightShopType
  | IResetSelectedTrip
  | ISetFlights
  | ISetMulticityFlights
  | ISetSelectedFlightIndex
  | IFetchTripSummariesV2
  | IFetchTripSummariesForPredictionV2
  | IFetchTripSummariesV3
  | IStopFetchTripSummariesV3
  | IFetchMulticityTripSummaries
  | IStopFetchMulticityTripSummaries
  | ISetBestOverallOffer
  | ISetOffersByTripId
  | ISetFlightShopExperiments
  | ISetProductRedeemChoice
  | ISetFlightShopEntryPoint
  | ISetPolicyLimit
  | ISetShopRequest
  | ISetFlightShopRecommendedBasedOnPreferences
  | ISetSelectedMarketingAirlineCodes
  | ISetSelectedOperatingAirlineCodes
  | IListPaymentMethods
  | ISetPaymentMethods
  | ISetPaymentMethodsCallStateFailed
  | IFetchCorpFintechEligibility
  | ISetCorpFintechEligibility;

// ===== shop v2

export interface IFetchTripSummariesV2 {
  type: actionTypes.FETCH_TRIP_SUMMARIES_V2;
  history: H.History;
  isMobile?: boolean;
}

export interface IFetchTripSummariesForPredictionV2 {
  type: actionTypes.FETCH_TRIP_SUMMARIES_FOR_PREDICTION_V2;
  history: H.History;
  isMobile?: boolean;
}

export const fetchTripSummariesV2 = (
  history: H.History,
  isMobile?: boolean
): IFetchTripSummariesV2 => ({
  type: actionTypes.FETCH_TRIP_SUMMARIES_V2,
  history,
  isMobile,
});

export const fetchTripSummariesForPredictionV2 = (
  history: H.History,
  isMobile?: boolean
): IFetchTripSummariesForPredictionV2 => ({
  type: actionTypes.FETCH_TRIP_SUMMARIES_FOR_PREDICTION_V2,
  history,
  isMobile,
});

// ===== shop v3

export interface IFetchTripSummariesV3 {
  type: actionTypes.FETCH_TRIP_SUMMARIES_V3;
  history: H.History;
  isMobile?: boolean;
  includeLocationLabels?: boolean;
  fareClass?: string[];
  includeStopsFilter?: boolean;
  trackNonStopFilter?: boolean;
}

export const fetchTripSummariesV3 = (
  history: H.History,
  isMobile?: boolean,
  includeLocationLabels?: boolean,
  includeStopsFilter?: boolean,
  trackNonStopFilter?: boolean
): IFetchTripSummariesV3 => ({
  type: actionTypes.FETCH_TRIP_SUMMARIES_V3,
  history,
  isMobile,
  includeLocationLabels,
  includeStopsFilter,
  trackNonStopFilter,
});

export interface IStopFetchTripSummariesV3 {
  type: actionTypes.STOP_FETCH_TRIP_SUMMARIES_V3;
}

export const stopFetchTripSummariesV3 = (): IStopFetchTripSummariesV3 => ({
  type: actionTypes.STOP_FETCH_TRIP_SUMMARIES_V3,
});

// ===== multicity shop

export interface IFetchMulticityTripSummaries {
  type: actionTypes.FETCH_MULTICITY_TRIP_SUMMARIES;
  history: H.History;
  isMobile?: boolean;
}

export const fetchMulticitySummaries = (
  history: H.History,
  isMobile?: boolean
): IFetchMulticityTripSummaries => ({
  type: actionTypes.FETCH_MULTICITY_TRIP_SUMMARIES,
  history,
  isMobile,
});

export interface IStopFetchMulticityTripSummaries {
  type: actionTypes.STOP_FETCH_MULTICITY_TRIP_SUMMARIES;
}

export const stopFetchMulticityTripSummaries =
  (): IStopFetchMulticityTripSummaries => ({
    type: actionTypes.STOP_FETCH_MULTICITY_TRIP_SUMMARIES,
  });

//TODO: Types
export interface ISetFlights {
  type: actionTypes.SET_FLIGHTS;
  flights: Flights;
}

export const setFlights = (flights: any): ISetFlights => ({
  type: actionTypes.SET_FLIGHTS,
  flights,
});

export interface ISetMulticityFlights {
  type: actionTypes.SET_MULTICITY_FLIGHTS;
  flights: MulticityFlights;
}

export const setMulticityFlights = (flights: any): ISetMulticityFlights => ({
  type: actionTypes.SET_MULTICITY_FLIGHTS,
  flights,
});

export interface ISetFlightShopExperiments {
  type: actionTypes.SET_FLIGHT_SHOP_EXPERIMENTS;
  experiments: IFlightShopExperiments;
}

export const setFlightShopExperiments = (
  experiments: IFlightShopExperiments
): ISetFlightShopExperiments => ({
  type: actionTypes.SET_FLIGHT_SHOP_EXPERIMENTS,
  experiments,
});

export interface ISetFlightShopEntryPoint {
  type: actionTypes.SET_FLIGHT_SHOP_ENTRY_POINT;
  entryPoint?: FlightEntryTypeEnum;
}

export const setFlightShopEntryPoint = (
  entryPoint?: FlightEntryTypeEnum
): ISetFlightShopEntryPoint => ({
  type: actionTypes.SET_FLIGHT_SHOP_ENTRY_POINT,
  entryPoint,
});

export const listPaymentMethods = (): IListPaymentMethods => ({
  type: actionTypes.LIST_PAYMENT_METHODS,
});

export interface IListPaymentMethods {
  type: actionTypes.LIST_PAYMENT_METHODS;
}

export const setPaymentMethods = (args: {
  paymentMethods: Payment[];
}): ISetPaymentMethods => ({
  type: actionTypes.SET_PAYMENT_METHODS,
  ...args,
});

export interface ISetPaymentMethods {
  type: actionTypes.SET_PAYMENT_METHODS;
  paymentMethods: Payment[];
}

export const setPaymentMethodsCallStateFailed =
  (): ISetPaymentMethodsCallStateFailed => ({
    type: actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED,
  });

export interface ISetPaymentMethodsCallStateFailed {
  type: actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED;
}

// Corporate Travel

export interface ISetPolicyLimit {
  type: actionTypes.SET_POLICY_LIMIT;
  policyLimit: FiatPrice | null | undefined;
}

export const setPolicyLimit = (
  policyLimit: FiatPrice | null | undefined
): ISetPolicyLimit => ({
  type: actionTypes.SET_POLICY_LIMIT,
  policyLimit,
});

export interface ISetShopRequest {
  type: actionTypes.SET_SHOP_REQUEST;
  shopRequest: ShopSummaryRequest | ShopMulticitySummaryRequest;
}

export const setShopRequest = (
  shopRequest: ShopSummaryRequest | ShopMulticitySummaryRequest
): ISetShopRequest => ({
  type: actionTypes.SET_SHOP_REQUEST,
  shopRequest,
});

export interface IFetchCorpFintechEligibility {
  type: actionTypes.FETCH_CORP_FINTECH_ELIGIBILITY;
  businessId: string;
}

export const fetchCorpFintechEligibility = (
  businessId: string
): IFetchCorpFintechEligibility => ({
  type: actionTypes.FETCH_CORP_FINTECH_ELIGIBILITY,
  businessId,
});

export interface ISetCorpFintechEligibility {
  type: actionTypes.SET_CORP_FINTECH_ELIGIBILITY;
  isEligibleToOptin: boolean;
}

export const setCorpFintechEligibility = (
  isEligibleToOptin: boolean
): ISetCorpFintechEligibility => ({
  type: actionTypes.SET_CORP_FINTECH_ELIGIBILITY,
  isEligibleToOptin,
});
