import React from "react";
import { RouteComponentProps } from "react-router";
import { Box, Typography } from "@material-ui/core";
import {
  FiatPrice,
  TripCategory,
  PriceDropProtectionEnum,
  VIEWED_PRICE_DROP_DETAILS,
} from "redmond";
import {
  Icon,
  IconName,
  ActionButton,
  getTotalPriceText,
  getRewardsString,
  twoDecimalFormatter,
  tripTypeText,
} from "halifax";
import clsx from "clsx";
import { PriceDropProtection as PriceDropProtectionDetails } from "../../../../book/components/PriceDropProtection";
import { trackEvent } from "../../../../../api/v0/analytics/trackEvent";
import * as constants from "./constants";
import { AddOnPricingBreakdownConnectorProps } from "./container";
import "./styles.scss";
import { CORP_FINTECH_SUBSCRIPTION_KEY } from "../../../reducer";
import { getCorpFintechSubscriptionPrices } from "../AddOnCustomize/constants";

export interface IAddOnPricingBreakdownProps
  extends AddOnPricingBreakdownConnectorProps,
    RouteComponentProps {
  isPriceFreezeExercise?: boolean;
  actionButtonProps?: {
    message: string | JSX.Element;
    disabled?: boolean;
    onContinue: () => void;
    onClickWhenDisabled?: () => void;
  };
  isMobile?: boolean;
  hasError?: boolean;
}

export const AddOnPricingBreakdown = (props: IAddOnPricingBreakdownProps) => {
  const {
    rewardsKey,
    cfarOfferPrices,
    chfarOfferPrices,
    discountedCfarOffer,
    disruptionProtectionOfferPrices,
    actionButtonProps,
    isMobile,
    isRapidRebookRenameEnabled,
    isAirOfferRedesignEnabled,
    hasError,
    cfarOfferCoverage,
    isCfarCoMerchEnabled,
    selectedDisruptionProtectionId,
    priceDropRefundType,
  } = props;

  const { selectedFareAmount, totalPrices, tripCategory, priceDropProtection } =
    getFareDetails(props);

  const totalPriceOrRewards = totalPrices
    ? constants.getPriceAndRewardsCopy({
        price: getTotalPriceText({
          price: totalPrices.fiat,
          priceFormatter: twoDecimalFormatter,
        }),
        rewards:
          rewardsKey && totalPrices.rewards
            ? getRewardsString(totalPrices.rewards)
            : undefined,
        separator: constants.OR_SEPARATOR,
      })
    : "";

  const flightType = constants.getPerTravelerCopy(tripTypeText[tripCategory]);

  return (
    <Box
      className={clsx("add-on-pricing-breakdown-root", { mobile: isMobile })}
    >
      <Box className="add-on-pricing-breakdown-container">
        <Box className="checkout-breakdown-header-section">
          <Typography className="header-copy" variant="h2">
            {constants.CHECKOUT_BREAKDOWN_HEADER}
          </Typography>
        </Box>
        <Box className="pricing-breakdown-section">
          {/* TODO: investigate using packages/cap1-components/src/PriceBreakdown/component.tsx here to reduce duplication [FLEX-4600] */}
          <PricingItems pricingItems={buildPricingItems()} />
        </Box>
        {priceDropProtection &&
          priceDropProtection?.PriceDropProtection ===
            PriceDropProtectionEnum.IsEligible && (
            <PriceDropProtectionDetails
              priceDropProtection={priceDropProtection}
              onClick={() =>
                trackEvent({
                  eventName: VIEWED_PRICE_DROP_DETAILS,
                  properties: {
                    page: "customize",
                    refund_type: priceDropRefundType,
                  },
                })
              }
              hideSubtitle={true}
            />
          )}
        <Box className="total-price-section">
          <Typography
            variant="body2"
            className="total-price-copy"
            dangerouslySetInnerHTML={{
              __html: totalPriceOrRewards,
            }}
          />
          <Typography
            variant="body2"
            className="flight-type-copy"
            dangerouslySetInnerHTML={{
              __html: flightType,
            }}
          />
        </Box>
        {!!actionButtonProps && (
          <Box className="continue-cta-section">
            <ActionButton
              className="continue-cta"
              defaultStyle="h4r-primary"
              onClick={actionButtonProps.onContinue}
              onClickWhenDisabled={actionButtonProps.onClickWhenDisabled}
              message={actionButtonProps.message}
              disabled={actionButtonProps.disabled}
            />
          </Box>
        )}
        {isAirOfferRedesignEnabled && actionButtonProps?.disabled && (
          <div
            className={clsx("air-offer-redesign-validation-text", {
              hasError: hasError,
            })}
          >
            {constants.VALIDATION_TEXT}
          </div>
        )}
      </Box>
    </Box>
  );

  function buildPricingItems(): PricingItemProps[] {
    const pricingItems: PricingItemProps[] = [];

    const baseFare = getFormattedTotalPriceText(
      selectedFareAmount?.baseAmount?.fiat
    );

    if (baseFare) {
      pricingItems.push({
        title: constants.BASE_FARE_COPY,
        price: baseFare,
      });
    }

    const taxesFees = getFormattedTotalPriceText(
      selectedFareAmount?.taxAmount?.fiat
    );

    if (taxesFees) {
      pricingItems.push({
        title: constants.TAXES_FEES_COPY,
        price: taxesFees,
      });
    }

    const isCorpFintechSubscription =
      selectedDisruptionProtectionId?.productId ===
        CORP_FINTECH_SUBSCRIPTION_KEY && disruptionProtectionOfferPrices;

    const disruptionProtectionFormattedTotalPriceText =
      getFormattedTotalPriceText(disruptionProtectionOfferPrices?.fiat);

    if (
      disruptionProtectionFormattedTotalPriceText &&
      !isCorpFintechSubscription
    ) {
      pricingItems.push({
        title: isRapidRebookRenameEnabled
          ? constants.DISRUPTION_PROTECTION_COPY_NEW
          : constants.DISRUPTION_PROTECTION_COPY_OLD,
        price: disruptionProtectionFormattedTotalPriceText,
        icon: IconName.DisruptionProtectionBlue,
      });
    }

    if (isCorpFintechSubscription) {
      pricingItems.push({
        title: isRapidRebookRenameEnabled
          ? constants.DISRUPTION_PROTECTION_COPY_NEW
          : constants.DISRUPTION_PROTECTION_COPY_OLD,
        priceJSX: (
          <CorpFintechSubscriptionPrice
            price={disruptionProtectionOfferPrices?.fiat}
          />
        ),
        icon: IconName.DisruptionProtectionBlue,
      });
    }

    const cfarFormattedTotalPriceText = getFormattedTotalPriceText(
      cfarOfferPrices?.fiat
    );
    if (cfarFormattedTotalPriceText) {
      pricingItems.push({
        title: isCfarCoMerchEnabled
          ? `${constants.CFAR_COPY}, ${cfarOfferCoverage?.cashCoveragePercentage}% coverage`
          : constants.CFAR_COPY,
        price: cfarFormattedTotalPriceText,
        icon: IconName.CheckShieldBlue,
        priceJSX: discountedCfarOffer ? (
          <>
            <span className="cross-out">
              {getFormattedTotalPriceText(
                discountedCfarOffer.originalPremiumAmount.fiat
              )}
            </span>{" "}
            <span className="green-text">{cfarFormattedTotalPriceText}</span>
          </>
        ) : undefined,
      });
    }

    const chfarFormattedTotalPriceText = getFormattedTotalPriceText(
      chfarOfferPrices?.fiat
    );
    if (chfarFormattedTotalPriceText) {
      pricingItems.push({
        title: constants.CHFAR_COPY,
        price: chfarFormattedTotalPriceText,
        icon: IconName.Reload,
      });
    }

    return pricingItems;
  }
};

function getFareDetails(props: IAddOnPricingBreakdownProps) {
  if (props.isPriceFreezeExercise) {
    return {
      selectedFareAmount: props.priceFreezeChargeAmount
        ? { baseAmount: props.priceFreezeChargeAmount }
        : undefined,
      tripCategory: props.tripCategoryFromPriceFreeze ?? TripCategory.ONE_WAY,
      totalPrices: props.priceFreezeTotalPrices,
      priceDropProtection: props.priceDropProtectionFromPriceFreeze,
    };
  }

  return {
    selectedFareAmount: props.selectedFare
      ? {
          baseAmount: props.selectedFare?.paxPricings?.[0]?.pricing?.baseAmount,
          taxAmount: props.selectedFare?.paxPricings?.[0]?.pricing?.taxAmount,
        }
      : undefined,
    tripCategory: props.tripCategory,
    totalPrices: props.totalPrices,
    priceDropProtection: props.prediction?.priceDropProtection,
  };
}

const PricingItems = (props: { pricingItems: PricingItemProps[] }) => {
  return <>{props.pricingItems.map((item) => PricingItem(item))}</>;
};

interface PricingItemProps {
  title: string;
  price?: string;
  priceJSX?: JSX.Element;
  icon?: IconName;
  className?: string;
}

const PricingItem = (props: PricingItemProps) => {
  const { title, price, icon, className, priceJSX } = props;
  return (
    <Box className={clsx("pricing-item-wrapper", className)} key={title}>
      <Box className="pricing-item-label">
        {icon && (
          <Box className="pricing-item-icon-wrapper">
            <Icon className="pricing-item-icon" name={icon} />
          </Box>
        )}
        <Typography variant="body2" className="pricing-item-copy">
          {title}
        </Typography>
      </Box>
      <Typography variant="body2" className="pricing-copy">
        {priceJSX ?? price}
      </Typography>
    </Box>
  );
};

const getFormattedTotalPriceText = (fiat: FiatPrice | undefined) => {
  if (fiat) {
    return getTotalPriceText({
      price: fiat,
      priceFormatter: twoDecimalFormatter,
    });
  }
  return "";
};

const CorpFintechSubscriptionPrice = ({ price }: { price: FiatPrice }) => {
  const { origPrice, discountedPrice } =
    getCorpFintechSubscriptionPrices(price);
  return (
    <>
      <del>{origPrice}</del>{" "}
      <span className="green-text">{discountedPrice}</span>
    </>
  );
};
