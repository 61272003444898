import React from "react";
import { Box } from "@material-ui/core";
import { ActionLink } from "halifax";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { RouteComponentProps } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import "./styles.scss";
import { MulticityFlightShopProgressBarConnectorProps } from "./container";
import { ISelectedMulticityTrip, MulticityFlightShopStep } from "../../reducer";
import * as constants from "./constants";
import { MulticityRoute } from "../../../search/reducer";

interface IMulticityFlightShopProgressBarProps
  extends MulticityFlightShopProgressBarConnectorProps,
    RouteComponentProps {
  prevPath?: string;
}

enum CompareStepsResult {
  Before,
  Same,
  After,
  Unknown,
}

export const MulticityFlightShopProgressBar = (
  props: IMulticityFlightShopProgressBarProps
) => {
  const {
    currentProgress,
    setMulticityFlightShopProgress,
    multicityRoutes,
    selectedMulticityTrips,
    isTripDetailsEmpty,
    isFlightReviewReady,
    isAddOnOptionAvailable,
    isCfarMulticityEnabled,
    populateFlightBookQueryParams,
    populateFlightShopQueryParams,
    prevPath,
    history,
  } = props;

  const renderProgressButtons = (progresses: MulticityFlightShopStep[]) => {
    const goToFlightShopStep = (progress: MulticityFlightShopStep) => {
      populateFlightShopQueryParams({
        history,
        prevPath,
        useHistoryPush: true,
        forceQueryUpdate: true,
        newQueryParams: {
          multicityFlightShopProgress: progress,
        },
      });
    };

    return progresses.map(
      (progress: MulticityFlightShopStep, index: number) => {
        return (
          <React.Fragment key={index}>
            <FlightShopProgressButton
              key={`button-${index}`}
              isTripDetailsEmpty={isTripDetailsEmpty}
              isFlightReviewReady={isFlightReviewReady}
              searchedRoute={multicityRoutes[index]}
              selectedStep={currentProgress}
              progress={progress}
              isAddOnOptionAvailable={isAddOnOptionAvailable}
              selectedMulticityTrips={selectedMulticityTrips}
              setMulticityFlightShopProgress={setMulticityFlightShopProgress}
              goToCheckout={() => {
                setMulticityFlightShopProgress(
                  MulticityFlightShopStep.BookTrip
                );
                populateFlightBookQueryParams({ history });
              }}
              goToFlightShopStep={goToFlightShopStep}
            />
            {index < progresses.length - 1 && (
              <FontAwesomeIcon
                key={`separator-${index}`}
                className="progress-button-separator"
                icon={faChevronRight}
              />
            )}
          </React.Fragment>
        );
      }
    );
  };

  const getProgresses = (): MulticityFlightShopStep[] => {
    const progresses: MulticityFlightShopStep[] = [];

    const numSearchedRoutes = multicityRoutes.length;

    for (let i = 0; i < numSearchedRoutes; i++) {
      progresses.push(MulticityFlightShopStep[`ChooseDeparture${i}`]);
    }

    progresses.push(MulticityFlightShopStep.ReviewItinerary);

    if (isCfarMulticityEnabled) {
      progresses.push(MulticityFlightShopStep.Customize);
    }

    progresses.push(MulticityFlightShopStep.BookTrip);

    return progresses;
  };

  return (
    <Box className="flight-shop-progress-bar-root">
      <Box className="flight-shop-progress-bar-container">
        {renderProgressButtons(getProgresses())}
      </Box>
    </Box>
  );
};

interface IFlightShopProgressButtonProps {
  isTripDetailsEmpty: boolean;
  selectedMulticityTrips: ISelectedMulticityTrip[];
  isFlightReviewReady: boolean;
  selectedStep: MulticityFlightShopStep;
  searchedRoute: MulticityRoute;
  progress: MulticityFlightShopStep;
  isAddOnOptionAvailable: boolean;
  setMulticityFlightShopProgress: (progress: MulticityFlightShopStep) => void;
  goToFlightShopStep?: (progress: MulticityFlightShopStep) => void;
  goToCheckout?: () => void;
}

const FlightShopProgressButton = (props: IFlightShopProgressButtonProps) => {
  const {
    isTripDetailsEmpty,
    selectedMulticityTrips,
    isFlightReviewReady,
    selectedStep,
    searchedRoute,
    progress,
    isAddOnOptionAvailable,
    setMulticityFlightShopProgress,
    goToFlightShopStep,
    goToCheckout,
  } = props;

  const getProgressText = (progress: MulticityFlightShopStep) => {
    switch (progress) {
      case MulticityFlightShopStep.ChooseDeparture0:
      case MulticityFlightShopStep.ChooseDeparture1:
      case MulticityFlightShopStep.ChooseDeparture2:
      case MulticityFlightShopStep.ChooseDeparture3:
      case MulticityFlightShopStep.ChooseDeparture4: {
        return constants.CHOOSE_DEPARTURE_TEXT(searchedRoute);
      }
      case MulticityFlightShopStep.ReviewItinerary: {
        return constants.REVIEW_ITINERARY_TEXT;
      }
      case MulticityFlightShopStep.Customize: {
        return constants.CUSTOMIZE;
      }
      case MulticityFlightShopStep.BookTrip: {
        return constants.BOOK_TEXT;
      }
      default: {
        return "";
      }
    }
  };

  const compareSteps = (
    step1: MulticityFlightShopStep,
    step2: MulticityFlightShopStep
  ) => {
    const stepOrdering: MulticityFlightShopStep[] = [
      MulticityFlightShopStep.ChooseDeparture0,
      MulticityFlightShopStep.ChooseDeparture1,
      MulticityFlightShopStep.ChooseDeparture2,
      MulticityFlightShopStep.ChooseDeparture3,
      MulticityFlightShopStep.ChooseDeparture4,
      MulticityFlightShopStep.ReviewItinerary,
      MulticityFlightShopStep.FareDetails,
      MulticityFlightShopStep.Customize,
      MulticityFlightShopStep.BookTrip,
    ];

    const index1 = stepOrdering.findIndex((s) => s === step1);
    const index2 = stepOrdering.findIndex((s) => s === step2);

    if (index1 === -1 || index2 === -1) {
      return CompareStepsResult.Unknown;
    } else if (index1 === index2) {
      return CompareStepsResult.Same;
    } else if (index1 < index2) {
      return CompareStepsResult.Before;
    } else {
      return CompareStepsResult.After;
    }
  };

  const isDisabled = (
    progress: MulticityFlightShopStep,
    selectedStep: MulticityFlightShopStep
  ) => {
    const isReviewItineraryReady = !isTripDetailsEmpty && isFlightReviewReady;

    const isChooseDepartureStepReady = (step: MulticityFlightShopStep) => {
      return step <= selectedMulticityTrips.length;
    };

    if (compareSteps(selectedStep, progress) === CompareStepsResult.Before) {
      return true;
    }

    switch (progress) {
      case MulticityFlightShopStep.ChooseDeparture0:
      case MulticityFlightShopStep.ChooseDeparture1:
      case MulticityFlightShopStep.ChooseDeparture2:
      case MulticityFlightShopStep.ChooseDeparture3:
      case MulticityFlightShopStep.ChooseDeparture4:
        return !isChooseDepartureStepReady(progress);
      case MulticityFlightShopStep.ReviewItinerary:
      case MulticityFlightShopStep.BookTrip:
        return !isReviewItineraryReady;
      case MulticityFlightShopStep.Customize: {
        return !isReviewItineraryReady || !isAddOnOptionAvailable;
      }
      default:
        return true;
    }
  };

  const handleOnClick = () => {
    switch (progress) {
      case MulticityFlightShopStep.BookTrip: {
        if (goToCheckout) goToCheckout();
        break;
      }
      default: {
        setMulticityFlightShopProgress(progress);
        if (goToFlightShopStep) goToFlightShopStep(progress);
        break;
      }
    }
  };

  return (
    <ActionLink
      className={clsx(
        "flight-shop-progress-button",
        { selected: selectedStep === progress },
        "b2b"
      )}
      onClick={handleOnClick}
      content={getProgressText(progress)}
      disabled={isDisabled(progress, selectedStep)}
    />
  );
};
