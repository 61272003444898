import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  CurrencyFormatters,
  TravelWalletSelectionWorkflow,
  getRestrictedATCText,
} from "halifax";
import { Prices } from "@b2bportal/air-shopping-api";
import {
  APPLY_TRAVEL_CREDIT_TITLE,
  APPLY_TRAVEL_OFFER_TITLE,
  CREDIT_BALANCE_DESCRIPTION,
  CREDIT_HISTORY_BALANCE_TEXT,
  CREDIT_HISTORY_MODAL_LIST_HEADER,
  CREDIT_HISTORY_MODAL_SUBHEADER,
  getSelectedCreditDescription,
  getSelectedOfferDescription,
  TRAVEL_CREDIT_AND_OFFER_TOTAL_PAYMENT_TEXT,
  TRAVEL_CREDIT_SUBTITLE,
  TRAVEL_CREDIT_TOTAL_PAYMENT_TEXT,
  TRAVEL_OFFER_SUBTITLE,
  TRAVEL_OFFER_TOTAL_PAYMENT_TEXT,
  VIEW_CREDIT_HISTORY_CTA,
} from "./textConstants";
import { ClientContext } from "../../../App";
import {
  AccountPrice,
  CallState,
  CreditDetail,
  FiatPrice,
  RewardsAccount,
  Transaction,
  TravelWalletCredit,
  TravelWalletOffer,
} from "redmond";
import {
  IResetPaymentCardSelectedAccounts,
  ISetCreditToApply,
  ISetOfferToApply,
  ISetSelectedRewardsPaymentTotal,
} from "../../book/actions/actions";

export interface ITravelWalletSelectionProps extends RouteComponentProps {
  className?: string;
  isMobile?: boolean;
  disabled?: boolean;
  isAnnualTravelCreditsExperiment?: boolean;
  isCreditAndOfferStackingExperimentV1?: boolean;
  isTravelSale?: boolean;
  offers: TravelWalletOffer[] | undefined;
  offerToApply: TravelWalletOffer | undefined;
  fetchApplicableTravelWalletItemsCallState: CallState;
  selectedPaymentMethodId: string;
  selectedRewardsPaymentAccountId: string | null | undefined;
  credits: TravelWalletCredit | undefined;
  creditBreakdown: CreditDetail[];
  creditsToApply: TravelWalletCredit | undefined;
  isStackedTravelWalletPaymentOnly: boolean;
  isTravelCreditPaymentOnly: boolean;
  isTravelWalletOfferPaymentOnly: boolean;
  creditsHistoryTransaction: Transaction[] | undefined;
  bestOverallOffer: TravelWalletOffer | undefined;
  rewardsAccounts: RewardsAccount[];
  maxApplicableTravelWalletCredit: number;
  setOfferToApply: (offer?: TravelWalletOffer | undefined) => ISetOfferToApply;
  resetPaymentCardSelectedAccounts: () => IResetPaymentCardSelectedAccounts;
  setSelectedRewardsPaymentTotal: (
    accountReferenceId: string,
    rewardsPaymentTotal: AccountPrice | null,
    rewardsPaymentInFiatCurrency: FiatPrice | null,
    total?: Prices | undefined
  ) => ISetSelectedRewardsPaymentTotal;
  setCreditToApply: (
    credit?: TravelWalletCredit | undefined
  ) => ISetCreditToApply;
}

export const TravelWalletSelection = (props: ITravelWalletSelectionProps) => {
  const {
    offers,
    offerToApply,
    setOfferToApply,
    credits,
    creditBreakdown,
    creditsToApply,
    setCreditToApply,
    disabled,
    isTravelCreditPaymentOnly,
    isTravelWalletOfferPaymentOnly,
    isStackedTravelWalletPaymentOnly,
    isMobile,
    creditsHistoryTransaction,
    selectedPaymentMethodId,
    selectedRewardsPaymentAccountId,
    setSelectedRewardsPaymentTotal,
    resetPaymentCardSelectedAccounts,
    bestOverallOffer,
    rewardsAccounts,
    isAnnualTravelCreditsExperiment,
    isCreditAndOfferStackingExperimentV1,
    maxApplicableTravelWalletCredit,
    isTravelSale,
  } = props;

  const firstName =
    React.useContext(ClientContext).sessionInfo?.userInfo?.firstName || "";

  if (!offers && !credits) return null;

  useEffect(() => {
    if (credits) {
      setCreditToApply(credits);
    }
  }, [credits]);

  useEffect(() => {
    if (selectedPaymentMethodId || selectedRewardsPaymentAccountId) {
      resetPaymentCardSelectedAccounts();
      setSelectedRewardsPaymentTotal("", null, null);
    }
  }, [offerToApply, creditsToApply]);

  useEffect(() => {
    if (bestOverallOffer) {
      setOfferToApply(bestOverallOffer);
    }
  }, [bestOverallOffer]);

  return (
    <TravelWalletSelectionWorkflow
      isMobile={isMobile}
      offersAvailable={offers || []}
      creditsAvailable={credits}
      offerToApply={offerToApply}
      creditsToApply={creditsToApply}
      setOfferToApply={setOfferToApply}
      setCreditToApply={setCreditToApply}
      titles={{
        creditTitle: APPLY_TRAVEL_CREDIT_TITLE,
        creditSubtitle: TRAVEL_CREDIT_SUBTITLE,
        offersTitle: APPLY_TRAVEL_OFFER_TITLE,
        offersSubtitle: TRAVEL_OFFER_SUBTITLE,
      }}
      getCreditDescription={getSelectedCreditDescription}
      getOfferDescription={getSelectedOfferDescription}
      disabled={disabled}
      totalCoverageTexts={{
        creditsCoverTotalText: isTravelCreditPaymentOnly
          ? TRAVEL_CREDIT_TOTAL_PAYMENT_TEXT
          : undefined,
        offerCoversTotalText: isTravelWalletOfferPaymentOnly
          ? TRAVEL_OFFER_TOTAL_PAYMENT_TEXT
          : undefined,
        travelWalletCoverTotalText: isStackedTravelWalletPaymentOnly
          ? TRAVEL_CREDIT_AND_OFFER_TOTAL_PAYMENT_TEXT
          : undefined,
      }}
      isTravelWalletCreditPaymentOnly={isTravelCreditPaymentOnly}
      isTravelWalletOfferPaymentOnly={isTravelWalletOfferPaymentOnly}
      maxApplicableCreditAmount={maxApplicableTravelWalletCredit}
      travelCreditHistoryProps={
        creditsHistoryTransaction?.length
          ? {
              modalContentProps: {
                header: CREDIT_BALANCE_DESCRIPTION(firstName),
                balanceText: CREDIT_HISTORY_BALANCE_TEXT(
                  CurrencyFormatters.getSymbol(credits?.amount.currency),
                  CurrencyFormatters.get(credits?.amount.currency).format(
                    (credits?.amount?.amount && credits?.amount?.amount * -1) ||
                      0
                  )
                ),
                subHeader: CREDIT_HISTORY_MODAL_SUBHEADER,
                creditListHeader: CREDIT_HISTORY_MODAL_LIST_HEADER,
              },
              viewHistoryCTAText: VIEW_CREDIT_HISTORY_CTA,
              creditTransactionsHistory: creditsHistoryTransaction,
              credit: credits,
              creditBreakdown,
              rewardsAccounts,
            }
          : undefined
      }
      isAnnualTravelCreditsExperiment={isAnnualTravelCreditsExperiment}
      isCreditAndOfferStackingExperimentV1={
        isCreditAndOfferStackingExperimentV1
      }
      restrictedATCText={getRestrictedATCText(
        rewardsAccounts,
        isAnnualTravelCreditsExperiment ?? false
      )}
      isTravelSale={isTravelSale}
    />
  );
};
