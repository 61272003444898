import React from "react";
import { Box } from "@material-ui/core";
import { CardCarousel, TestimonyCard, weave } from "halifax";
import {
  TESTIMONY_CAROUSEL_TITLE,
  TESTIMONIES_RAPID_REBOOKING_NEW,
  TESTIMONIES_RAPID_REBOOKING_OLD,
  TESTIMONIES_CFAR,
} from "../AddOnCustomize/testimonyConstants";

interface IAddOnTestimonyCarousel {
  showArrows: boolean;
  hasDisruptionProtectionAddOnToShow: boolean;
  hasCfarAddOnToShow: boolean;
  isRapidRebookRenameEnabled: boolean;
}
export const AddOnTestimonyCarousel = ({
  showArrows,
  hasDisruptionProtectionAddOnToShow,
  hasCfarAddOnToShow,
  isRapidRebookRenameEnabled,
}: IAddOnTestimonyCarousel) => {
  if (hasDisruptionProtectionAddOnToShow && hasCfarAddOnToShow) {
    return (
      <Box className="add-on-testimony">
        <CardCarousel
          key="dp-and-cfar"
          title={TESTIMONY_CAROUSEL_TITLE}
          // Alternate DP and CFAR testimonies one-by-one
          cardContents={weave(
            isRapidRebookRenameEnabled
              ? TESTIMONIES_RAPID_REBOOKING_NEW
              : TESTIMONIES_RAPID_REBOOKING_OLD,
            TESTIMONIES_CFAR
          ).map((t) => (
            <TestimonyCard testimony={t}></TestimonyCard>
          ))}
          showArrows={showArrows}
        />
      </Box>
    );
  } else if (hasDisruptionProtectionAddOnToShow) {
    return (
      <Box className="add-on-testimony">
        <CardCarousel
          // Setting the key differently for each case ensures React remounts the carousel in order to keep
          // the scrolling state of the two elements separate.
          key="dp"
          title={TESTIMONY_CAROUSEL_TITLE}
          cardContents={(isRapidRebookRenameEnabled
            ? TESTIMONIES_RAPID_REBOOKING_NEW
            : TESTIMONIES_RAPID_REBOOKING_OLD
          ).map((t) => (
            <TestimonyCard testimony={t}></TestimonyCard>
          ))}
          showArrows={showArrows}
        />
      </Box>
    );
  } else if (hasCfarAddOnToShow) {
    return (
      <Box className="add-on-testimony">
        <CardCarousel
          // Setting the key differently for each case ensures React remounts the carousel in order to keep
          // the scrolling state of the two elements separate.
          key="cfar"
          title={TESTIMONY_CAROUSEL_TITLE}
          cardContents={TESTIMONIES_CFAR.map((t) => (
            <TestimonyCard testimony={t}></TestimonyCard>
          ))}
          showArrows={showArrows}
        />
      </Box>
    );
  } else {
    return null;
  }
};
