import { FiatPrice, RewardsPrice } from "redmond";
import { getTotalPriceText, getRewardText, twoDecimalFormatter } from "halifax";
import { getCorpFintechSubscriptionPriceCopy } from "../../AddOnCustomize/constants";
import { getPortalName } from "@capone/common";

export const LOCATION_TEXT = "Capri, Italy";

export const POINT_ONE_V2_TEXT = (cap: string) =>
  `<strong>Rebook your flight:</strong> Rebook on any airline available through ${getPortalName()}. Select from a same or next-day flight up to ${cap} for the same fare class and the same airports as your original ticket.<br/><br/>You’ll need to confirm your new flight, re-check any luggage, and confirm the status of your return flight. Upgrades and baggage fees are not included.`;
export const POINT_TWO_V2_TEXT = `<strong>Get a refund:</strong> If you don’t want to rebook your flight, get a full refund of your base fare and taxes without the hassle of contacting the airline or filling out forms.<br/><br/>Getting a refund through ${getPortalName()} does not impact your eligibility to receive compensation or accommodations from the airline.`;

export const ADDITIONAL_LUGGAGE_COPY_OLD = `If you rebook using this service, you’ll need to confirm your new flight, re-check any luggage, and confirm the status of your return flight. Rapid rebooking doesn’t cover upgrades or baggage fees, and you can only rebook once per trip.`;
export const ADDITIONAL_LUGGAGE_COPY_ONE_WAY_OLD = `If you rebook using this service, you’ll need to confirm your new flight and re-check any luggage. Rapid rebooking doesn’t cover upgrades or baggage fees, and you can only rebook once per trip.`;
export const ADDITIONAL_LUGGAGE_COPY_NEW = `If you rebook using this service, you’ll need to confirm your new flight, re-check any luggage, and confirm the status of your return flight. Flight disruption assistance doesn’t cover upgrades or baggage fees, and you can only rebook once per trip.`;
export const ADDITIONAL_LUGGAGE_COPY_ONE_WAY_NEW = `If you rebook using this service, you’ll need to confirm your new flight and re-check any luggage. Flight disruption assistance doesn’t cover upgrades or baggage fees, and you can only rebook once per trip.`;

export const DISRUPTED_FLIGHT_DESCRIPTION_COPY = (
  delayThresholdInHours: string
) =>
  `*A disrupted flight means your flight is delayed for ${delayThresholdInHours}+ hours, is canceled by the airline or you’re at risk of missing a connecting flight due to a delay. This service can be used once within 24 hours before or after your departure time.`;

export const YES_OPTION_COPY = (
  fiat: FiatPrice,
  reward: RewardsPrice | undefined
) =>
  `Yes, add this option for <strong>${getTotalPriceText({
    price: fiat,
    priceFormatter: twoDecimalFormatter,
  })}</strong>${
    reward
      ? ` / ${getRewardText({
          reward,
        })}`
      : ""
  } per traveler.`;

export const CORP_SUBSCRIPTION_TITLE = "Subscribe and save (Admin only)";
export const CORP_SUBSCRIPTION_CHIP = `Save $30 on 3 bookings`;
export const CORP_SUBSCRIPTION_COPY = (
  price: FiatPrice,
  rewards?: RewardsPrice
) => `Add this discounted option for ${getCorpFintechSubscriptionPriceCopy(
  price,
  rewards
)} per traveler for this flight. Subscribing will also automatically add flight disruption assistance to your business’s next 2 flights at a $10 discount.
<br/><br/>Prices for this feature will vary based on flight details. The discount will be applied to the applicable fee at the time of booking. <strong>Cancel anytime.</strong>`;
export const NO_OPTION_COPY = `No, thanks. I don’t want to add this option.`;
export const YES_OPTION_COPY_SHORT = "Yes, add this option.";
export const ADD_TO_MY_TRIP = "Add to my trip";
export const PER_TRAVELER = "per traveler";

export const CONTINUE_BUTTON_COPY = "Continue";
export const TERMS_AND_CONDITIONS_COPY = "Terms and conditions";
export const VIEW_TERMS_AND_CONDITIONS_COPY = "View Terms and Conditions";

export const DISRUPTION_PROTECTION_RADIO_BUTTONS_GROUP =
  "Rapid rebooking attachment radio buttons group";

export const DISRUPTION_PROTECTION_NO_OPTION_SELECTED_COPY =
  "You must add or decline this option to continue.";

export const HOW_IT_WORKS_COPY = "<strong>How it works:</strong>";
export const CHOOSE_THE_FOLLOWING_COPY =
  "If your flight is delayed or canceled on the day of travel*, choose between the following options:";

/*
  note: ESTIMATED_DISRUPTION_PROTECTION_CONTENT_HEIGHT is actually an upper bound to the height of disruption protection content (with image-background header);
  in the future, it can be removed if we were to find a better solution. DIALOG_MARGIN is the same as the margin in MuiDialog-paperScrollPaper,
  which comes from material-ui.Dialog; MuiDialog-paperScrollPaper has max-height set to calc(100% - 2 * 32px)
*/
export const ESTIMATED_DISRUPTION_PROTECTION_CONTENT_HEIGHT = 750;
export const DIALOG_MARGIN = 32;
export const PARTIAL_SCROLL_THRESHOLD = `(max-height: ${
  ESTIMATED_DISRUPTION_PROTECTION_CONTENT_HEIGHT + 2 * DIALOG_MARGIN
}px)`;
export const AIR_OFFER_REDESIGN_CLASSNAME = "air-offer-redesign";
export const INFO_BOX_PRIMARY_TITLE = "If you experience:";
export const INFO_BOX_PRIMARY_LINE_ONE = "Canceled on the day of travel";
export const INFO_BOX_PRIMARY_LINE_THREE = "Or you miss a connection";

export const INFO_BOX_SECONDARY_TITLE = "You can:";
export const INFO_BOX_SECONDARY_SECTION_ONE_TITLE = "Rebook on any airline";
export const INFO_BOX_SECONDARY_SECTION_ONE_BODY =
  "through ${getPortalName()} for flights up to $5,000 (re-checking luggage is needed)";
export const INFO_BOX_SECONDARY_SECTION_TWO_TITLE = "Get a refund";
export const INFO_BOX_SECONDARY_SECTION_TWO_BODY =
  "for your base fare and taxes and keep your original reservation.";
export const NO_THANKS = "No, thanks";

export const MORE_DETAILS_TITLE = "More details";
export const MORE_DETAILS_LINE_ONE =
  "We'll monitor your flight and notify you if there is a disruption.";
export const MORE_DETAILS_LINE_TWO = `<span class="item-copy-bold">Rebooking</span>: Select a new same-day or next-day flight that matches the fare class and airports from your original reservation (must re-check any luggage for your new flight).`;
export const MORE_DETAILS_LINE_THREE = `<span class="item-copy-bold">Refund</span>: If the ${getPortalName()} rebooking options don't work for you, get a 100% refund of your base fare + taxes and keep your delayed flight or the rescheduled flight provided by the airline.`;
export const MORE_DETAILS_LINE_FOUR =
  "Upgrades and baggage fees aren't included.";

export const ATTACHED_COPY = "You added flight disruption assistance";
export const DECLINED_COPY =
  "You declined to add flight disruption assistance.";
