export const ROUND_TRIP_TEXT = "Round-trip";
export const ONE_WAY_TEXT = "One-way";
export const FARE_DETAILS = "Fare details";
export const TICKET_OPTION = "Ticket option";
export const DISRUPTION_PROTECTION_TITLE_NEW = "Flight disruption assistance";
export const DISRUPTION_PROTECTION_TITLE_OLD = "Rapid rebooking";
export const DISRUPTION_PROTECTION_SUBTITLE = "Get help with your trip";
export const CHANGE_DATES = "Change Dates";
export const REVIEW_DETAILS = "Review Itinerary";

export const CONTINUE = "Continue";
